import { ref, Ref, reactive, markRaw, onBeforeMount, watch, getCurrentInstance, computed } from 'vue';
import apiService from '@/services/api';
import useFilters from '@/utils/hooks/useFilters';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';
import segmentProviderModel from '@/models/segment/segmentProvider';
import segmentResultModel from '@models/segment/result';

import FolderIcon from '@/assets/icons/modules/segment/all-segment/Folder.vue';

interface Props {
  selectedList: Record<string, any>[];
  excludedList: Record<string, any>[];
  isSelectAll: boolean;
  isSelectAllWithExclude: boolean;
  campaign: Campaign.ResponseDetail;
}

export default function useCustomerTable(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { openDefaultErrorModal } = useValidationModal();
  const { filterDisplayObj } = useFilters();

  const { fetchPersonalizeListModel } = segmentProviderModel();
  const { fetchSegmentResultModel, fetchCountRecord } = segmentResultModel();

  const isLoading: Ref<boolean> = ref(false);
  const folderList: Ref<Communication.CreateCampaign.Component.Personalization[]> = ref([]);
  const selectedFolder: Ref<Communication.CreateCampaign.Component.Personalization> = ref(null!);
  const segmentOptionList: Ref<Communication.Personalization.PersonalizeSegment[]> = ref([]);
  const selectedSegment: Ref<Communication.Personalization.PersonalizeSegment> = ref(null!);
  const customerList: Ref<Record<string, any>[]> = ref([]);
  const isTableBusy: Ref<boolean> = ref(false);
  const isSelectAll: Ref<boolean> = ref(false);
  const addedList: Ref<Record<string, any>[]> = ref([]);
  const excludeList: Ref<Record<string, any>[]> = ref([]);
  const searchValue: Ref<string> = ref('');
  const filtersObj: Ref<Array<any>> = ref([]);
  const sortCol: Ref<Array<any>> = ref([]);
  const isSelectAllWithExclude: Ref<boolean> = ref(false);

  const field: Ref<DynamicTable.Column[]> = ref([]);
  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const checkbox = {
    seq: 1,
    column_id: 3413,
    ref_columnid: 1,
    ref_tableid: 1,
    key: 'checkbox',
    column_name: 'Checkbox',
    column_alias: 'Checkbox',
    label_en: 'test',
    label_th: 'test',
    sortable: false,
    type_id: 1,
    type_desc: 'Text',
    column_property: {},
    col_sqltype: 1,
    col_sqltype_desc: 'Column',
    is_primary_key: false,
    cdp_unique_index: 1,
    nullable: false,
    data_type: 15,
    data_type_desc: '',
    col_type: 101,
    col_type_desc: '',
    is_groupby: false,
    is_display: true,
    is_filter: false,
    align: 1,
    align_desc: 'Left',
  };

  let searchTimeOut: ReturnType<typeof setTimeout> = null!;

  const countSelected = computed(() => {
    let sum = 0;

    if (isSelectAll.value && !isSelectAllWithExclude.value) {
      sum = pagination.totalRows;
    } else if (!isSelectAll.value && isSelectAllWithExclude.value) {
      sum = pagination.totalRows - excludeList.value.length;
    } else {
      sum = addedList.value.length;
    }

    return sum;
  });

  const fetchPersonalizeList = () => {
    isLoading.value = true;

    return apiService
      .apiRequest(fetchPersonalizeListModel)
      .then((response) => {
        const personalizeList: Communication.CreateCampaign.Component.Personalization[] = [];

        response.data.payload.map((folder: Communication.Personalization.Personalization) => {
          personalizeList.push({
            label: folder.folder_name,
            folderId: folder.folder_id,
            segmentList: folder.segment_list,
            icon: markRaw(FolderIcon) as Vue.Component,
          });

          return personalizeList;
        });

        folderList.value = personalizeList;
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  const fetchCustomerList = () => {
    if (selectedSegment.value?.segment_id) {
      fetchSegmentResultModel.payload.SegmentId = selectedSegment.value.segment_id;
      fetchSegmentResultModel.payload.Page = pagination.currentPage;
      fetchSegmentResultModel.payload.Limit = pagination.perPage;
      fetchSegmentResultModel.payload.Filters = filtersObj.value;
      fetchSegmentResultModel.payload.Ordering = sortCol.value;
      fetchSegmentResultModel.payload.Search_Value = searchValue.value;
      fetchSegmentResultModel.payload.Flag_Count = true;
      fetchSegmentResultModel.payload.ResultType = 1;
      isTableBusy.value = true;

      return apiService
        .apiRequest(fetchSegmentResultModel)
        .then((response) => {
          if (!selectedSegment.value && !selectedFolder.value) {
            customerList.value = [];
            return (pagination.totalRows = 0);
          }

          const tempCustomerList: Record<string, any>[] = response.data.segment_result;
          field.value = response.data.datasourcedisplay.columns;
          field.value.splice(0, 0, checkbox);

          //find selected customer
          if (props.campaign?.recpSelect.length) {
            const recpSelect = props.campaign?.recpSelect.filter((recp) => recp.segmentid == selectedSegment.value?.segment_id);
            const mappedRecp = recpSelect[0]?.cids.map((i: any) => {
              return { cid: i, isSelected: true };
            });
            if (recpSelect[0]?.selection_mode == 2 && recpSelect[0]?.cids.length <= 0) {
              isSelectAll.value = true;
              isSelectAllWithExclude.value = false;
              addedList.value = [];
            } else if (recpSelect[0]?.selection_mode == 2 && recpSelect[0]?.cids.length > 0) {
              isSelectAll.value = false;
              isSelectAllWithExclude.value = true;
              excludeList.value = mappedRecp || [];
            } else {
              isSelectAll.value = false;
              isSelectAllWithExclude.value = false;
              addedList.value = mappedRecp || [];
            }
          }

          if (isSelectAll.value && !isSelectAllWithExclude.value) {
            tempCustomerList.forEach((customer) => {
              const foundCustomer = addedList.value.find((addedCustomer) => addedCustomer.cid == customer.cid);
              if (!foundCustomer) addedList.value.push(customer);
              customer.isSelected = true;
            });
          } else if (!isSelectAll.value && isSelectAllWithExclude.value) {
            tempCustomerList.forEach((customer) => {
              const foundExcludeCustomer = excludeList.value.find((excludedCustomer) => excludedCustomer.cid == customer.cid);
              if (foundExcludeCustomer) {
                customer.isSelected = false;
              } else {
                customer.isSelected = true;
                addedList.value.push(customer);
              }
            });
          } else {
            tempCustomerList.forEach((customer) => {
              const foundAddedCustomer = addedList.value.find((addedCustomer) => addedCustomer.cid == customer.cid);
              if (foundAddedCustomer) return (customer.isSelected = true);
              return (customer.isSelected = false);
            });
          }

          customerList.value = tempCustomerList;
          pagination.totalRows = response.data.filtered_record;
          vm?.$emit('select-change', JSON.parse(JSON.stringify(addedList.value)));
          vm?.$emit('total-rows-change', pagination.totalRows);
          vm?.$emit('on-change-filter-search', searchValue.value);
        })
        .catch((err) => {
          openDefaultErrorModal(err);
        })
        .finally(() => {
          isTableBusy.value = false;
        });
    }
  };

  const onSelectFolder = (option: Communication.CreateCampaign.Component.Personalization) => {
    field.value = [];
    selectedFolder.value = option;
    selectedSegment.value = null!;
    customerList.value = [];
    addedList.value = [];
    excludeList.value = [];
    isSelectAll.value = false;
    pagination.currentPage = 1;
    pagination.totalRows = 0;
    segmentOptionList.value = option?.segmentList || [];
    vm?.$emit('select-all-with-exclude', false);
    vm?.$emit('exclude-list-change', JSON.parse(JSON.stringify(excludeList.value)));
    vm?.$emit('select-change', JSON.parse(JSON.stringify(addedList.value)));
    vm?.$emit('select-all', isSelectAll.value);
    vm?.$emit('select-segment', selectedSegment.value);
    vm?.$emit('total-rows-change', pagination.totalRows);
  };

  const onSelectSegment = (option: Communication.Personalization.PersonalizeSegment) => {
    field.value = [];
    customerList.value = [];
    addedList.value = [];
    excludeList.value = [];
    isSelectAll.value = false;
    selectedSegment.value = option;
    pagination.totalRows = 0;

    vm?.$emit('select-all-with-exclude', false);
    vm?.$emit('exclude-list-change', JSON.parse(JSON.stringify(excludeList.value)));
    vm?.$emit('select-change', JSON.parse(JSON.stringify(addedList.value)));
    vm?.$emit('select-all', isSelectAll.value);
    vm?.$emit('select-segment', selectedSegment.value);
    vm?.$emit('total-rows-change', pagination.totalRows);

    if (pagination.currentPage == 1) return fetchCustomerList();
    return (pagination.currentPage = 1);
  };

  const onSelectRow = (event: Event, selectedCustomer: Record<string, any>) => {
    const checkbox = event.target as HTMLInputElement;
    selectedCustomer.isSelected = checkbox.checked;

    if (selectedCustomer.isSelected) {
      addedList.value.push(selectedCustomer);
      excludeList.value = excludeList.value.filter((customer) => customer.cid !== selectedCustomer.cid);
    } else {
      excludeList.value.push(selectedCustomer);
      addedList.value = addedList.value.filter((customer) => customer.cid !== selectedCustomer.cid);

      if (isSelectAll.value) {
        isSelectAllWithExclude.value = true;
        isSelectAll.value = false;
      }
    }

    if (addedList.value.length == pagination.totalRows && !excludeList.value.length) {
      isSelectAll.value = true;
      isSelectAllWithExclude.value = false;
    }

    if (isSelectAllWithExclude.value) {
      vm?.$emit('select-all-with-exclude', true);
    } else {
      vm?.$emit('select-all-with-exclude', false);
    }

    vm?.$emit('select-all', isSelectAll.value);
    vm?.$emit('exclude-list-change', excludeList.value);
    return vm?.$emit('select-change', JSON.parse(JSON.stringify(addedList.value)));
  };

  const onSelectAllRow = () => {
    if (customerList.value) {
      customerList.value.forEach((customer) => (customer.isSelected = isSelectAll.value));
      customerList.value = [...customerList.value];

      if (isSelectAll.value) {
        addedList.value = [...customerList.value];
      } else {
        addedList.value = [];
      }

      isSelectAllWithExclude.value = false;
      excludeList.value = [];
      vm?.$emit('select-all-with-exclude', false);
      vm?.$emit('exclude-list-change', JSON.parse(JSON.stringify(excludeList.value)));
      vm?.$emit('select-change', JSON.parse(JSON.stringify(addedList.value)));
      return vm?.$emit('select-all', isSelectAll.value);
    }
  };

  const selectAllRow = (selectAll: boolean) => {
    isSelectAll.value = selectAll;

    return onSelectAllRow();
  };

  const onSearch = () => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    searchTimeOut = setTimeout(() => {
      filtersObj.value = filterDisplayObj(field.value, searchValue.value);
      if (pagination.currentPage == 1) {
        fetchCustomerList();
      } else {
        pagination.currentPage = 1;
      }
    }, 800);
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    const sortModel = sortedColumn.direction
      ? [
          {
            Seq: 1,
            Key: sortedColumn.key,
            Direction: sortedColumn.direction,
          },
        ]
      : [];

    sortCol.value = sortModel;
    if (pagination.currentPage == 1) return fetchCustomerList();
    return (pagination.currentPage = 1);
  };

  watch(
    () => pagination.currentPage,
    () => {
      fetchCustomerList();
    },
  );

  watch(
    () => props,
    () => {
      isSelectAll.value = props.isSelectAll;
      isSelectAllWithExclude.value = props.isSelectAllWithExclude;
      addedList.value = props.selectedList;
      excludeList.value = props.excludedList;
    },
  );

  onBeforeMount(() => {
    fetchPersonalizeList();
  });

  return {
    field,
    customerList,
    pagination,
    isLoading,
    folderList,
    segmentOptionList,
    selectedSegment,
    isTableBusy,
    isSelectAll,
    addedList,
    countSelected,
    searchValue,
    selectedFolder,
    onSelectFolder,
    onSelectSegment,
    onSelectRow,
    onSelectAllRow,
    selectAllRow,
    onSearch,
    onSort,
  };
}
