export default {
  dashboard: 'แดชบอร์ด',
  add_chart: 'เพิ่มกราฟ',
  data_type: 'ประเภทข้อมูล',
  source: 'แหล่งข้อมูล',
  date: 'วันที่',
  chart_type: 'ประเภทกราฟ',
  cancel: 'ยกเลิก',
  add: 'เพิ่ม',
  data_info: 'รายละเอียด',
  setting: 'ตั้งค่า',
  delete: 'ลบ',
  information: 'ข้อมูล',
  chart_name: 'ชื่อกราฟ',
  description: 'คำอธิบาย',
  filter: 'ตัวกรอง',
  filters: 'ตัวกรอง',
  add_tab: 'เพิ่มแท็ป',
  tab_name: 'ชื่อแท็ป',
  fill: 'ระบุ',
  All: 'ทั้งหมด',
  'Pie Chart': 'แผนภูมิวงกลม (Pie Charts)',
  'Line Chart': 'แผนภูมิเส้น (Line Charts)',
  'Bar Chart': 'แผนภูมิแท่ง (Bar Charts)',
  Heatmap: 'แผนภูมิความร้อน (Heat Map)',
  Infographic: 'อินโฟกราฟิก (Infographic)',
  Radar: 'เรดาร์ (Radar)',
  Box: 'กล่องข้อมูล (Box)',
  'Geo-Map': 'ภูมิศาสตร์/แผนที่ (GEO/Map)',
  Table: 'ตาราง (Table)',
  TOverview: 'แผนภูมิภาพรวม (Overview)',
  Gauge: 'แผนภูมิวัด (Gauge)',
  Profile: 'โปรไฟล์',
  Customer: 'ลูกค้า',
  Product: 'สินค้า',
  Transaction: 'ธุรกรรม',
  POS: 'POS',
  CRM: 'CRM',
  Csv: 'Csv',
  'CRM/POS': 'CRM/POS',
  BP: 'BCRM Platform',
  FH5: 'Fasthelp5',
  edit_tab: 'แก้ไขชื่อแท็ป',
  save: 'บันทึก',
  no_filter: 'ไม่มีตัวกรอง',
  selected: 'เลือกแล้ว',
  select: 'เลือก',
  setting_chart: 'ตั้งค่ากราฟ',
  color: 'สี',
  no_color: 'ไม่มีสี',
  apply: 'นำไปใช้',
  confirm_deletion: 'ยืนยันการลบ',
  or_not: 'หรือไม่',
  confirm: 'ยืนยัน',
  no_item: 'ไม่มีรายการ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  select_source: 'เลือกแหล่งข้อมูล',
  startDate: 'วันที่เริ่มต้น',
  endDate: 'วันที่สิ้นสุด',
  fillDataSourceAndDate: 'โปรดระบุแหล่งข้อมูล และวันที่ให้ถูกต้อง',
  successful: 'ทำรายการสำเร็จ',
  success: 'สำเร็จ',
  want_to_delete_tab: 'ต้องการลบแท็บ',
  yes_no: 'หรือไม่ ?',
  create_tab: 'สร้างแท็ปใหม่',
  change_tab_name: 'เปลี่ยนชื่อแท็บ',
  tab_name_to: 'เป็น',
  tab_list_empty: 'Tab list empty',
  not_found_tab_item: 'Not found tab item',
  overview: 'ภาพรวม',
  geomap_extraleft_title: '5 อันดับจังหวัดเเรก',
  total: 'ทั้งหมด',
  no_data: 'ไม่มีข้อมูล',
};
