import { reactive, onBeforeMount, watch, ref, Ref, getCurrentInstance, onMounted } from 'vue';
import { DateTime } from 'luxon';

import api from '@/services/api';
import filterTable from '@models/segment/filterTable';

import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

interface Props {
  ruleSet: CreateSegmentModal.RuleSet;
  groupฺByList: CreateSegmentModal.GroupBy[];
  operatorRuleSet?: object;
  operatorRuleSetList: Array<object>;
  columns: CreateSegmentModal.RuleColumn[];
  aggregateColumns: CreateSegmentModal.RuleColumn[];
  groupbyColumns: CreateSegmentModal.RuleColumn[];
  tableId: number;
  seq?: number;
  logic?: string;
  advanceMode?: boolean;
  miniModal?: boolean;
  modalType?: number;
  modalRuleSetName?: string;
  modalRuleSetIndex?: number;
}

interface SearchPayload {
  ColumnId: number;
  Key_Column: string;
  Limit: number;
  Page: number;
  TableId: number;
  Search_Value?: string;
  Filters: any;
}

enum OperatorList {
  Like_Contain = 6,
  Like_StartWith = 7,
  Like_EndWith = 8,
}

export interface Column extends DynamicTable.Column {
  isSelected?: boolean;
}

export default function useRuleSetCard(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const { fetchFilterTableAndOrdering } = filterTable();
  const { openDefaultErrorModal } = useValidationModal();
  const ruleSetConfig: CreateSegmentModal.RuleSet = reactive({
    ruleName: '',
    logic: '',
    logicTH: '',
    aggLogicEquation: '',
    aggLogicEquationTH: '',
    rules: [],
    aggregates: [],
    groupByList: [],
    useAggregate: false,
    logicAggregateOperatorId: null!,
    logicRuleOperatorId: null!,
    flagExclude: false,
    selectSegment: null!,
    useFilter: false,
    rootView: null!,
  });
  const columnList: Ref<Column[]> = ref([]);
  const logicArea: Ref<HTMLElement> = ref(null!);
  const logicAggArea: Ref<HTMLElement> = ref(null!);
  const timezone = DateTime.fromISO((localStorage.getItem('userInfo') as any)?.timezone || new Date().toISOString()).zoneName || 'UTC+7';

  const dataColumnResult: Ref<Record<string, any>> = ref([]);
  const filteredRecord: Ref<number> = ref(0);
  const pagination: Record<string, any> = reactive({
    Page: 1,
    Limit: 20,
  });
  const columnMultiselect: Ref<Record<string, any>> = ref([]);
  const tableIdOfMultiselect: Ref<number> = ref(null!);
  let controllers: AbortController[] = [];
  const textSearch: Ref<string> = ref('');

  const showContent = (inputName: string, operatorId: number, type_desc: string | null = null, edit_type: number | null = null) => {
    switch (inputName) {
      case 'rule-value-1-tag': {
        if (
          operatorId &&
          operatorId !== 10 &&
          operatorId !== 110 &&
          operatorId !== 112 &&
          type_desc !== 'Date' &&
          (operatorId == 11 || operatorId == 111)
        ) {
          return true;
        }
        break;
      }
      case 'rule-value-1-input-1': {
        if (
          operatorId &&
          operatorId !== 10 &&
          operatorId !== 110 &&
          operatorId !== 112 &&
          operatorId !== 11 &&
          operatorId !== 111 &&
          type_desc !== 'Date'
        ) {
          return true;
        }
        break;
      }
      case 'rule-value-1-input-2': {
        if ((operatorId == 51 || operatorId == 54 || operatorId == 57 || operatorId == 151) && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'rule-value-1-datetime': {
        if (
          operatorId &&
          operatorId !== 10 &&
          operatorId !== 110 &&
          operatorId !== 112 &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 54 &&
          operatorId !== 55 &&
          operatorId !== 56 &&
          operatorId !== 57 &&
          operatorId !== 58 &&
          operatorId !== 151 &&
          edit_type == 1 &&
          type_desc == 'Date'
        ) {
          return true;
        }
        break;
      }
      case 'rule-value-1-date': {
        if (
          operatorId &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 54 &&
          operatorId !== 55 &&
          operatorId !== 56 &&
          operatorId !== 57 &&
          operatorId !== 58 &&
          operatorId !== 151 &&
          edit_type == 2 &&
          type_desc == 'Date'
        ) {
          return true;
        }
        break;
      }
      case 'rule-value-1-time': {
        if (
          operatorId &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 54 &&
          operatorId !== 55 &&
          operatorId !== 56 &&
          operatorId !== 57 &&
          operatorId !== 58 &&
          operatorId !== 151 &&
          edit_type == 3 &&
          type_desc == 'Date'
        ) {
          return true;
        }
        break;
      }
      case 'rule-value-2-input-1': {
        if ((operatorId == 9 || operatorId == 109) && type_desc !== 'Date') {
          return true;
        }
        break;
      }
      case 'rule-value-2-datetime': {
        if ((operatorId == 9 || operatorId == 109) && edit_type == 1 && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'rule-value-2-date': {
        if ((operatorId == 9 || operatorId == 109) && edit_type == 2 && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'rule-data-unit': {
        if ((operatorId == 55 || operatorId == 56) && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'rule-unit': {
        if ((operatorId == 51 || operatorId == 52 || operatorId == 151 || operatorId == 54 || operatorId == 57) && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'aggregate-value-1-input-1': {
        if (operatorId && operatorId !== 10 && operatorId !== 110 && operatorId !== 112 && type_desc !== 'Date') {
          return true;
        }
        break;
      }
      case 'aggregate-value-1-datetime': {
        if (
          operatorId &&
          operatorId !== 10 &&
          operatorId !== 110 &&
          operatorId !== 112 &&
          operatorId !== 51 &&
          operatorId !== 52 &&
          operatorId !== 53 &&
          operatorId !== 57 &&
          operatorId !== 58 &&
          operatorId !== 151 &&
          edit_type == 1 &&
          type_desc == 'Date'
        ) {
          return true;
        }
        break;
      }
      case 'aggregate-value-1-date': {
        if (operatorId && edit_type == 2 && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'aggregate-value-1-time': {
        if (operatorId && edit_type == 3 && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'aggregate-value-2-input-1': {
        if ((operatorId == 9 || operatorId == 109) && type_desc !== 'Date') {
          return true;
        }
        break;
      }
      case 'aggregate-value-2-datetime': {
        if ((operatorId == 9 || operatorId == 109) && edit_type == 1 && type_desc == 'Date') {
          return true;
        }
        break;
      }
      case 'aggregate-value-2-date': {
        if ((operatorId == 9 || operatorId == 109) && edit_type == 2 && type_desc == 'Date') {
          return true;
        }
        break;
      }

      default: {
        return false;
      }
    }
  };
  const initRuleSet = () => {
    const initialRuleSet = JSON.parse(JSON.stringify(props.ruleSet));

    ruleSetConfig.ruleName = initialRuleSet.ruleName;
    ruleSetConfig.logic = initialRuleSet.logic;
    ruleSetConfig.rules = initialRuleSet.rules;
  };

  const initColumns = () => {
    const columns = JSON.parse(JSON.stringify(props.columns)) || [];
    columnList.value = columns;
  };

  const onSelectColumn = (column: Column) => {
    if (column) {
      column.isSelected = true;
    }
  };

  const onOpenRule = (status: boolean, column: Record<string, any>, TableId: number) => {
    if (status && pagination.Page == 1) {
      fetchColumnList(textSearch.value, column, TableId);
    }
  };

  const onSearch = (value: string, column: Record<string, any>, TableId: number) => {
    pagination.Page = 1;
    textSearch.value = value;
    fetchColumnList(value, column, TableId);
  };

  const onScroll = (value: any, column: Record<string, any>, TableId: number) => {
    if (dataColumnResult.value.length != filteredRecord.value) {
      if (value.scrollTop + value.clientHeight <= value.scrollHeight) {
        pagination.Page++;
        fetchColumnList(textSearch.value, column, TableId, true);
      }
    }
  };

  const fetchColumnList = async (query: any, column: Record<string, any>, TableId: number, status?: boolean) => {
    columnMultiselect.value = column;
    tableIdOfMultiselect.value = TableId;

    controllers.push(new AbortController());
    for (let i = 0; i < controllers.length - 1; i++) {
      pagination.Page = 1;
      controllers[i].abort();
    }

    const payload: SearchPayload = {
      ColumnId: column.column_id,
      Key_Column: column.key,
      TableId: TableId,
      Page: pagination.Page,
      Limit: pagination.Limit,
      Filters: [
        {
          Seq: 1,
          Key: column.key,
          Exp_Comp_Op: OperatorList.Like_StartWith,
          R_Value: query,
        },
      ],
    };

    const result: any = [];

    fetchFilterTableAndOrdering.payload = payload;
    fetchFilterTableAndOrdering.signal = controllers[controllers.length - 1].signal;
    await api
      .apiRequest(fetchFilterTableAndOrdering)
      .then(async (response) => {
        const data = await response.data.datalist;
        filteredRecord.value = response.data.filtered_record;

        for (const [index, item] of data.entries()) {
          if (item[column.key]) {
            result.push({
              value: item[column.key],
              label: item[column.key],
            });
          }
        }

        if (!status) {
          dataColumnResult.value = result;
        } else {
          dataColumnResult.value.push(...result);
        }
        controllers = [];
      })
      .catch((error) => {
        if (!(error instanceof TypeError)) {
          openDefaultErrorModal(error);
        }
      });
  };

  onBeforeMount(() => {
    initRuleSet();
    initColumns();
  });

  watch(
    () => props.ruleSet,
    () => {
      initRuleSet();
    },
  );

  watch(
    () => props.columns,
    () => {
      initColumns();
    },
  );

  return {
    ruleSetConfig,
    columnList,
    logicArea,
    logicAggArea,
    timezone,
    dataColumnResult,
    showContent,
    onSelectColumn,
    onSearch,
    onScroll,
    onOpenRule,
  };
}
