import { Ref, ref, onBeforeMount, reactive, onMounted, watch, onBeforeUnmount } from 'vue';
import router from '@/router';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { htmlToString } from '@/utils/editor';
import templateModel from '@/models/communication/template';
import { Channel as ChannelConstant } from '@views/modules/communication/pages/main/constants/channel';

interface Props {
  isEdit?: boolean;
}

export default function useLineTemplateForm(props: Props) {
  const { createTemplateModel, fetchTemplateDetailModel, editTemplateModel } = templateModel();

  const { openSuccessModal, openDefaultErrorModal } = useValidationModal();

  const smsMessage: Ref<string> = ref('');
  const isLoading: Ref<boolean> = ref(false);
  const isCategoryModalOpen: Ref<boolean> = ref(false);
  const tagNumber: Ref<number> = ref(-1);
  const smsEditorSelection: Ref<any> = ref(null);
  const mapper: Ref<Campaign.Personalize[] | Campaign.CouponColumn[]> = ref([]);
  const selectedButton: Ref<any> = ref(null!);
  const isUpdated: Ref<boolean> = ref(true);
  const interval: Ref<ReturnType<typeof setInterval>> = ref(null!);
  //personalize
  const isPersonalizeModalOpen: Ref<boolean> = ref(false);
  const isSegmentSelected: Ref<boolean> = ref(false);
  const personalizeObjColumn: Ref<Campaign.Personalize> = ref(null!);
  const personalizeEditMode: Ref<boolean> = ref(false);
  //coupon
  const isCouponCodeModalOpen: Ref<boolean> = ref(false);
  const couponObjColumn: Ref<Campaign.CouponColumn> = ref(null!);
  const couponEditMode: Ref<boolean> = ref(false);
  //tracking link
  const isTrackingLinkModalOpen: Ref<boolean> = ref(false);
  const trackingLinkObj: Ref<Communication.MapperModel.TrackingLink> = ref(null!);
  const trackingLinkEditMode: Ref<boolean> = ref(false);
  const selectedTrackingLink: Ref<HTMLSpanElement> = ref(null!);

  const smsDisplay = ref({
    maxChars: 0,
    maxCredit: 0,
    creditUsed: 0,
    extraChars: 0,
    msgCount: 0,
  });

  const templateForm = reactive({
    templateName: '',
    channel: 1,
    categoryId: 0,
    templateId: 0,
    updatedTime: '',
  });

  const createTemplate = () => {
    createTemplateModel.payload.CategoryId = templateForm.categoryId;
    createTemplateModel.payload.Channel = templateForm.channel as Communication.Template.Request.ChannelType;
    createTemplateModel.payload.Name = templateForm.templateName;
    createTemplateModel.payload.Type = 2;
    createTemplateModel.payload.Contents = resolveMessageContent() as Communication.Template.Request.SaveContent[];
    isLoading.value = true;

    apiService
      .apiRequest(createTemplateModel)
      .then(() => {
        openSuccessModal('บันทึกรายการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: { channel: templateForm.channel, category: templateForm.categoryId, mode: ChannelConstant.sms, type: 2 },
          }),
        );
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  const editTemplate = () => {
    isLoading.value = true;
    editTemplateModel.payload.TemplateId = templateForm.templateId;
    editTemplateModel.payload.CategoryId = templateForm.categoryId;
    editTemplateModel.payload.Channel = templateForm.channel as Communication.Template.Request.ChannelType;
    editTemplateModel.payload.Type = 2;
    editTemplateModel.payload.Name = templateForm.templateName;
    editTemplateModel.payload.Contents = resolveMessageContent() as Communication.Template.Request.SaveContent[];

    apiService
      .apiRequest(editTemplateModel)
      .then(() =>
        openSuccessModal('บันทึกรายการสำเร็จ', '', '', undefined, () =>
          router.push({
            path: '/communication/template/list',
            query: { channel: templateForm.channel, category: templateForm.categoryId, mode: ChannelConstant.sms, type: 2 },
          }),
        ),
      )
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.value = false));
  };

  function saveTemplate() {
    isLoading.value = true;
    editTemplateModel.payload.TemplateId = templateForm.templateId;
    editTemplateModel.payload.CategoryId = templateForm.categoryId;
    editTemplateModel.payload.Channel = templateForm.channel as Communication.Template.Request.ChannelType;
    editTemplateModel.payload.Type = 2;
    editTemplateModel.payload.Name = templateForm.templateName;
    editTemplateModel.payload.Contents = resolveMessageContent() as Communication.Template.Request.SaveContent[];

    apiService
      .apiRequest(editTemplateModel)
      .then(() => {
        fetchTemplateDetail();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isUpdated.value = true;
        isLoading.value = false;
      });
  }

  const fetchTemplateDetail = () => {
    isLoading.value = true;
    fetchTemplateDetailModel.payload.TemplateId = templateForm.templateId;

    apiService
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const detail = response.data as Communication.Template.Template;
        templateForm.categoryId = detail.category_id;
        templateForm.channel = detail.channel;
        templateForm.templateId = detail.template_id;
        templateForm.templateName = detail.name;
        templateForm.updatedTime = detail.updated_dt;
        if (isJsonString(detail.contents[0].content_editor as string) && detail.contents[0].content_editor) {
          const parseMessage = JSON.parse(detail.contents[0].content_editor || '');
          mapper.value = parseMessage.mapper;
          smsMessage.value = decodeURI((parseMessage.text as string) || '');
        } else {
          smsMessage.value = detail.contents[0].content_editor || '';
        }
        decreaseTagNumberFromLatest();
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => {
        isLoading.value = false;
        isUpdated.value = true;
        addActionEditToPersonalizeButton();
      });
  };

  const resolveMessageContent = () => {
    if (smsMessage.value) {
      const resolveMessageList = htmlToString(replacePersonalizeTag(smsMessage.value));
      const messageContent = [
        {
          Content: resolveMessageList,
          Content_Editor: JSON.stringify(resolveContentEditor()),
        },
      ];

      function resolveContentEditor() {
        const contentEditor = {
          type: 'text',
          text: encodeURI(smsMessage.value),
          mapper: filterMapper(smsMessage.value),
        };
        return contentEditor;
      }

      return messageContent;
    }
  };

  const onGetSmsDisplay = (value: Campaign.SmsValue) => {
    smsDisplay.value = value;
    addActionEditToPersonalizeButton();
  };

  const useMessageTemplate = (template: Communication.Template.Template) => {
    if (template.contents && template.contents.length) {
      mapper.value = [];
      if (isJsonString(template.contents[0].content_editor as string) && template.contents[0].content_editor) {
        const parseMessage = JSON.parse(template.contents[0].content_editor || '');
        mapper.value = parseMessage.mapper;
        smsMessage.value = decodeURI((parseMessage.text as string) || '');
      } else {
        smsMessage.value = template.contents[0].content_editor || '';
      }
      decreaseTagNumberFromLatest();
    }
  };

  const onClickSave = () => {
    if (templateForm.templateName.trim() && smsMessage.value) {
      if (!props.isEdit) {
        if (templateForm.categoryId === 0) {
          return onSaveNoCategoryId();
        } else return createTemplate();
      } else {
        return editTemplate();
      }
    }
  };

  function onSaveNoCategoryId() {
    isCategoryModalOpen.value = true;
  }

  function onCloseCategoryModal() {
    isCategoryModalOpen.value = false;
  }

  function onSaveWithCategoryId(cat: Communication.TemplateCard.Response.CategoryList) {
    templateForm.categoryId = cat.categoryId as number;
    if (templateForm.categoryId !== 0) {
      isCategoryModalOpen.value = false;
      setTimeout(() => {
        createTemplate();
      }, 200);
    }
  }

  //personalize
  function onSelectPersonalize() {
    isPersonalizeModalOpen.value = true;
  }

  function onSelectPersonalizeNoSegment() {
    // openWarningModal('คุณยังไม่ได้เลือก Customer ต้องการไปแท็บ Select Customer หรือไม่ ?', '', () => (activeTab.value = 1));
    isPersonalizeModalOpen.value = true;
  }

  function onClosePersonalizeModal() {
    isPersonalizeModalOpen.value = false;
    personalizeEditMode.value = false;
    personalizeObjColumn.value = null!;
  }

  function onAddEditPersonalize(personalize: Campaign.Personalize) {
    if (personalizeEditMode.value) {
      editPersonalize(selectedButton.value, personalize);
    } else {
      addPersonalizeButton(personalize);
    }
    addActionEditToPersonalizeButton();
    isPersonalizeModalOpen.value = false;
    personalizeEditMode.value = false;
    personalizeObjColumn.value = null!;
  }

  function addPersonalizeButton(personalize: Campaign.Personalize) {
    const smsEditor = document.getElementById('sms-editor') as HTMLDivElement;
    restoreSelection(smsEditorSelection.value);
    const range: any = smsEditorSelection.value.getRangeAt(0);
    range.deleteContents();
    const personalizeObj = Object.assign(personalize, { tag: `{{mp:${tagNumber.value}}}` });
    const span = document.createElement('span');
    span.innerHTML = `${personalize.columnAlias as string}`;
    span.setAttribute(
      'style',
      `background-color: ${personalizeObj.backgroundColor as string}; color: ${
        personalizeObj.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
    span.draggable = true;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('value', `${JSON.stringify(personalizeObj)}`);
    span.setAttribute('class', 'personalize-button-span');
    const spacebar = document.createTextNode(' ');
    range.insertNode(span);
    range.insertNode(spacebar);
    range.setStartAfter(span);
    smsEditorSelection.value.removeAllRanges();
    smsEditorSelection.value.addRange(range);

    tagNumber.value--;
    const personalizeButton: any = smsEditor.getElementsByClassName('personalize-button-span');
    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      if (mapper.columnAlias === personalizeObj.columnAlias) {
        mapper.defaultValue = personalizeObj.defaultValue;
        personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
      }
    }
    smsEditor.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
  }

  function editPersonalize(button: any, personalize: Campaign.Personalize) {
    const smsEditor = document.getElementById('sms-editor') as HTMLDivElement;
    const personalizeButton: any = smsEditor.getElementsByClassName('personalize-button-span');
    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      if (mapper.columnAlias === personalize.columnAlias) {
        mapper.defaultValue = personalize.defaultValue;
        personalizeButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
      }
    }
    button.innerHTML = personalize.columnAlias as string;
    button.setAttribute('value', `${JSON.stringify(personalize)}`);
    button.setAttribute(
      'style',
      `background-color: ${personalize.backgroundColor as string}; color: ${
        personalize.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
  }

  //coupon
  function onSelectCoupon() {
    isCouponCodeModalOpen.value = true;
  }

  function onCloseCouponModal() {
    isCouponCodeModalOpen.value = false;
    couponEditMode.value = false;
    couponObjColumn.value = null!;
  }

  function onAddEditCoupon(coupon: Campaign.CouponColumn[] | Campaign.CouponColumn) {
    if (couponEditMode.value) {
      editCoupon(selectedButton.value, coupon as Campaign.CouponColumn);
    } else {
      addCoupon(coupon as Campaign.CouponColumn[]);
    }
    addActionEditToPersonalizeButton();
    onCloseCouponModal();
  }

  function addCoupon(couponList: Campaign.CouponColumn[]) {
    const smsEditor = document.getElementById('sms-editor') as HTMLDivElement;
    restoreSelection(smsEditorSelection.value);
    const range: any = smsEditorSelection.value.getRangeAt(0);
    couponList.forEach((item: Campaign.CouponColumn) => {
      range.deleteContents();
      const couponObj = Object.assign(item, { tag: `{{mp:${tagNumber.value}}}` });
      const button = document.createElement('span');
      button.className = 'coupon-button';
      button.setAttribute(
        'style',
        `cursor:pointer;border-radius:2px;padding:0 5px;margin:0px 2px;border:none;background-color:${couponObj.backgroundColor as string};color:${
          couponObj.fontColor as string
        }`,
      );
      button.draggable = true;
      button.setAttribute('contenteditable', 'false');
      button.setAttribute('value', JSON.stringify(couponObj));
      button.innerHTML = couponObj.couponColumnAlias as string;
      const spacebar = document.createTextNode(' ');
      range.insertNode(button);
      range.insertNode(spacebar);
      range.setStartAfter(button);
      smsEditorSelection.value.removeAllRanges();
      smsEditorSelection.value.addRange(range);
      tagNumber.value--;
      const couponButton: any = smsEditor.getElementsByClassName('personalize-button-span');
      for (let i = 0; i < couponButton.length; i++) {
        const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
        if (mapper.couponColumnAlias === couponObj.couponColumnAlias) {
          mapper.defaultValue = couponObj.defaultValue as string;
          couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
        }
      }
    });
    smsEditor.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
  }

  function editCoupon(button: any, coupon: Campaign.CouponColumn) {
    const smsEditor = document.getElementById('sms-editor') as HTMLDivElement;
    const couponButton: any = smsEditor.getElementsByClassName('coupon-button');
    for (let i = 0; i < couponButton.length; i++) {
      const mapper: Campaign.CouponColumn = JSON.parse(couponButton[i].getAttribute('value') as string);
      if (
        mapper.couponColumnAlias === coupon.couponColumnAlias &&
        mapper.couponFreeTextRef === coupon.couponFreeTextRef &&
        mapper.couponId === coupon.couponId
      ) {
        mapper.defaultValue = coupon.defaultValue as string;
        couponButton[i].setAttribute('value', `${JSON.stringify(mapper)}`);
      }
    }
    button.innerHTML = coupon.couponColumnAlias as string;
    button.setAttribute('value', `${JSON.stringify(coupon)}`);
    button.setAttribute(
      'style',
      `background-color: ${coupon.backgroundColor as string}; color: ${
        coupon.fontColor as string
      }; cursor:pointer;border-radius:2px;padding:0px 5px;margin:0px 2px;`,
    );
  }

  //#region tracking link
  function onSelectTrackingLinkMenu() {
    isTrackingLinkModalOpen.value = true;
  }

  function onCloseTrackingLinkModal() {
    isTrackingLinkModalOpen.value = false;
    trackingLinkEditMode.value = false;
    trackingLinkObj.value = null!;
  }

  function onAddEditTrackingLink(trackingLink: Communication.MapperModel.TrackingLink) {
    if (trackingLinkEditMode.value) {
      editTrackingLink(selectedTrackingLink.value, trackingLink);
    } else {
      addTrackingLink(trackingLink);
    }
    addActionEditToPersonalizeButton();
    isTrackingLinkModalOpen.value = false;
    trackingLinkEditMode.value = false;
    trackingLinkObj.value = null!;
  }

  function addTrackingLink(trackingLink: Communication.MapperModel.TrackingLink) {
    const smsEditor = document.getElementById('sms-editor') as HTMLDivElement;
    restoreSelection(smsEditorSelection.value);
    const range: any = smsEditorSelection.value.getRangeAt(0);
    range.deleteContents();
    const trackingLinkObj = Object.assign(trackingLink, { tag: `{{mp:${tagNumber.value}}}` });
    const span = document.createElement('span');
    span.innerHTML = `${process.env.NODE_ENV === 'development' ? 'dev.' : ''}cdp.cx/${trackingLink.alphabetSeries as string}`;
    span.setAttribute(
      'style',
      `background-color: ${trackingLink.backgroundColor as string}; color: ${
        trackingLink.fontColor as string
      }; cursor:pointer; border-radius:2px; padding:0px 5px; margin:0px 2px; text-decoration: underline; text-decoration-color: ${
        trackingLink.fontColor as string
      };`,
    );
    span.draggable = true;
    span.setAttribute('contenteditable', 'false');
    span.setAttribute('value', `${JSON.stringify(trackingLinkObj)}`);
    span.setAttribute('class', 'tracking-link-span');
    const spacebar = document.createTextNode(' ');
    range.insertNode(span);
    range.insertNode(spacebar);
    range.setStartAfter(span);
    smsEditorSelection.value.removeAllRanges();
    smsEditorSelection.value.addRange(range);
    tagNumber.value--;
    smsEditor.dispatchEvent(new KeyboardEvent('keydown', { keyCode: 32, which: 32 }));
  }

  function editTrackingLink(button: HTMLSpanElement, trackingLink: Communication.MapperModel.TrackingLink) {
    button.setAttribute('value', `${JSON.stringify(trackingLink)}`);
    button.innerHTML = `${process.env.NODE_ENV === 'development' ? 'dev.' : ''}cdp.cx/${trackingLink.alphabetSeries as string}`;
    button.setAttribute(
      'style',
      `background-color: ${trackingLink.backgroundColor as string}; color: ${
        trackingLink.fontColor as string
      }; cursor:pointer; border-radius:2px; padding:0px 5px; margin:0px 2px; text-decoration: underline; text-decoration-color: ${
        trackingLink.fontColor as string
      };`,
    );
  }
  //#endregion

  //util for personalize
  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function getCursorPosition(selection: any) {
    smsEditorSelection.value = selection;
  }

  function restoreSelection(saved: any) {
    smsEditorSelection.value = window.getSelection();
    smsEditorSelection.value.setBaseAndExtent(saved[0], saved[1], saved[2], saved[3]);
  }

  const replacePersonalizeTag = (contentHtml: string) => {
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton: any = htmlElement.getElementsByClassName('personalize-button-span');
    const couponButtonList: any = htmlElement.getElementsByClassName('coupon-button');
    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(personalizeButton[i].getAttribute('value') as string).tag).replaceAll('"', '');
      contentHtml = contentHtml.replace(personalizeButton[i].outerHTML as string, tagMp);
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const tagMp = JSON.stringify(JSON.parse(couponButtonList[i].getAttribute('value') as string).tag).replaceAll('"', '');
      contentHtml = contentHtml.replace(couponButtonList[i].outerHTML as string, tagMp);
    }
    //tracking link
    htmlElement?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLink) => {
      const tagMp = JSON.stringify(JSON.parse(trackingLink.getAttribute('value') as string).tag).replaceAll('"', '');
      contentHtml = contentHtml.replace(trackingLink.outerHTML, tagMp);
    });
    return contentHtml;
  };

  function filterMapper(contentHtml: string) {
    const currentMapper = [];
    const htmlElement = new DOMParser().parseFromString(contentHtml, 'text/html');
    const personalizeButton: any = htmlElement.getElementsByClassName('personalize-button-span');
    const couponButtonList: any = htmlElement.getElementsByClassName('coupon-button');
    //personalize
    for (let i = 0; i < personalizeButton.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    //coupon
    for (let i = 0; i < couponButtonList.length; i++) {
      const mapper: Campaign.Personalize = JSON.parse(couponButtonList[i].getAttribute('value') as string);
      currentMapper.push(mapper);
    }
    // tracking link
    htmlElement?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLink) => {
      const mapper: Communication.MapperModel.TrackingLink = JSON.parse(trackingLink.getAttribute('value') as string);
      currentMapper.push(mapper);
    });

    return currentMapper;
  }

  function addActionEditToPersonalizeButton() {
    const smsEditor = document.getElementById('sms-editor') as HTMLDivElement;
    let range: any = document.createRange();
    if (smsEditor) {
      function setContent() {
        const smsEditorContent = smsEditor?.innerHTML;
        smsMessage.value = smsEditorContent;
      }
      //Personalize Action
      const personalizeButton: any = smsEditor.getElementsByClassName('personalize-button-span');

      for (let i = 0; i < personalizeButton.length; i++) {
        const personalize: Campaign.Personalize = JSON.parse(personalizeButton[i].getAttribute('value') as string);

        personalizeButton[i].onclick = () => {
          personalizeEditMode.value = true;
          personalizeObjColumn.value = personalize;
          isPersonalizeModalOpen.value = true;
          selectedButton.value = personalizeButton[i];
        };
        personalizeButton[i].ondragstart = (e: any) => {
          e.dataTransfer.setData('text/html', e.target.outerHTML);
        };
        personalizeButton[i].ondragend = (e: any) => {
          if (e.dataTransfer.dropEffect === 'copy') {
            personalizeButton[i].remove();
            setContent();
          }
        };
        personalizeButton[i].onmouseup = (e: any) => {
          if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
          else {
            // firefox
            const sel = window.getSelection();
            sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
          }
        };
      }
      //Coupon Action
      const couponButtonList: any = smsEditor?.getElementsByClassName('coupon-button');
      for (let i = 0; i < couponButtonList.length; i++) {
        const couponObj = JSON.parse(couponButtonList[i].getAttribute('value') as string);

        couponButtonList[i].onclick = () => {
          couponEditMode.value = true;
          couponObjColumn.value = couponObj;
          isCouponCodeModalOpen.value = true;
          selectedButton.value = couponButtonList[i];
        };
        couponButtonList[i].ondragstart = (e: any) => {
          e.dataTransfer.setData('text/html', e.target.outerHTML);
        };
        couponButtonList[i].ondragend = (e: any) => {
          if (e.dataTransfer.dropEffect === 'copy') {
            couponButtonList[i].remove();
            setContent();
          }
        };
        couponButtonList[i].onmouseup = (e: any) => {
          if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
          else {
            // firefox
            const sel = window.getSelection();
            sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
          }
        };
      }
      //tracking link Action
      smsEditor?.querySelectorAll<HTMLElement>('.tracking-link-span').forEach((trackingLinkEle) => {
        trackingLinkEle.onclick = () => {
          const trackingLink: Communication.MapperModel.TrackingLink = JSON.parse(trackingLinkEle.getAttribute('value') as string);
          trackingLinkEditMode.value = true;
          trackingLinkObj.value = trackingLink;
          isTrackingLinkModalOpen.value = true;
          selectedTrackingLink.value = trackingLinkEle;
        };
        trackingLinkEle.ondragstart = (e: any) => {
          e.dataTransfer.setData('text/html', e.target.outerHTML);
        };
        trackingLinkEle.ondragend = (e: any) => {
          if (e.dataTransfer.dropEffect === 'copy') {
            trackingLinkEle.remove();
            setContent();
          }
        };
        trackingLinkEle.onmouseup = (e: any) => {
          if (document.caretRangeFromPoint) range = document.caretRangeFromPoint(e.clientX as number, e.clientY as number);
          else {
            // firefox
            const sel = window.getSelection();
            sel?.collapse(e.rangeParent as Node, e.rangeOffset as number);
          }
        };
      });

      setContent();
    }
  }

  function decreaseTagNumberFromLatest() {
    const tagNumList: number[] = [];
    filterMapper(smsMessage.value).forEach((mapper: any) => {
      tagNumList.push(mapper.tag.split(/(-?\d+)/)[1] as number);
      const min = Math.min(...tagNumList);
      if (min > 0) tagNumber.value = 0;
      else if (min < 0) tagNumber.value = min;
      tagNumber.value--;
    });
  }

  function autoSave() {
    if (!isUpdated.value) return saveTemplate();
  }

  onBeforeMount(() => {
    const query = router.currentRoute.value.query;
    if (!props.isEdit) {
      templateForm.categoryId = Number(query.category) || 0;
      return (smsMessage.value = '');
    }
    templateForm.templateId = Number(router.currentRoute.value.params.id) || 0;
    fetchTemplateDetail();
  });

  onMounted(() => {
    if (props.isEdit) interval.value = setInterval(autoSave, 3600000);
  });

  onBeforeUnmount(() => {
    if (interval.value) {
      clearInterval(interval.value);
    }
  });

  watch(
    [templateForm, smsMessage],
    () => {
      if (!isLoading.value) isUpdated.value = false;
    },
    {
      deep: true,
    },
  );

  return {
    isLoading,
    smsDisplay,
    smsMessage,
    templateForm,
    isCategoryModalOpen,
    isCouponCodeModalOpen,
    isPersonalizeModalOpen,
    isSegmentSelected,
    personalizeObjColumn,
    personalizeEditMode,
    couponEditMode,
    couponObjColumn,
    mapper,
    isTrackingLinkModalOpen,
    trackingLinkEditMode,
    trackingLinkObj,
    onClickSave,
    onGetSmsDisplay,
    useMessageTemplate,
    onCloseCategoryModal,
    onSaveWithCategoryId,
    onSelectCoupon,
    onCloseCouponModal,
    getCursorPosition,
    onSelectPersonalizeNoSegment,
    onClosePersonalizeModal,
    onAddEditPersonalize,
    onSelectPersonalize,
    onAddEditCoupon,
    onSelectTrackingLinkMenu,
    onCloseTrackingLinkModal,
    onAddEditTrackingLink,
  };
}
