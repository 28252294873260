function isUrlValid(url: string) {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

function resolveActionArea(gridType: string, index: number, image: { url: string; width: number; height: number }) {
  let areaObj = {};
  if (gridType == 'custom-grid') {
    return (areaObj = {
      x: 0,
      y: 0,
      width: image.width,
      height: image.height,
    });
  } else if (gridType == 'one-grid') {
    return (areaObj = {
      x: 0,
      y: 0,
      width: image.width,
      height: image.height,
    });
  } else if (gridType == 'two-grid-vertical') {
    console.log(gridType, index);
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width / 2,
          height: image.height,
        });
      case 1:
        return (areaObj = {
          x: image.width / 2,
          y: 0,
          width: image.width / 2,
          height: image.height,
        });
    }
  } else if (gridType == 'two-grid-horizontal') {
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width,
          height: image.height / 2,
        });
      case 1:
        return (areaObj = {
          x: 0,
          y: image.height / 2,
          width: image.width,
          height: image.height / 2,
        });
    }
  } else if (gridType == 'three-grid-horizontal') {
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width,
          height: image.height / 3,
        });
      case 1:
        return (areaObj = {
          x: 0,
          y: image.height / 3,
          width: image.width,
          height: image.height / 3,
        });
      case 2:
        return (areaObj = {
          x: 0,
          y: image.height - image.height / 3,
          width: image.width,
          height: image.height / 3,
        });
    }
  } else if (gridType == 'three-grid-one-to-two') {
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width,
          height: image.height / 2,
        });
      case 1:
        return (areaObj = {
          x: 0,
          y: image.height / 2,
          width: image.width / 2,
          height: image.height / 2,
        });
      case 2:
        return (areaObj = {
          x: image.width / 2,
          y: image.height / 2,
          width: image.width / 2,
          height: image.height / 2,
        });
    }
  } else if (gridType == 'three-grid-two-to-one') {
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width / 2,
          height: image.height / 2,
        });
      case 1:
        return (areaObj = {
          x: image.width / 2,
          y: 0,
          width: image.width / 2,
          height: image.height / 2,
        });
      case 2:
        return (areaObj = {
          x: 0,
          y: image.height / 2,
          width: image.width,
          height: image.height / 2,
        });
    }
  } else if (gridType == 'four-grid') {
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width / 2,
          height: image.height / 2,
        });
      case 1:
        return (areaObj = {
          x: image.width / 2,
          y: 0,
          width: image.width / 2,
          height: image.height / 2,
        });
      case 2:
        return (areaObj = {
          x: 0,
          y: image.height / 2,
          width: image.width / 2,
          height: image.height / 2,
        });
      case 3:
        return (areaObj = {
          x: image.width / 2,
          y: image.height / 2,
          width: image.width / 2,
          height: image.height / 2,
        });
    }
  } else if (gridType == 'six-grid') {
    switch (index) {
      case 0:
        return (areaObj = {
          x: 0,
          y: 0,
          width: image.width / 3,
          height: image.height / 2,
        });
      case 1:
        return (areaObj = {
          x: image.width / 3,
          y: 0,
          width: image.width / 3,
          height: image.height / 2,
        });
      case 2:
        return (areaObj = {
          x: image.width - image.width / 3,
          y: 0,
          width: image.width / 3,
          height: image.height / 2,
        });
      case 3:
        return (areaObj = {
          x: 0,
          y: image.height / 2,
          width: image.width / 3,
          height: image.height / 2,
        });
      case 4:
        return (areaObj = {
          x: image.width / 3,
          y: image.height / 2,
          width: image.width / 3,
          height: image.height / 2,
        });
      case 5:
        return (areaObj = {
          x: image.width - image.width / 3,
          y: image.height / 2,
          width: image.width / 3,
          height: image.height / 2,
        });
    }
  }
  return areaObj;
}

export function buildRichMessagePayload(richMessage: Communication.LineOA.Component.RichMessage) {
  let builtPayload = {};
  builtPayload = {
    type: 'imagemap',
    baseUrl: `${richMessage.image.url}&q=imagemap`,
    altText: 'Rich Message',
    sref: richMessage.sref,
    baseSize: {
      width: richMessage.image.width,
      height: richMessage.image.height,
    },
    actions: richMessage.gridList.map((message, index) => {
      return {
        type: 'uri',
        area: resolveActionArea(richMessage.gridType.key as string, index, richMessage.image),
        linkUri: isUrlValid(message.properties?.linkUrl as string) ? message.properties?.linkUrl : 'https://' + message.properties?.linkUrl,
        text: message.properties?.actionLabel,
      };
    }),
  };
  return builtPayload;
}
