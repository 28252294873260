import { reactive, Ref, ref, getCurrentInstance, markRaw, computed, watch } from 'vue';
import { Channel } from '../../../constants/channel';
import { TemplateMainTypeEnum } from '@/constants/modules/commu/template-mainType.enum';
import templateModel from '@/models/communication/template';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import All from '@/assets/icons/modules/communication/template-tab/template-category/All.vue';
import Popular from '@/assets/icons/modules/communication/template-tab/template-category/Popular.vue';
import Promotions from '@/assets/icons/modules/communication/template-tab/template-category/Promotions.vue';
import Seasonal from '@/assets/icons/modules/communication/template-tab/template-category/Seasonal.vue';
import Awareness from '@/assets/icons/modules/communication/template-tab/template-category/Awareness.vue';
import WinBack from '@/assets/icons/modules/communication/template-tab/template-category/WinBack.vue';
import Engagement from '@/assets/icons/modules/communication/template-tab/template-category/Engagement.vue';
import Notification from '@/assets/icons/modules/communication/template-tab/template-category/Notification.vue';
import Other from '@/assets/icons/modules/communication/template-tab/template-category/Other.vue';
import apiService from '@/services/api';

interface Props {
  isOpen: boolean;
  channel: number;
  subType?: number[];
}

export default function useSelectTemplateModal(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { fetchTemplateCardModel, fetchTemplateListModel, getCategoryListModel } = templateModel();
  const { openDefaultErrorModal } = useValidationModal();

  const templateCategoryList: Ref<Communication.TemplateCard.Response.Category[]> = ref([]);
  const selectedTemplateCategory: Ref<Communication.TemplateCard.Response.Category> = ref(null!);
  const search: Ref<string> = ref('');
  const isChangedListStyle: Ref<boolean> = ref(false);
  const selectedCategory: Ref<number> = ref(0);
  const allCategoryList: Ref<Communication.TemplateCard.Response.CategoryList[]> = ref([]);
  const templateList: Ref<Communication.Template.Template[]> = ref([]);
  const tableSortingOrder: Ref<Communication.Template.Request.Ordering[]> = ref([]);
  const keyChange: Ref<number> = ref(0);
  const selectedTemplate: Ref<Communication.Template.Template> = ref(null!);
  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const isLoading = reactive({
    all: false,
    category: false,
    table: false,
  });

  const templateCategory = reactive([
    {
      label: 'All',
      icon: markRaw(All),
      value: 1,
    },
    {
      label: 'Popular',
      icon: markRaw(Popular),
      value: 2,
    },
    {
      label: 'Promotions',
      icon: markRaw(Promotions),
      value: 3,
    },
    {
      label: 'Seasonal',
      icon: markRaw(Seasonal),
      value: 4,
    },
    {
      label: 'Awareness',
      icon: markRaw(Awareness),
      value: 5,
    },
    {
      label: 'Win-Back',
      icon: markRaw(WinBack),
      value: 6,
    },
    {
      label: 'Engagement',
      icon: markRaw(Engagement),
      value: 7,
    },
    {
      label: 'Notification',
      icon: markRaw(Notification),
      value: 8,
    },
    {
      label: 'Other',
      icon: markRaw(Other),
      value: 9,
    },
  ]);

  const templateFilterForm: Record<string, any> = reactive({
    mainType: TemplateMainTypeEnum.STANDARD,
  });

  const templateFilterList: Ref<Record<string, any>> = ref({
    mainType: [
      {
        id: TemplateMainTypeEnum.STANDARD,
        label: 'Standard',
      },
      {
        id: TemplateMainTypeEnum.MY_TEMPLATE,
        label: 'My Template',
      },
    ],
  });

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const isListEmpty = computed(() => {
    if (templateCategoryList.value && templateCategoryList.value.length) {
      const notEmptyCategory = templateCategoryList.value.find((category) => category?.template_list?.length);

      return !!!notEmptyCategory;
    }

    return true;
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'radio', label: '' },
    { key: 'name', label: 'Template Name', sortable: true },
    { key: 'category_name', label: 'Category', sortable: true },
    { key: 'updated_dt', label: 'Last Update', sortable: true },
    { key: 'updated_by', label: 'Update by', sortable: true },
    { key: 'action', label: '' },
  ];

  const fetchTemplateCardList = () => {
    getCategoryList();
    fetchTemplateCardModel.payload.Channel = resolveChannel();
    fetchTemplateCardModel.payload.Status = 1;
    fetchTemplateCardModel.payload.Type = templateFilterForm.mainType || null;
    fetchTemplateCardModel.payload.List_SubType = props.subType || [];
    fetchTemplateCardModel.payload.CategoryId = selectedCategory.value || null;
    fetchTemplateCardModel.payload.Query = search.value;
    fetchTemplateCardModel.payload.Limit = 999;

    if (selectedCategory.value) {
      isLoading.category = true;
    } else {
      isLoading.all = true;
    }
    apiService
      .apiRequest(fetchTemplateCardModel)
      .then((response) => {
        const categoryList: Communication.TemplateCard.Response.Category[] = response.data;

        if (!templateCategoryList.value.length || selectedCategory.value == 0) {
          const allCategory: Communication.TemplateCard.Response.Category = {
            category_icon_name: null,
            category_icon_url: null,
            category_id: 0,
            category_name: 'All',
            template_list: [],
          };
          if (categoryList) {
            return (templateCategoryList.value = [allCategory, ...categoryList]);
          } else {
            const categoryListDropDown: Communication.TemplateCard.Response.Category[] = [];
            allCategoryList.value.forEach((cat) => {
              const category: Communication.TemplateCard.Response.Category = {
                category_icon_name: cat.iconName,
                category_icon_url: cat.iconUrl,
                category_id: cat.categoryId,
                category_name: cat.name,
                template_list: [],
              };
              categoryListDropDown.push(category);
            });

            return (templateCategoryList.value = [allCategory, ...categoryListDropDown]);
          }
        }

        const searchedCategory = templateCategoryList.value.find((category) => category.category_id == selectedCategory.value);

        if (searchedCategory) return (searchedCategory.template_list = categoryList && categoryList.length ? categoryList[0].template_list : []);
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.all = false;
        isLoading.category = false;
      });
  };

  const fetchTemplateList = () => {
    fetchTemplateListModel.payload.Channel = resolveChannel();
    fetchTemplateListModel.payload.Status = 1;
    fetchTemplateListModel.payload.Type = templateFilterForm.mainType || null;
    fetchTemplateListModel.payload.CategoryId = selectedCategory.value || null;
    fetchTemplateListModel.payload.List_SubType = props.subType || [];
    fetchTemplateListModel.payload.Query = search.value;
    fetchTemplateListModel.payload.Limit = pagination.perPage;
    fetchTemplateListModel.payload.Page = pagination.currentPage;
    fetchTemplateListModel.payload.Ordering = tableSortingOrder.value;
    isLoading.table = true;

    apiService
      .apiRequest(fetchTemplateListModel)
      .then((response) => {
        const responseData: Communication.Template.Response.TemplateList = response.data;
        pagination.totalRows = responseData.filtered_records;
        templateList.value = responseData.records;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.table = false));
  };

  function getCategoryList() {
    allCategoryList.value = [];
    apiService
      .apiRequest(getCategoryListModel)
      .then((response) => {
        const data = response.data;
        data.forEach((data: any) => {
          const category = {
            categoryId: data.categoryid,
            createdBy: data.created_by,
            createdByReq: data.created_by_req,
            createdDt: data.created_dt,
            iconName: data.icon_name,
            iconUrl: data.icon_url,
            isDeleted: data.is_deleted,
            name: data.name,
            seq: data.seq,
            updatedBy: data.updated_by,
            updatedByReq: data.updated_by_req,
            updatedDt: data.updated_dt,
          };
          allCategoryList.value.push(category);
        });
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally();
  }

  const resolveCategoryIcon = (categoryType: number | null) => {
    switch (categoryType) {
      case 0:
        return markRaw(All);
      case 1:
        return markRaw(Popular);
      case 2:
        return markRaw(Promotions);
      case 3:
        return markRaw(Seasonal);
      case 4:
        return markRaw(Awareness);
      case 5:
        return markRaw(WinBack);
      case 6:
        return markRaw(Engagement);
      case 7:
        return markRaw(Notification);
      case 8:
        return markRaw(Other);
      default:
        return null;
    }
  };

  const resolveChannel = (): Communication.Template.Request.ChannelType => {
    switch (props.channel) {
      case Channel.lineOA:
        return 3;
      case Channel.sms:
        return 1;
      default:
        return 0;
    }
  };

  const onSelectCategory = () => {
    allCategoryList.value = [];
    const searchedCategory = templateCategoryList.value.find((category) => category.category_id == selectedCategory.value);

    selectedTemplateCategory.value = searchedCategory!;
    search.value = '';
    tableSortingOrder.value = [];
    selectedTemplate.value = null!;

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onSelectFilter = (typeId: number) => {
    templateFilterForm.mainType = typeId;
    selectedTemplate.value = null!;

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onClickViewAll = (categoryId: number) => {
    selectedCategory.value = categoryId;

    onSelectCategory();
  };

  const onSearch = () => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (isChangedListStyle.value) {
        return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
      }

      return fetchTemplateCardList();
    }, 500);
  };

  const onCloseModal = () => {
    templateCategoryList.value = [];
    selectedCategory.value = null!;
    search.value = '';
    isChangedListStyle.value = false;
    selectedCategory.value = 0;
    isLoading.all = false;
    isLoading.category = false;
    isLoading.table = false;
    templateFilterForm.mainType = 1;
    tableSortingOrder.value = [];
    pagination.currentPage = 1;
    selectedTemplate.value = null!;

    vm?.$emit('close-modal');
  };

  const onChangeListStyle = () => {
    isChangedListStyle.value = !isChangedListStyle.value;
    pagination.currentPage = 1;
    selectedTemplate.value = null!;

    if (isChangedListStyle.value) return fetchTemplateList();

    tableSortingOrder.value = [];
    return fetchTemplateCardList();
  };

  const onClickTemplateCard = (template: Communication.Template.Template) => {
    selectedTemplate.value = template;
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      tableSortingOrder.value = sortModel as Communication.Template.Request.Ordering[];
    } else {
      tableSortingOrder.value = [];
    }

    if (pagination.currentPage == 1) return fetchTemplateList();

    return (pagination.currentPage = 1);
  };

  const useTemplate = () => {
    vm?.$emit('use-template', selectedTemplate.value);
  };

  watch(
    () => pagination.currentPage,
    () => {
      if (isChangedListStyle.value) return fetchTemplateList();
    },
  );

  return {
    field,
    pagination,
    templateFilterForm,
    templateFilterList,
    templateCategory,
    search,
    isChangedListStyle,
    templateCategoryList,
    selectedCategory,
    selectedTemplateCategory,
    isLoading,
    isListEmpty,
    templateList,
    keyChange,
    selectedTemplate,
    resolveCategoryIcon,
    onSelectCategory,
    fetchTemplateCardList,
    onCloseModal,
    onSelectFilter,
    onSearch,
    onClickViewAll,
    onChangeListStyle,
    onSort,
    onClickTemplateCard,
    useTemplate,
  };
}
