<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="155.29" height="103.595" viewBox="0 0 155.29 103.595">
    <g id="Group_45159" data-name="Group 45159" transform="translate(-263.881 -428.965)">
      <g id="Group_45160" data-name="Group 45160" transform="translate(263.881 428.965)">
        <g id="Group_45161" data-name="Group 45161" transform="translate(0 0)">
          <rect id="Rectangle_17076" data-name="Rectangle 17076" width="14.265" height="2.229" transform="translate(8.247 0) rotate(90)" />
          <rect id="Rectangle_17077" data-name="Rectangle 17077" width="14.265" height="2.229" transform="translate(0 6.018)" />
        </g>
      </g>
      <g id="Group_45162" data-name="Group 45162" transform="translate(423.222 522.474) rotate(135)">
        <g id="Group_45161-2" data-name="Group 45161" transform="translate(0 0)">
          <rect id="Rectangle_17076-2" data-name="Rectangle 17076" width="14.265" height="2.229" transform="translate(6.018 14.265) rotate(-90)" />
          <rect id="Rectangle_17077-2" data-name="Rectangle 17077" width="14.265" height="2.229" transform="translate(14.265 8.247) rotate(180)" />
        </g>
      </g>
      <circle id="Ellipse_1010" data-name="Ellipse 1010" cx="3.376" cy="3.376" r="3.376" transform="translate(263.881 504.632)" />
      <g id="Group_45172" data-name="Group 45172" transform="translate(408.368 447.917)">
        <circle id="Ellipse_1011" data-name="Ellipse 1011" cx="5.401" cy="5.401" r="5.401" />
        <circle id="Ellipse_1012" data-name="Ellipse 1012" cx="2.026" cy="2.026" r="2.026" transform="translate(6.752 13.503)" />
      </g>
      <g id="Group_45169" data-name="Group 45169" transform="translate(281.066 436.098)">
        <g id="Group_45169-2" data-name="Group 45169" transform="translate(2.049 12.794)">
          <rect id="Rectangle_17078" data-name="Rectangle 17078" width="115.974" height="3.683" transform="translate(0 12.334)" />
          <rect id="Rectangle_17079" data-name="Rectangle 17079" width="115.974" height="3.335" transform="translate(0 56.897)" />
          <g id="Group_45169-3" data-name="Group 45169" transform="translate(24.203 63.133)">
            <rect id="Rectangle_17080" data-name="Rectangle 17080" width="67.569" height="2.736" rx="1.368" transform="translate(0 2.3)" />
            <rect
              id="Rectangle_17081"
              data-name="Rectangle 17081"
              width="7.336"
              height="2.718"
              rx="1.359"
              transform="translate(6.393 0) rotate(90)"
            />
          </g>
          <g id="Group_45170" data-name="Group 45170" transform="translate(12.788)">
            <circle id="Ellipse_1013" data-name="Ellipse 1013" cx="3.999" cy="3.999" r="3.999" />
            <circle id="Ellipse_1014" data-name="Ellipse 1014" cx="3.999" cy="3.999" r="3.999" transform="translate(12.4)" />
            <circle id="Ellipse_1015" data-name="Ellipse 1015" cx="3.999" cy="3.999" r="3.999" transform="translate(24.8)" />
          </g>
          <g id="Group_45171" data-name="Group 45171" transform="translate(38.698 17.654)">
            <path
              id="Path_28707"
              data-name="Path 28707"
              d="M4754.979,1211.984v-11a.888.888,0,0,1,1.384-.74l8.129,5.5a.894.894,0,0,1,0,1.48l-8.129,5.5A.888.888,0,0,1,4754.979,1211.984Z"
              transform="translate(-4740.741 -1188.315)"
            />
            <path
              id="Path_28708"
              data-name="Path 28708"
              d="M4711.917,1185.779a18.171,18.171,0,1,1,18.171-18.171A18.191,18.191,0,0,1,4711.917,1185.779Zm0-31.675a13.5,13.5,0,1,0,13.5,13.5A13.519,13.519,0,0,0,4711.917,1154.1Z"
              transform="translate(-4693.747 -1149.436)"
            />
          </g>
        </g>
        <path
          id="Path_28709"
          data-name="Path 28709"
          d="M4653.321,1031.5H4547.869a7.308,7.308,0,0,0-7.309,7.309v81.845a7.308,7.308,0,0,0,7.309,7.309h105.452a7.308,7.308,0,0,0,7.31-7.309V1038.8A7.309,7.309,0,0,0,4653.321,1031.5Zm-2.547,86.607H4550.416v-76.751h100.358Z"
          transform="translate(-4540.561 -1031.496)"
        />
      </g>
    </g>
  </svg>
</template>
