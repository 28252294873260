export default {
  config: {
    role_all: 'All',
    role_super_admin: 'Super Admin',
    role_admin: 'Admin',
    role_editor: 'Editor',
    role_generaluser: 'General User',
    role_onlysee: 'Only See',
    status_all: 'Status All ',
    status_active: 'Active',
    status_inactive: 'Inactive',
    status_pending: 'Waiting',
    status_archived: 'Archived',

    manage_role: 'Manage Role',
    dashboard: 'Dashboard',
    create_audience: 'Create Audience',

    edit: 'Edit',
    delete: 'Delete',
    build: 'Build',
  },

  button_menu: {
    profile: 'Profile',
    manage_role: 'Manage Role',
  },

  profile_detail: {
    your_profile: 'Your Profile',
    manage_profile: 'Manage Profile',
    use: 'use',
    not_use: 'Not in use',
    format_image: 'Format : PNG,JPEG size not more than 2 Mbs',
    firstname: 'FirstName',
    lastname: 'LastName',
    phone: 'Phone',
    email: 'Email',
    role: 'Manage Role',
    title_role: 'View Role',
    save_done: 'Saved Successfully',
    save_fail: 'Failed To Save',
    upload_photo: 'Upload Photo',
    record: 'OK',
  },

  manage_all: {
    manage_role: 'Manage Role',
    manage_member: 'Manage Member',
  },

  user_list: {
    number: 'Number',
    image: 'Image',
    firstname: 'FirstName',
    lastname: 'LastName',
    phone: 'Phone',
    email: 'Email',
    role: 'Role',
    update: 'Update',
    status: 'Status',
    user_list: 'User List',
    manage_role: 'Manage Role',
    activity: 'Activity',
    role_all: 'Role All',
    status_all: 'Status All ',
    invite: 'Invite',
    manage: 'Manage',
    edit: 'Edit',
    delete: 'Delete',
    history: 'History',
    send_again: 'Send Again',
    cancel: 'Cancel',
  },

  manage_role: {
    number: 'Number',
    role: 'Role',
    update_by: 'Update By',
    update: 'Update',
    status: 'Status',
    user_list: 'User List',
    manage_role: 'Manage Role',
    role_all: 'Role All',
    status_all: 'Status All',
    title_role: 'View Role',
  },

  activity: {
    activity_all: {
      list: 'List',
      filter_data: 'Filter Data',
      clear_value: 'Clear all value',
      role: 'Role',
      function: 'Function',
      action: 'Action',
      update: 'Update',
      apply: 'Apply',
      reset: 'Reset',
      last_number: 'Top 5 most recently used',
      symbol: 'Symbol',
    },

    activity_per_person: {
      list: 'List',
      filter_data: 'Filter Data',
      clear_value: 'Clear all value',
      function: 'Function',
      action: 'Action',
      update: 'Update',
      apply: 'Apply',
      reset: 'Reset',
      last_number: 'Top 5 most recently used',
      symbol: 'Symbol',
      back: 'Back',
      history: 'History',
      profile: 'Profile',
      use: 'Use',
      not_use: 'Not in use',
      to: 'To',
    },
  },

  modal: {
    role_detail: {
      admin: 'Admin',
      editor: 'Editor',
      general_user: 'General User',
      only_see: 'Only See',
      role: 'Role',
      dashboard: 'Dashboard',
      create_audience: 'Create Audience',
      data_conector: 'Data Conector',
      communication: 'Communicaation',
      mka: 'Marketing Automation',
      manage_role: 'จัดการสิทธิ์การใช้งาน',
      change_password: 'Change Password',
      detail: 'Role Detail',
    },

    user_invite: {
      invite: 'Invite User',
      add_user_detail_1: 'Please enter the user email address. The system will send an invitation.',
      add_user_detail_2: 'Go to your email to login to ChocoCDP.',
      role_detail: 'Role Detail',
      add_email: 'Add Email',
      send_invite: 'Send Invite',
      manage_role: 'Manage Role',
      email: 'Email',
      role_all: 'Manage Role',
      placeholder: 'Select Role',
    },

    confirm_welcome: {
      title: 'Welcome',
      sub_title_1: 'You',
      sub_title_2: 'Login to ChocoCDP',
      content_1: 'ChocoCDP is a tool for doing Digital Marketing so that businesses can market effectively.',
      content_2: 'Helps manage customer data from various sources gathered into one place. Answering questions about analyzing customer data',
      content_3: 'To deliver good experiences appropriate for customers It also helps to increase sales opportunities better.',
      role: 'Role',
      start: 'Get started',
    },

    validation: {
      cancel: {
        invite_cancel_success: 'Invitation canceled successfully.',
        invite_cancel: 'Cancel invitation',
        want_invite_cancel: 'Want to cancel email invitation?',
        confirm_cancel: 'Confirm cancellation?',
        back: 'Back',
        ok: 'OK',
      },
      delete: {
        delete_success: 'User successfully deleted',
        delete_user: 'Delete user',
        want_delete_user: 'Want to delete an email user',
        confirm_delete_user: 'Confirm deletion?',
        back: 'Back',
        ok: 'OK',
      },
      resend: {
        invite_email_success: 'Invitation email sent successfully.',
        resend_email: 'Resend the invitation email',
        want_resend_email: 'To send an invitation to email',
        again: 'Again',
        please_click: 'Please click',
        send_again: '“Send again”',
        confirm_resend_email: 'for the invitee to accept the invitation',
        close: 'Close',
        ok: 'Send again',
      },
      warning: {
        title_size: 'The file size is too large.',
        desc_size: 'Please upload files no larger than “2 MB” in size.',
        title_type: 'Invalid file type',
        desc_type: 'Please upload file types “PNG and JEG” only',
      },
    },
  },

  invite_profile: {
    title: 'Your profile',
    sub_title: 'Please fill in the details about your profile.',
    firstname: 'FirstName',
    lastname: 'LastName',
    phone: 'Phone',
    email: 'Email',
    role: 'Manage Role',
  },

  create_password: {
    password: 'Password',
    confirm_password: 'Confirm Password',
    title: 'Create New Password',
    sub_title_step1: 'Your new password must be different',
    sub_title_step2: 'from previous used passwords.',
    placeholder_password: 'New Password',
    placeholder_confirm_password: 'Confirm New Password',
    rule1: 'Password must match',
    rule2: '8 Characters',
    rule3: 'One lowercase characters',
    rule4: 'One uppercase characters',
    rule5: 'One number characters',
    rule6: 'One number or special characters',
    btn: 'Create Pasword',
    btn_submit: 'OK',
    btn_back: 'Back',
  },
  reset_password: {
    password: 'Password',
    confirm_password: 'Confirm Password',
    title: 'Change Password',
    description_first: 'Please create a current password. and set a new password',
    placeholder: 'Your old password',
    button: 'Send',
    error_text: 'Password is invalid',
    notemail_text: 'This password was not found in the system. Please check again.',
    back: 'back',

    check_email: {
      title: 'Help is on the way',
      description_first: 'We’re combing through our records to find the CHOCO CDP account',
      description_second: 'For ',
      go_back: 'Go back to ChocoFor ',
      link_login: 'portal.chococdp.com/login',
    },

    expire_email: {
      title: 'Your URL link has expire',
      description_first: 'If you want to continue reset password,',
      re_click: 'please re’ click this link ',
      link_forgot_password: 'portal.chococdp.com/forgot-password',
    },

    confirm_change_password: {
      password_change_success: 'Password changed successfully',
      password_change_confirm: 'Confirm password change',
      sign_out: 'Sign out from every device',
      stay: 'Stay logged in',
      back: 'Back',
      ok: 'OK',
    },

    success_modal: {
      header: 'Password changed successfully.',
      title_1: 'Login user account',
      title_2: 'In all devices has been cleared.',
      submit: 'New login',
    },
  },
};
