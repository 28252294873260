import { ref, Ref, getCurrentInstance, watch } from 'vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import api from '@services/api';
// models
import templateModel from '@/models/communication/template';

// constants
import { NODE_ACTION_LINE_SUB_TYPE } from '@/constants/modules/mka/mkaDetail';
import { RICH_MESSAGE_TEMPLATE } from '@/views/modules/communication/pages/line-oa-campaign/constants/richMessage';
import { ContentTypeEnum } from '@/views/modules/communication/pages/line-oa-campaign/constants/contentType.enum';

import { Channel as ChannelConstant } from '@/views/modules/communication/pages/main/constants/channel';

interface Props {
  //setup props

  isOpen: boolean;
  dataInfo: MKA_Nodes.NodeDataInfo.DataInfo;
  templateList: number[];
}

export default function useDataInfoPreview(props: Props) {
  // constants
  const templateConstant: Communication.Template.Template = {
    brand_id: 0,
    category_id: 0,
    category_name: '',
    channel: 0,
    channel_desc: '',
    contents: [],
    created_by: 0,
    created_by_req: 0,
    created_dt: '',
    description: '',
    is_deleted: false,
    name: '',
    status: 0,
    status_desc: '',
    subtype: 0,
    subtype_desc: '',
    template_id: 0,
    thumbnail_sref: '',
    thumbnail_url: '',
    type: 0,
    type_desc: '',
    updated_by: '',
    updated_by_req: '',
    updated_dt: '',
  };
  const vm = getCurrentInstance()?.proxy;
  const { openErrorModal } = useValidationModal();

  const { fetchTemplateDetailModel } = templateModel();

  const allTemplatePreview = ref<Communication.Template.Template[]>([]);
  const edmTemplatePreview = ref<Communication.Template.Template>(templateConstant);

  // loadings
  const loading = ref<boolean>(false);
  const loadingTemplate = ref<boolean>(false);

  const currentChannel = ref<Communication.Campaign.ChannelType>(0);

  //messages
  const messageList = ref<Communication.LineOA.Component.LineMessage[]>([]);
  const cardMessageList = ref<Communication.LineOA.Component.CardMessge[]>([]);
  const richMessageList: Ref<Communication.LineOA.Component.RichMessage> = ref(null!);

  const previewMixMessageList: Ref<Communication.LineOA.Component.LineMessage[]> = ref([]);
  const indexPreviewMixMessage: Ref<number> = ref(0);

  const keyChange = ref<number>(1);
  const dataInfo = ref<MKA_Nodes.NodeDataInfo.DataInfo>(props.dataInfo);

  const subTypeLine = ref<number>(0);

  const fetchTemplateDetail = async (templateId: number): Promise<void> => {
    // clear data before fetch data
    indexPreviewMixMessage.value = 0;
    keyChange.value = 1;

    fetchTemplateDetailModel.payload.TemplateId = templateId;
    return api
      .apiRequest(fetchTemplateDetailModel)
      .then((response) => {
        const responseData = response.data as Communication.Template.Template;
        edmTemplatePreview.value = responseData;
        // clear allTemplate before mapping first message
        allTemplatePreview.value = [];
        allTemplatePreview.value.push(responseData);
        currentChannel.value = responseData.channel as Communication.Campaign.ChannelType;

        Promise.resolve();
      })
      .catch((err) => {
        openErrorModal(err as string);
        Promise.reject();
      })
      .finally(() => {});
  };

  const resolveDisplayMessageSms = (contentList: Communication.Template.TemplateContent[]) => {
    const resolvedList: any = [];

    if (contentList && contentList.length) {
      contentList.forEach((contentData) => {
        const displayContent = {
          key: keyChange.value,
          sender: dataInfo.value.sender_name || '',
          message: contentData.content_editor ? formatTextSMS(contentData.content_editor) : '',
        };
        resolvedList.push(displayContent);
        keyChange.value++;
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: dataInfo.value.sender_name || '', message: '' });
      keyChange.value++;
    }
    return resolvedList;
  };

  function buildObjectDisplayMessage(resolvedData: Communication.LineOA.Component.LineMessage) {
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: 0,
      sender: '',
      message: '',
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: undefined,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };
    const combinedObject = { ...defaultLineMessage, ...resolvedData };

    return combinedObject;
  }

  const resolveDisplayMessageLine = (contentList: Communication.Template.TemplateContent[], isMixType = false) => {
    const resolvedList: Communication.LineOA.Component.LineMessage[] = [];

    if (contentList && contentList.length) {
      contentList.forEach((contentData) => {
        if (contentData.content) {
          const contentOBJ = JSON.parse(contentData.content);
          let displayContent: Communication.LineOA.Component.LineMessage = { key: keyChange.value };
          if (contentOBJ.type == 'text') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              message: contentData.content_editor ? formatTextSMS(contentData.content_editor) : '',
            };
          } else if (contentOBJ.type == 'image') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              imageUrl: contentOBJ.previewImageUrl || '',
              previewImageUrl: contentOBJ.previewImageUrl,
              sref: contentOBJ.sref,
            };
          } else if (contentOBJ.type == 'video') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              videoUrl: contentOBJ.originalContentUrl || '',
              previewImageUrl: contentOBJ.previewImageUrl,
              sref: contentOBJ.sref,
            };
          } else if (contentOBJ.type == 'audio') {
            displayContent = {
              key: keyChange.value,
              sender: dataInfo.value.sender_name || '',
              audioUrl: contentOBJ.originalContentUrl || '',
              duration: contentOBJ.duration,
              sref: contentOBJ.sref,
            };
          } else {
            displayContent = { key: keyChange.value, sender: dataInfo.value.sender_name || '' };
          }
          resolvedList.push(displayContent);
          keyChange.value++;
        }
      });
    } else {
      resolvedList.push({ key: keyChange.value, sender: dataInfo.value.sender_name || '', message: '' });
      keyChange.value++;
    }

    // previewMixMessageList : data <preview-message>
    if (isMixType != true) {
      for (let i = 0; i < resolvedList.length; i++) {
        const objData = buildObjectDisplayMessage(resolvedList[i]);
        previewMixMessageList.value[i] = objData;
      }
    } else {
      const objData = buildObjectDisplayMessage(resolvedList[0]);
      previewMixMessageList.value[indexPreviewMixMessage.value] = objData;
    }

    return resolvedList;
  };

  function resolveDisplayCard(content: Communication.LineOA.CreateCampaign.Content[], isMixType = false) {
    let resolvedList: Communication.LineOA.Component.CardMessge[] = [];
    let url: string = '';
    let sref: string = '';

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        if (content.content_editor !== '{}') {
          contentEditor.list_card.forEach((card: Communication.LineOA.Component.CardMessge) => {
            resolvedList.push({
              ...card,
              sender: dataInfo.value.sender_name || '',
            });
          });

          url = contentEditor.thumbnail?.url;
          sref = contentEditor.thumbnail?.sref;
        } else {
          resolvedList = [];
        }

        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedList;
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: undefined,
      cardMessageList: resolvedList,
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: url,
        sref: sref,
      },
    };
    if (isMixType != true) {
      previewMixMessageList.value[0] = defaultLineMessage;
    } else {
      previewMixMessageList.value[indexPreviewMixMessage.value] = defaultLineMessage;
    }

    return (cardMessageList.value = resolvedList);
  }

  function resolveDisplayRichMessage(content: Communication.LineOA.CreateCampaign.Content[], isMixType = false) {
    let resolvedContent: Communication.LineOA.Component.RichMessage = null!;

    if (content && content.length) {
      content.forEach((content) => {
        let contentEditor: any;
        if (isJsonString(content.content_editor) && content.content_editor) {
          contentEditor = JSON.parse(content.content_editor || '');
        } else {
          contentEditor = content.content_editor || '';
        }
        if (content.content_editor !== '{}') {
          resolvedContent = {
            ...contentEditor,
            gridType: RICH_MESSAGE_TEMPLATE.find((message) => message.key == contentEditor.gridType),
            sender: dataInfo.value.sender_name || '',
          };
        } else {
          resolvedContent = null!;
        }
        keyChange.value++;
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }

    // add data for send <preview-message>
    const defaultLineMessage: Communication.LineOA.Component.LineMessage = {
      key: keyChange.value - 1,
      sender: '',
      message: undefined,
      imageUrl: '',
      videoUrl: '',
      audioUrl: '',
      richMessage: resolvedContent,
      cardMessageList: [],
      previewImageUrl: '',
      duration: 0,
      sref: '',
      thumbnail: {
        url: '',
        sref: '',
      },
    };
    if (isMixType != true) {
      previewMixMessageList.value[0] = defaultLineMessage;
    } else {
      previewMixMessageList.value[indexPreviewMixMessage.value] = defaultLineMessage;
    }

    return (richMessageList.value = resolvedContent);
  }

  function resolveDisplayMultiMessage(content: Communication.LineOA.CreateCampaign.Content[]) {
    let resolvedContent: Communication.LineOA.Component.RichMessage = null!;
    let dataList: Communication.LineOA.CreateCampaign.Content[] = [];

    if (content && content.length) {
      content.forEach((contentText, index) => {
        let contentEditor: any;
        dataList = [];
        dataList.push(contentText);
        if (isJsonString(contentText.content_editor) && contentText.content_editor) {
          contentEditor = JSON.parse(contentText.content_editor || '');
        } else {
          contentEditor = contentText.content_editor || '';
        }

        if (contentEditor.type == ContentTypeEnum.TEXT) {
          resolveDisplayMessageLine(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.IMAGE) {
          resolveDisplayMessageLine(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.VIDEO) {
          resolveDisplayMessageLine(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.AUDIO) {
          resolveDisplayMessageLine(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.RICH_MESSAGE) {
          resolveDisplayRichMessage(dataList, true);
        } else if (contentEditor.type == ContentTypeEnum.CARD_MESSAGE) {
          resolveDisplayCard(dataList, true);
        }

        indexPreviewMixMessage.value++;
      });
    } else {
      keyChange.value++;
      return resolvedContent;
    }
  }

  const resolveChannel = (channel: number): Communication.Template.Request.ChannelType => {
    switch (channel) {
      case 1:
        return ChannelConstant.sms;
      case 2:
        return ChannelConstant.email;
      case 3:
        return ChannelConstant.lineOA;
      default:
        return 0;
    }
  };

  const onLoadDataInfoPreview = () => {
    loading.value = true;
    loadingTemplate.value = true;
    if (props.templateList.length > 0) {
      fetchTemplateDetail(props.templateList[0]);
      loadingTemplate.value = false;
    }

    subTypeLine.value = allTemplatePreview.value[0]?.subtype; // for check type line is [message,Card-Message or Rich-Message]

    // mapping first message
    if (resolveChannel(currentChannel.value) !== ChannelConstant.email) {
      const templateContents = allTemplatePreview.value.map((item) => {
        if (item.contents) {
          return item.contents;
        } else {
          return {
            content: '-',
            content_editor: '-',
            title: '-',
          };
        }
      });

      if (resolveChannel(currentChannel.value) == ChannelConstant.sms) {
        allTemplatePreview.value = [];
        messageList.value = resolveDisplayMessageSms(templateContents.flat());
      } else if (resolveChannel(currentChannel.value) == ChannelConstant.lineOA) {
        allTemplatePreview.value = [];

        if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.MESSAGE) {
          messageList.value = resolveDisplayMessageLine(templateContents.flat());
        } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.RICH_MESSAGE) {
          resolveDisplayRichMessage(templateContents.flat() as Communication.LineOA.CreateCampaign.Content[]);
        } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.CARD_MESSAGE) {
          resolveDisplayCard(templateContents.flat() as Communication.LineOA.CreateCampaign.Content[]);
        } else if (subTypeLine.value == NODE_ACTION_LINE_SUB_TYPE.MULTI_MESSAGE) {
          resolveDisplayMultiMessage(templateContents.flat() as Communication.LineOA.CreateCampaign.Content[]);
        }
      }
    }
    loading.value = false;
  };
  function isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  function formatTextSMS(smsText: string) {
    if (isJsonString(smsText)) {
      const parseMessage = JSON.parse(smsText);
      return decodeURI((parseMessage.text as string) || ''); // SMS Personalize
    } else {
      return smsText; // SMS Normal Text
    }
  }

  // modal action
  function onClickClose() {
    vm?.$emit('close-modal');
  }

  watch(
    [() => props.isOpen],
    () => {
      onLoadDataInfoPreview();
    },
    { immediate: true },
  );

  return {
    onLoadDataInfoPreview,
    onClickClose,
    edmTemplatePreview,
    allTemplatePreview,
    messageList,
    loading,
    loadingTemplate,
    resolveChannel,
    currentChannel,
    cardMessageList,
    richMessageList,
    previewMixMessageList,
  };
}
