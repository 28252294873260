import { MKA_NODE } from '@/types/modules/mka/mka';

interface MKA_MODALS {
  EntrySegment: string;

  FlowForkSplit: string;
  DistributionSplit: string;
  RandomSplitTrue: string;
  RandomSplitNormal: string;
  FlowWaitByDuration: string;
  FlowWaitUntilDate: string;
  Merge: string;
  Limit: string;

  ActionSms: string;
  ActionEdm: string;
  ActionLine: string;
}

interface NODE_ID {
  ENTRY_SEGMENT: string;
  FORK_SPLIT: string;
  DISTRIBUTION_SPLIT: string;
  RANDOM_SPLIT_TRUE: string;
  RANDOM_SPLIT_NORMAL: string;
  WAIT_BY_DURATION: string;
  WAIT_UNTIL_DATE: string;
  MERGE: string;
  LIMIT: string;
  ACTION_SMS: string;
  ACTION_LINE: string;
}

interface JOURNEY_STATUS {
  DRAFT: number;
  PENDING: number;
  PUBLISHED: number;
}

export const NODE_ID: NODE_ID = {
  ENTRY_SEGMENT: '101_0',
  FORK_SPLIT: '202_1',
  DISTRIBUTION_SPLIT: '202_2',
  RANDOM_SPLIT_TRUE: '202_3',
  RANDOM_SPLIT_NORMAL: '202_4',
  WAIT_BY_DURATION: '203_1',
  WAIT_UNTIL_DATE: '203_2',
  MERGE: '204_0',
  LIMIT: '205_0',
  ACTION_SMS: '301_0',
  ACTION_LINE: '303_0',
};

export const MKA_MODEL_COMPONENTS: MKA_MODALS = {
  EntrySegment: 'ModalEntrySegment',
  FlowForkSplit: 'ModalFlowForkSplit',
  DistributionSplit: 'ModalDistributionSplit',
  RandomSplitTrue: 'ModalRandomSplitTrue',
  RandomSplitNormal: 'ModalRandomSplitNormal',
  FlowWaitByDuration: 'ModalFlowWaitByDuration',
  FlowWaitUntilDate: 'ModalFlowWaitUntilDate',
  Merge: 'ModalMerge',
  Limit: 'ModalLimit',

  ActionSms: 'ModalActionSms',
  ActionEdm: 'ModalActionEdm',
  ActionLine: 'ModalActionLine',
};

export const MKA_MODEL_COMPONENTS_LIST = [
  'ModalEntrySegment',

  'ModalFlowForkSplit',
  'ModalDistributionSplit',
  'ModalRandomSplitTrue',
  'ModalRandomSplitNormal',
  'ModalFlowWaitByDuration',
  'ModalFlowWaitUntilDate',
  'ModalMerge',
  'ModalLimit',

  'ModalActionSms',
  'ModalActionLine',
];

export const MKA_NODE_CONSTANT_LIST: { [key: string]: MKA_NODE } = {
  '101_0': {
    id: 0,
    category: 1,
    type: 101,
    subType: 0,
    title: 'mka.entry_source_segment',
    subTitle: '',
    icon: 'IconMkaEntrySegment',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 1,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {},
  },
  '202_1': {
    id: 0,
    category: 2,
    type: 202,
    subType: 1,
    title: 'mka.flow_control_fork_split',
    subTitle: '',
    icon: 'IconMkaFlowForkSplit',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: { branch: { label: '2', id: 2 } },
  },
  '202_2': {
    id: 0,
    category: 2,
    type: 202,
    subType: 2,
    title: 'mka.flow_control_distribution_split',
    subTitle: 'mka.flow_control_distribution_split_sub',
    icon: 'IconMkaFlowDistributionSplit',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: { branch: { label: '2', id: 2 } },
  },
  '202_3': {
    id: 0,
    category: 2,
    type: 202,
    subType: 3,
    title: 'mka.flow_control_random_split_true',
    subTitle: 'mka.flow_control_random_split_true_sub',
    icon: 'IconMkaFlowRandomSplitTrue',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: ['A : 50%', 'B : 50%'],
    payload: {
      connectList: [
        {
          label: 'A',
          link: null,
          display: null,
          percent: null,
          autoPercent: 50,
        },
        {
          label: 'B',
          link: null,
          display: null,
          percent: null,
          autoPercent: 50,
        },
      ],
    },
  },
  '202_4': {
    id: 0,
    category: 2,
    type: 202,
    subType: 4,
    title: 'mka.flow_control_random_split_normal',
    subTitle: 'mka.flow_control_random_split_normal_sub',
    icon: 'IconMkaFlowRandomSplitNormalize',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: ['A : 50%', 'B : 50%'],
    payload: {
      connectList: [
        {
          label: 'A',
          link: null,
          display: null,
          percent: null,
          autoPercent: 50,
        },
        {
          label: 'B',
          link: null,
          display: null,
          percent: null,
          autoPercent: 50,
        },
      ],
    },
  },
  '203_1': {
    id: 0,
    category: 2,
    type: 203,
    subType: 1,
    title: 'mka.flow_control_wait_by_duration',
    subTitle: '',
    icon: 'IconMkaFlowWaitByDuration',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 1,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {},
  },
  '203_2': {
    id: 0,
    category: 2,
    type: 203,
    subType: 2,
    title: 'mka.flow_control_wait_until_date',
    subTitle: '',
    icon: 'IconMkaFlowWaitUntilDate',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 1,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {},
  },
  '204_0': {
    id: 0,
    category: 2,
    type: 204,
    subType: 0,
    title: 'mka.flow_control_merge',
    subTitle: '',
    icon: 'IconMkaFlowMerge',
    info: '',
    editable: true,
    false_link: false,
    nodeIn: 1,
    nodeOut: 1,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {},
  },
  '205_0': {
    id: 0,
    category: 2,
    type: 205,
    subType: 0,
    title: 'mka.flow_control_limit',
    subTitle: '',
    icon: 'IconMkaFlowLimit',
    info: '',
    editable: true,
    false_link: true,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {},
  },
  '301_0': {
    id: 0,
    category: 3,
    type: 301,
    subType: 0,
    title: 'mka.actions_sms',
    subTitle: '',
    icon: 'IconMkaActionSms',
    info: '',
    editable: true,
    false_link: true,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {
      mode: 2,
      templateId: [],
      providerId: null,
      contents: [
        {
          title: '',
          content: '',
          content_editor: '',
        },
      ],
    },
  },
  '302_0': {
    id: 0,
    category: 3,
    type: 302,
    subType: 0,
    title: 'mka.actions_edm',
    subTitle: '',
    icon: 'IconMkaActionEDM',
    info: '',
    editable: true,
    false_link: true,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {
      mode: 2,
      templateId: [],
      providerId: null,
      contents: [
        {
          title: '',
          content: '',
          content_editor: '',
        },
      ],
    },
  },
  '303_0': {
    id: 0,
    category: 3,
    type: 303,
    subType: 0,
    title: 'mka.actions_line',
    subTitle: '',
    icon: 'IconMkaActionLineOA',
    info: '',
    editable: true,
    false_link: true,
    nodeIn: 1,
    nodeOut: 2,
    nodeInLabel: [],
    nodeOutLabel: [],
    payload: {
      mode: 2,
      templateId: [],
      providerId: null,
      contents: [
        {
          title: '',
          content: '',
          content_editor: '',
        },
        {
          title: '',
          content: '',
          content_editor: '',
        },
        {
          title: '',
          content: '',
          content_editor: '',
        },
      ],
    },
  },
};

export const MKA_NODE_TYPE = {
  Source: 'source',
  FlowControl: 'flow-control',
  Action: 'action',
};

export const JOURNEY_STATUS = {
  DRAFT: 1,
  PENDING: 2,
  PUBLISHED: 3,
  PAUSE: 4,
  ARCHIVED: 9,
};

export const JOURNEY_SAVE_MODE = {
  INFO: 'info',
  CONTENT: 'content',
  PUBLISHED: 'publishedSave',
};

export const COMM_Transaction_Status = { All: 0, Pending: 1, Mapped: 2, PDPA_Restricted: 4, Out_Of_Credit: 7, Sent: 9, Error: 11, Queued: 100 };

export const NODE_ACTION_LINE_SUB_TYPE = {
  ALL: 0,
  MESSAGE: 31,
  RICH_MESSAGE: 32,
  CARD_MESSAGE: 33,
  MULTI_MESSAGE: 30,
};
