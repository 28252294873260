export default function templateModel() {
  const fetchTemplateCardModel: API.RequestModel<Communication.Template.Request.ListCard> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/ListCard`,
    method: 'POST',
    payload: {
      Channel: null!,
      Status: 1,
      Type: null,
      List_SubType: [],
      SubType: null,
      CategoryId: null,
      Query: '',
      Filters: [],
      Ordering: [],
      Limit: 99,
    },
  };

  const fetchTemplateListModel: API.RequestModel<Communication.Template.Request.FetchTemplateList> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/List`,
    method: 'POST',
    payload: {
      Channel: null!,
      Status: 1,
      Type: null,
      List_SubType: [],
      SubType: null,
      CategoryId: null,
      Query: '',
      Filters: [],
      Ordering: [],
      Limit: 10,
      Page: 1,
    },
  };

  const createTemplateModel: API.RequestModel<Communication.Template.Request.CreateTemplate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/Add`,
    method: 'POST',
    payload: {
      Channel: null!,
      Type: 2,
      SubType: null!,
      CategoryId: null,
      Name: null!,
      Description: null,
      Contents: [],
      Deleted_File_Sref: [],
      Thumbnail_Url: null!,
      Thumbnail_Sref: null!,
    },
  };

  const fetchTemplateDetailModel: API.RequestModel<Communication.Template.Request.FetchTemplateDetail> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/Detail`,
    method: 'POST',
    payload: {
      TemplateId: null!,
    },
  };

  const editTemplateModel: API.RequestModel<Communication.Template.Request.EditTemplate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/Edit`,
    method: 'POST',
    payload: {
      Channel: null!,
      TemplateId: null!,
      Status: null,
      Type: null!,
      SubType: null!,
      CategoryId: null!,
      Name: null!,
      Description: null!,
      Contents: [],
      Deleted_File_Sref: [],
      Thumbnail_Url: null!,
    },
  };

  const duplicateTemplateModel: API.RequestModel<Communication.Template.Request.DuplicateTemplate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/Duplicate`,
    method: 'POST',
    payload: {
      TemplateId: null!,
    },
  };

  const deleteTemplateModel: API.RequestModel<Communication.Template.Request.DeleteTemplate> = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/Delete`,
    method: 'POST',
    payload: {
      TemplateId: null!,
    },
  };

  const getCategoryListModel: API.RequestModel = {
    endpoint: `${process.env.BASE_URL_COMMUNICATION!}/Comm/Template/Category_List`,
    method: 'GET',
    payload: {},
  };

  return {
    fetchTemplateCardModel,
    fetchTemplateListModel,
    createTemplateModel,
    fetchTemplateDetailModel,
    editTemplateModel,
    deleteTemplateModel,
    getCategoryListModel,
    duplicateTemplateModel,
  };
}
