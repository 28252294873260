import axios, { AxiosInstance } from 'axios';
import authService from '@/services/authentication';
import useAuthentication from '@/utils/hooks/useAuthentication';
import router from '@/router';

const REFRESH_API = `${process.env.BASE_URL_CDP_CORE}/account/refreshToken`;

const axiosInstance: AxiosInstance = axios.create();

const { logout } = useAuthentication();

const fetchBrandRef = () => {
  return new URLSearchParams(window.location.search).get('brandRef') || sessionStorage.getItem('brandSessionData');
};

const fetchRefreshToken = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(REFRESH_API, authService.fetchBrandData())
      .then((res: Record<string, any>) => {
        let tempBrand: Array<any> = [];
        if (authService.getLocalBrandData()) {
          tempBrand = [
            ...JSON.parse(authService.getLocalBrandData()!)?.filter((o: Record<string, any>) => {
              return o.brandRef != fetchBrandRef();
            }),
          ];
        }
        tempBrand.push({
          brandRef: fetchBrandRef(),
          brandData: authService.fetchBrandData()?.brandData,
          accessToken: res.data.data.access_token,
          refreshToken: res.data.data.refresh_token,
        });
        authService.setLocalBrandData(JSON.stringify(tempBrand));
        resolve(res);
      })
      .catch((err) => {
        alert(err.response.data.error.locale['en-us'].message || err.response.data.error.message); // TODO: using toast
        if (err.response.status === 500) {
          logout();
        }
        if (err.response.status === 401) {
          logout();
        }
        if (err.response.status === 422) {
          authService.isClearLocalStorage();
          window.location.href = '/login';
        }

        reject(err);
      });
  });
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url === REFRESH_API) {
      return Promise.reject(err.response);
    }

    if (err.response.status === 400) {
      return Promise.reject(err);
    }

    if (err.response.status === 401 && !originalConfig.retry) {
      originalConfig.retry = true;

      await fetchRefreshToken();

      originalConfig.headers.Authorization = `Bearer ${authService.fetchBrandData()?.access_token}`;

      return axiosInstance(originalConfig);
    }

    if (err.response.status === 401 && originalConfig.retry) {
      return Promise.reject(err.response);
    }

    return Promise.reject(err);
  },
);

export default axiosInstance;
