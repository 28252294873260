import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

export default function useSplitDateTime() {
  const { t } = useI18n();

  const splitDate = (dateString: string, format?: string) => {
    let resolveValue = '';

    if (!dateString) {
      return '-';
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const dateObject = DateTime.fromISO(dateString, { zone: timezone });
    const currentDateObject = DateTime.now().setZone(timezone as string);
    const duration = dateObject.diff(currentDateObject, ['hour', 'minute', 'second']);

    if (Math.abs(duration.hours) > 24) {
      resolveValue = format ? dateObject.toFormat(format) : dateObject.toFormat('dd-LLL-yyyy');
    } else if (Math.abs(duration.hours) <= 24 && Math.abs(duration.hours) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.hours))} hours ago`;
    } else if (Math.abs(duration.minutes) < 60 && Math.abs(duration.minutes) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.minutes))} ` + t('segment.detail_segment.minutes_ago'); // minutes ago
    } else {
      resolveValue = `${Math.ceil(Math.abs(duration.seconds))} ` + t('segment.detail_segment.seconds_ago'); // seconds ago
    }

    return resolveValue;
  };

  const splitTime = (dateString: string, format?: string) => {
    let resolveValue = '';

    if (!dateString) {
      return '-';
    }

    const userInfo = JSON.parse(localStorage.getItem('userInfo') || 'null');
    const timezone = userInfo.timezone || DateTime.fromISO(new Date().toISOString()).zoneName;
    const dateObject = DateTime.fromISO(dateString, { zone: timezone });
    const currentDateObject = DateTime.now().setZone(timezone as string);
    const duration = dateObject.diff(currentDateObject, ['hour', 'minute', 'second']);

    if (Math.abs(duration.hours) > 24) {
      resolveValue = format ? dateObject.toFormat(format) : dateObject.toFormat('HH:mm a');
    } else if (Math.abs(duration.hours) <= 24 && Math.abs(duration.hours) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.hours))} hours ago`;
    } else if (Math.abs(duration.minutes) < 60 && Math.abs(duration.minutes) >= 1) {
      resolveValue = `${Math.ceil(Math.abs(duration.minutes))} ` + t('segment.detail_segment.minutes_ago'); // minutes ago
    } else {
      resolveValue = `${Math.ceil(Math.abs(duration.seconds))} ` + t('segment.detail_segment.seconds_ago'); // seconds ago
    }

    return resolveValue;
  };

  return {
    splitDate,
    splitTime,
  };
}
