export default {
  campaign: {
    line_oa: {
      create: {
        uploading: 'กำลังอัพโหลด',
        error: 'เกิดข้อผิดพลาด',
        filenameExists: 'มีไฟล์ชื่อนี้อยู่แล้ว โปรดเปลี่ยนชื่อไฟล์',
        fileOverSize: 'ขนาดไฟล์ใหญ่เกินไป',
        wrongFileType: 'ไฟล์ผิดประเภท',
        uploadImage: 'อัพโหลดรูปภาพ',
        uploadVideo: 'อัพโหลดวิดีโอ',
        uploadVoice: 'อัพโหลดเสียง',
        actionToolTip: 'ป้ายแอคชั่นจะแสดงบนอุปกรณ์ที่ไม่รองรับและอ่านโดยการแปลงข้อความเป็นคำพูด',
      },
    },
    sms: {},
  },
  resume_campaign: {
    campaign_list_paused: {
      button_back: 'ย้อนกลับ',
      tab: {
        all_list_paused_tab: 'แคมเปญทั้งหมด',
        commu_list_paused_tab: 'การสื่อสาร',
        mka_list_paused_tab: 'การตลาดอัตโนมัติ',
      },
      box_detail: {
        channel: 'ช่องทาง',
        detail_channel: {
          all: 'ทั้งหมด',
          communication: 'การสื่อสาร',
          mka: 'การตลาดอัตโนมัติ',
        },
        campaignUsing: 'แคมเปญที่ใช้งานอยู่',
        requiredCredit: 'เครดิตที่ต้องใช้',
      },
      next: 'ถัดไป',
      confirm_all_button: 'เริ่มการใช้งานทั้งหมด',
      confirm_button: 'เริ่มการใช้งาน',
      confirm_count_button: 'รายการ',
    },

    check_export_conditions: {
      title: 'ตรวจสอบเงื่อนไขการส่งออก',
      box_detail: {
        count_campaign_select: ' จำนวนเเคมเปญที่เลือก',
        requiredCredit: 'เครดิตที่ต้องใช้',
      },
      box_conditions: {
        title_time: 'กรุณาเลือกเวลาส่งซ่อม ',
        detail_time: {
          time: 'กำหนดวัน และเวลา',
          now: 'ส่งทันที',
        },

        title_return: 'กรุณาเลือกโหมดการส่งย้อนหลัง',
        detail_return: {
          send: { start: '', middle: 'ส่งข้อความ', end: 'ไปยังผู้รอรับคงค้าง' },
          unsend: { start: '', middle: 'ไม่ส่งข้อความ', end: 'ไปยังผู้รอรับคงค้าง' },
        },

        title_round: 'กรุณาเลือกรอบการส่งย้อนหลัง',
        detail_round: {
          all_round: 'ส่งย้อนหลังทั้งหมด',
          latest_round: 'ส่งย้อนหลังเฉพาะรอบการส่งล่าสุด',
          custom_round: 'กำหนดรอบการส่งย้อนหลัง',
        },
        manage_campaigns: 'เลือก',
      },
      confirm_button: 'เริ่มการส่งซ้ำ',
    },
  },
  //resume_campaign
  No: 'ลำดับ',
  All: 'ทั้งหมด',
  communication: 'การสื่อสาร',
  campaign_list_paused: 'รายการแคมเปญหยุดชั่วคราว',
  mka: 'การตลาดอัตโนมัติ',
  bi: 'กราฟ',
  ins: 'ข้อมูลลูกค้าเชิงลึก',
  dc: 'เชื่อมต่อข้อมูล (นำเข้า)',
  di: 'การนำเข้าข้อมูล',
  seg: 'จัดกลุ่มเป้าหมาย',
  perm: 'สิทธิการใช้งาน',
  show: 'โชว์',
  list: 'รายการ',
  CampaignName: 'ชื่อแคมเปญ',
  function: 'ฟังก์ชั่น',
  module: 'โมดูล',
  channel: 'ช่องทาง',
  schedule_mode: 'รูปแบบการส่ง',
  count_dispatchable: 'จำนวนผู้รับ',
  count_success: 'จำนวนผู้รับที่ส่งออกสำเร็จ',
  count_pendingcredit: 'จำนวนผู้รับที่รอการส่ง',
  est_credit_use: 'เครดิตที่ต้องใช้',
  updated: 'อัปเดตล่าสุด',
  status: 'สถานะ',
  CampaignNameExpand: 'ชื่อเทมเพลตที่ใช้ใน node',
  send_now: 'ส่งทันที',
  schedule_later: 'กำหนดเวลาในภายหลัง',
  repeat: 'ส่งซ้ำ',
  pause: 'หยุดชั่วคราว',
  draft: 'แบบร่าง',
  complete: 'สำเร็จ',
  cancel: 'ยกเลิก',
  dispath: 'กำลังส่ง',
  scheduled: 'รอดำเนินการ', //Scheduled
  preparing: 'เตรียมข้อมูล',
  resendInformation: 'ข้อมูลการส่งซ้ำ',
  totalResending: 'จำนวนการส่งซ้ำคงเหลือ',
  round: 'รอบที่',
  day: 'วัน',
  month: 'เดือน',
  year: 'ปี',
  outstandingAmount: 'จำนวนคงค้าง',
  apply: 'นำไปใช้',
  manageCampaignsInScheduledRepeat: 'จัดการแคมเปญในรูปแบบบการส่งซ้ำ',
  time: 'เวลา',
  minute: 'น.',
  sentEvery: 'ส่งทุก',

  monday: 'วันจันทร์',
  tuesday: 'วันอังคาร',
  wednesday: 'วันพุธ',
  thursday: 'วันพฤหัสบดี',
  friday: 'วันศุกร์',
  saturday: 'วันเสาร์',
  End: 'สิ้นสุด',
  completed_successfully: 'ทำรายการสำเร็จ',
  error_occurred: 'เกิดข้อผิดพลาด',
  check_export_conditions: 'ยืนยันตรวจสอบเงื่อนไขการส่งออก',
  confirm_YesOrNo: 'ต้องการยืนยันใช่หรือไม่?',
  tooltip_column_accum_recp:
    'นับแบบสะสม, แคมเปญการสื่อสารแบบส่งครั้งเดียวจะมีจำนวนตามรอบเดียวที่ run, แคมเปญการสื่อสารแบบวนส่งซ้ำจะนับสะสมตั้งแต่รอบที่ 1 - รอบล่าสุดที่ run ไปแล้ว, แคมเปญการตลาดอัตโนมัติจะนับสะสมเรื่อยๆ ตามยอดผู้รับปัจจุบัน (นับเฉพาะผู้รับที่ส่งแล้วเครดิตไม่พอ)',
  tooltip_column_accum_recp_pendingcredit: 'ผู้รับที่ส่งแล้วเครดิตไม่พอ​​',
  tooltip_column_est_credit_use: 'เครติดที่ต้องใช้โดยประมาณ',
  tooltip_datetimeMode: 'กำหนดเวลาการเริ่มทำงานอีกครั้งของแคมเปญ',
  tooltip_resendMode: 'ต้องการให้ระบบส่งข้อความย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกเนื่องจากเครดิตหมดหรือไม่​',
  tooltip_resend: 'ส่งข้อความย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกข้อความเนื่องจากเครดิตหมด',
  tooltip_DoNot_resend: 'ไม่ต้องการส่งข้อความย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกข้อความเนื่องจากเครดิตหมด',
  tooltip_resendPreviousMode: 'เลือกรูปแบบการส่งย้อนหลังไปยังผู้รับคงค้างระหว่างระบบหยุดส่งออกข้อความเนื่องจากเครดิตหมด',
  tooltip_resend_allPrevious: 'ส่งข้อความย้อนหลังไปยังผู้รับคงค้างทั้งหมด​',
  tooltip_resend_lastPreviousRound: 'ส่งข้อความย้อนหลังไปยังผู้รับคงค้างเฉพาะรอบการส่งออกล่าสุด',
  tooltip_resend_lastPreviousRoundRepeat:
    'ต้องการส่งย้อนไปยังผู้รอรับคงค้างเฉพาะรอบล่าสุด กรณีมีมากกว่า 1 รอบการส่งค้าง ตัวอย่างเช่น มีการตั้งส่งทุกวันที่จันทร์ รอบคงค้างเนื่องจากเครดิตหมด มีจำนวน 3 จันทร์ ต้องการส่งย้อนหลังไปยังผู้รับคงค้างเฉพาะผู้ที่ควรจะได้รับรอบวันจันทร์รอบล่าสุดที่มีการคงค้างเท่านั้น',
  tooltip_resend_specifyPrevious: 'ต้องการเลือกรอบส่งแบบกำหนดเอง กรณีมีมากกว่า 1 รอบการส่งค้าง',
  sunday: 'วันอาทิตย์',
  expand: 'ขยาย',
  dayMonthYear: 'วัน/เดือน/ปี',
  language: 'th-th',
  save_wait: 'กำลังบันทึก กรุณารอสักครู่...',
  successful: 'ทำรายการสำเร็จ',
  //Limitation
  Limit_buttonEdit: 'แก้ไข',
  Limit_buttonSave: 'นำไปใช้',
  Limit_amountLimit: 'จำกัดจำนวนส่งออก',
  Limit_amountLimit_detail: 'จำกัดจำนวนข้อความที่ผู้ใช้งานสามารถส่งได้ต่อวัน, เดือน และปี ต่อลูกค้า 1 คน เพื่อรักษาความปลอดภัยให้ระบบ และบัญชีผู้ใช้',
  Limit_step: 'ขั้นตอนที่',
  Limit_stepOne_amountDetail: 'กรุณากำหนดจำนวนเพื่อจำกัดการส่งออกข้อความไปยังลูกค้าต่อ 1 คน',
  Limit_messagesPerDay: 'จำกัดข้อความต่อวัน',
  Limit_messagesPerMonth: 'จำกัดข้อความต่อเดือน',
  Limit_messagesPerYear: 'จำกัดข้อความต่อปี',
  Limit_noMoreThan: 'จำกัดไม่เกิน',
  Limit_Unlimited_messagesPerDay: 'ไม่จำกัดการส่งข้อความต่อวัน',
  Limit_Unlimited_messagesPerMonth: 'ไม่จำกัดการส่งข้อความต่อเดือน',
  Limit_Unlimited_messagesPerYear: 'ไม่จำกัดการส่งข้อความต่อปี',
  Limit_Unlimited_exportPerDay: 'ไม่จำกัดการส่งต่อวัน',
  Limit_Unlimited_exportPerMonth: 'ไม่จำกัดการส่งต่อเดือน',
  Limit_Unlimited_exportPerYear: 'ไม่จำกัดการส่งต่อปี',
  Limit_messages: 'ข้อความ',
  Limit_stepTwo_detail: 'ตั้งค่าการจำกัดการส่งข้อความ',
  Limit_disallow_exceedLimit: 'ห้ามการส่งออกย้อนหลัง หากจำนวนข้อความที่ส่งออกเกินกำหนด หรือ อยู่ในช่วงเวลาที่ระบบกำหนดห้ามส่งออก', //ห้ามการส่งออก หากจำนวนข้อความที่ส่งออกเกินที่จำกัด
  Limit_nextRound_exceedLimit: 'ส่งออกรอบถัดไป หากจำนวนข้อความที่ส่งออกเกินที่จำกัด',

  Limit_timeLimit: 'จำกัดเวลาส่งออก',
  Limit_timeLimit_detail: 'จำกัดวัน และเวลาการส่งข้อความ ต่อลูกค้า 1 คน เพื่อรักษาความปลอดภัยให้ระบบ และบัญชีผู้ใช้',
  Limit_stepOne_timeDetail: 'กรุณาเลือกวิธีการจำกัดเวลาการส่งออก',
  Limit_weekly: 'รายสัปดาห์',
  Limit_dateAndTime: 'กำหนดวัน และเวลา',
  Limit_LimitedDays: 'จำกัดวัน',
  Limit_LimitedExportTime: 'จำกัดช่วงเวลาการส่งออก',
  Limit_between: 'ระหว่าง',
  Limit_to: 'ถึง',
  Limit_noTimeLimit: 'ไม่จำกัดเวลา',

  Limit_ResultOfMessage: 'ผลลัพธ์การจำกัดส่งข้อความ',
  Limit_sendingOfMessages_by: 'จำกัดการส่งข้อความโดย',
  Limit_cusReceive: 'ลูกค้าจะได้รับ',
  Limit_notMoreThan: 'ไม่เกิน',
  Limit_day: 'วัน',
  Limit_month: 'เดือน',
  Limit_year: 'ปี',

  Limit_tooltipButtonAdd: 'เพิ่มเวลาจำกัด',
  Limit_tooltipButtonRemove: 'ลบ',
  Limit_view_calendar: 'ผลลัพธ์การจำกัดวัน และเวลาการส่งข้อความ',

  Limit_ConfirmQuantityLimit: 'ยืนยันการจำกัดจำนวน',
  Limit_DateAndTimeOfExport: 'วัน และเวลาการส่งออก',
  Limit_Confirm_yesOrNo: 'ต้องการยืนยัน ใช่หรือไม่ ?',
};
