import { getCurrentInstance, Ref, ref, onMounted, watch, reactive, markRaw } from 'vue';

import { arr_ascii, arr_unicode, arr_extraChars, EMOJI_PATTERN, DOUBLE_BYTES_CHAR } from '../../../../main/constants/msgChars';

import { htmlToString } from '@/utils/editor';

import EmojiPicker from '@/views/components/emoji-picker/EmojiPicker.vue';

import TemplateIcon from '@/assets/icons/modules/communication/main-page/TemplateIcon.vue';
import PersonalizeIcon from '@assets/icons/modules/communication/template-tab/Personalize.vue';
import CouponIcon from '@assets/icons/modules/communication/template-tab/Coupon.vue';
import EmojiIcon from '@assets/icons/modules/communication/template-tab/Emoji.vue';
import ConnectorIcon from '@assets/icons/modules/communication/edm-editor/ConnectorIcon.vue';

interface Props {
  readOnly: boolean;
  modelValue?: string;
  headerMenu?: Campaign.MenuHeader[];
  isSegmentSelected?: boolean;
  provider?: string;
}

export default function useSmsEditor(props: Props) {
  const vm = getCurrentInstance()?.proxy;
  const editor: Ref<HTMLDivElement> = ref(null!);
  const editorConverted: Ref<string> = ref('');
  const isSelectTemplateModalOpen: Ref<boolean> = ref(false);
  const isPreviewTemplateModalOpen: Ref<boolean> = ref(false);
  const selectedTemplate: Ref<Communication.Template.Template> = ref(null!);
  const selectionEditor: Ref<any> = ref(null!);
  const isEditorFocus: Ref<boolean> = ref(false);
  const isSetContent: Ref<boolean> = ref(false);

  const smsInput: Campaign.SmsValue = reactive({
    unicodeFlag: false,
    maxChars: 0,
    maxDisplay: null,
    maxCredit: 0,
    creditUsed: 0,
    extraChars: 0,
    msgCount: 0,
  });

  const menuHeaderList: Ref<Campaign.MenuHeader[]> = ref([
    {
      label: 'Template',
      key: 'template',
      icon: markRaw(TemplateIcon),
      tooltipBackgroundColor: '#007FFF',
    },
    {
      label: 'Personalize',
      key: 'personalization',
      icon: markRaw(PersonalizeIcon),
      tooltipBackgroundColor: '#007FFF',
    },
    {
      label: 'Coupon',
      key: 'coupon',
      icon: markRaw(CouponIcon),
      tooltipBackgroundColor: '#007FFF',
    },
    {
      label: 'Emoji',
      key: 'emoji',
      icon: markRaw(EmojiIcon),
      tooltipBackgroundColor: '#007FFF',
    },
    {
      label: 'Tracking Link',
      key: 'link',
      icon: markRaw(ConnectorIcon),
      tooltipBackgroundColor: '#007FFF',
    },
  ]);

  const onCheckMaxChars = (event: KeyboardEvent) => {
    if (smsInput.msgCount >= smsInput.maxChars) event.preventDefault();
  };

  const isInnerHtmlOnlyContainLineBreak = (str: string) => {
    const temp = htmlToString(str);

    editorConverted.value = temp;
    return editorConverted.value === '' || editorConverted.value.length == 0 || /^(?:\r\n|\r|\n)+$/.test(temp);
  };

  const updateValue = () => {
    if (isInnerHtmlOnlyContainLineBreak(editor.value.innerHTML)) {
      editor.value.innerHTML = '';
    }
    if (smsInput.msgCount > smsInput.maxChars) {
      editor.value.innerText = editor.value.innerText.slice(0, smsInput.maxChars);

      const selection = window.getSelection();
      const range = document.createRange();

      selection?.removeAllRanges();
      range.selectNodeContents(editor.value);
      range.collapse(false);
      selection?.addRange(range);
      editor.value.focus();
    }

    if (props.provider) {
      if (props.provider == 'ARC') {
        checkInputARCSms(editor.value.innerHTML);
      } else if (props.provider == 'ThaiBulk') {
        checkInput1MobySMS(editor.value.innerHTML);
      }
    } else {
      checkInputARCSms(editor.value.innerHTML);
    }

    const editorContainer = vm?.$refs['sms-editor-container'] as HTMLDivElement;
    editorContainer.classList.remove('warning');
    const firstHTMLNotDIV = editor.value.innerHTML.match(/.*?<div>/);
    const contentNotDIV = firstHTMLNotDIV ? firstHTMLNotDIV[0].replace(/<div>/g, '') : '';
    if (contentNotDIV != '') {
      return vm?.$emit('update:modelValue', editor.value.innerHTML.replace(/.*?<div>/, `<div>${contentNotDIV}</div><div>`));
    } else {
      return vm?.$emit('update:modelValue', editor.value.innerHTML);
    }
  };

  const removeStyle = () => {
    const editorEle: any = document.querySelector('.sms-editor');

    // Handle the `paste` event
    editorEle.onpaste = (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      let text: string = e.clipboardData.getData('text/plain');
      text = text.replace(/\r\n/g, '\n');
      if (document.queryCommandSupported('insertText')) {
        document.execCommand('insertText', false, text);
      } else {
        document.execCommand('paste', false, text);
      }
    };
  };

  //ARC SMS
  const checkInputARCSms = (value: string) => {
    const text = htmlToString(value);
    const msg = text.replace(/\n/g, ' ');
    smsInput.unicodeFlag = false;
    smsInput.maxChars = 0;
    smsInput.maxCredit = 0;
    smsInput.msgCount = 0;
    smsInput.creditUsed = 0;
    smsInput.extraChars = 0;

    function countEmojis(str: string) {
      return ((str || '').match(EMOJI_PATTERN) || []).length + str.replaceAll(EMOJI_PATTERN, '').length;
    }

    if (msg.length > 0) {
      if (!smsInput.unicodeFlag) {
        for (let i = 0; i < msg.length; i++) {
          if (arr_unicode.indexOf(msg.charAt(i)) !== -1) {
            smsInput.unicodeFlag = true;
          } else if (arr_ascii.indexOf(msg.charAt(i)) !== -1) {
          } else if (arr_extraChars.indexOf(msg.charAt(i)) !== -1) {
          } else {
            smsInput.unicodeFlag = true;
          }
        }
      }
      // Count extraChars
      if (smsInput.unicodeFlag === false) {
        for (let j = 0; j < msg.length; j++) {
          if (arr_extraChars.indexOf(msg.charAt(j)) !== -1) {
            smsInput.extraChars++;
          }
        }
      }
      smsInput.msgCount = countEmojis(msg);

      //Count Credit
      if (smsInput.unicodeFlag === false) {
        //ASCII
        if (smsInput.msgCount > 612) smsInput.creditUsed = 5;
        else if (smsInput.msgCount > 459 && smsInput.msgCount <= 612) smsInput.creditUsed = 4;
        else if (smsInput.msgCount > 306 && smsInput.msgCount <= 459) smsInput.creditUsed = 3;
        else if (smsInput.msgCount > 160 && smsInput.msgCount <= 306) smsInput.creditUsed = 2;
        else smsInput.creditUsed = 1;

        smsInput.maxChars = 765;
        smsInput.maxCredit = 5;
      } else {
        // Unicode
        if (smsInput.msgCount > 670) smsInput.creditUsed = 11;
        else if (smsInput.msgCount > 603 && smsInput.msgCount <= 670) smsInput.creditUsed = 10;
        else if (smsInput.msgCount > 536 && smsInput.msgCount <= 603) smsInput.creditUsed = 9;
        else if (smsInput.msgCount > 469 && smsInput.msgCount <= 536) smsInput.creditUsed = 8;
        else if (smsInput.msgCount > 402 && smsInput.msgCount <= 469) smsInput.creditUsed = 7;
        else if (smsInput.msgCount > 335 && smsInput.msgCount <= 402) smsInput.creditUsed = 6;
        else if (smsInput.msgCount > 268 && smsInput.msgCount <= 335) smsInput.creditUsed = 5;
        else if (smsInput.msgCount > 201 && smsInput.msgCount <= 268) smsInput.creditUsed = 4;
        else if (smsInput.msgCount > 134 && smsInput.msgCount <= 201) smsInput.creditUsed = 3;
        else if (smsInput.msgCount > 70 && smsInput.msgCount <= 134) smsInput.creditUsed = 2;
        else smsInput.creditUsed = 1;

        smsInput.maxChars = 737;
        smsInput.maxCredit = 11;
      }
      if (smsInput.unicodeFlag === false && smsInput.msgCount > 765) {
        smsInput.msgCount = 765;
      } else if (smsInput.unicodeFlag === true && smsInput.msgCount > 737) {
        smsInput.msgCount = 737;
      }
    } else {
      smsInput.maxChars = 765;
      smsInput.maxCredit = 5;
      smsInput.msgCount = 0;
      smsInput.creditUsed = 0;
    }
    vm?.$emit('on-get-sms-display', smsInput);
  };

  //ThaiBulk(1Moby) SMS
  const checkInput1MobySMS = (value: string) => {
    /**
     * htmlToString()
     * This function return length of HTML message
     * the result must equal this link --> https://wordcount.com/
     */

    const msg = htmlToString(value);

    const maxCharsASCII = 1530;
    const maxCreditASCII = 10;
    const maxCharsUnicode = 670;
    const maxCredUnicode = 10;

    const minCharsASCII = 160;
    const minCharsUnicode = 70;
    const asciiNextCharLength = 153;
    const unicodeNextCharLength = 67;

    smsInput.unicodeFlag = false;
    smsInput.maxChars = 0;
    smsInput.maxCredit = 0;
    smsInput.msgCount = 0;
    smsInput.creditUsed = 0;
    smsInput.extraChars = 0;

    // function count the number of characters
    function getCountMsg(text: string) {
      const msgCount = ref(0);
      let count = 0;
      if (text !== '') {
        for (let index = 0; index < text.length; index++) {
          if (DOUBLE_BYTES_CHAR.includes(text.charCodeAt(index)) && !smsInput.unicodeFlag) {
            count += 2;
          } else {
            count++;
          }
          msgCount.value = count;
        }
      } else {
        msgCount.value = 0;
      }
      return msgCount.value;
    }

    if (msg.length > 0) {
      // Check regex
      const regex = new RegExp(/[^\x00-\x7F]+/g);
      if (!smsInput.unicodeFlag) {
        if (regex.test(msg)) {
          smsInput.unicodeFlag = true;
        } else {
          smsInput.unicodeFlag = false;
        }
      }

      //set count the number of characters
      smsInput.msgCount = getCountMsg(msg);

      if (smsInput.unicodeFlag === false && smsInput.msgCount > maxCharsASCII) {
        smsInput.msgCount = maxCharsASCII;
      } else if (smsInput.unicodeFlag === true && smsInput.msgCount > maxCharsUnicode) {
        smsInput.msgCount = maxCharsUnicode;
      }

      const creditCount = ref(0);
      const DEFAULT_CHAR_LENGTH = smsInput.unicodeFlag ? minCharsUnicode : minCharsASCII;
      let stateCharLength = 0;
      if (smsInput.unicodeFlag) {
        stateCharLength = smsInput.msgCount <= minCharsUnicode ? minCharsUnicode : unicodeNextCharLength;
        smsInput.maxChars = maxCharsUnicode;
      } else {
        stateCharLength = smsInput.msgCount <= minCharsASCII ? minCharsASCII : asciiNextCharLength;
        smsInput.maxChars = maxCharsASCII;
      }
      creditCount.value = Math.ceil(smsInput.msgCount / stateCharLength);
      if (creditCount.value <= 1) {
        smsInput.maxDisplay = DEFAULT_CHAR_LENGTH;
      } else {
        smsInput.maxDisplay = stateCharLength * creditCount.value;
      }
      smsInput.creditUsed = creditCount.value;
      smsInput.maxCredit = maxCredUnicode;
    } else {
      smsInput.maxDisplay = minCharsASCII;
      smsInput.maxChars = maxCharsASCII;
      smsInput.maxCredit = maxCreditASCII;
      smsInput.msgCount = 0;
      smsInput.creditUsed = 0;
    }
    vm?.$emit('on-get-sms-display', smsInput);
  };

  const setContent = () => {
    isSetContent.value = true;
    const selection = window.getSelection();
    const range = document.createRange();

    editor.value.innerHTML = props.modelValue || '';
    removeStyle();
    selection?.removeAllRanges();
    range.selectNodeContents(editor.value);
    range.collapse(false);
    selection?.addRange(range);
    editor.value.focus();

    if (props.provider) {
      if (props.provider == 'ARC') {
        checkInputARCSms(editor.value.innerHTML);
      } else if (props.provider == 'ThaiBulk') {
        checkInput1MobySMS(editor.value.innerHTML);
      }
    } else {
      checkInputARCSms(editor.value.innerHTML);
    }

    if (isInnerHtmlOnlyContainLineBreak(editor.value.innerHTML)) {
      editor.value.innerHTML = '';
    }
  };

  const onSelectEmoji = (emoji: string) => {
    const selection: any = window.getSelection();
    const range = selection.getRangeAt(0);
    range?.deleteContents();
    const insertEmoji = document.createTextNode(emoji);
    range?.insertNode(insertEmoji);
    range?.setStartAfter(insertEmoji);
    selection?.removeAllRanges();
    selection?.addRange(range);
    editor.value.focus();
    updateValue();
  };

  function resolveEmojiPicker(menu: any) {
    switch (menu.key) {
      case 'emoji':
        return EmojiPicker;
      default:
        return menu.icon;
    }
  }

  const resolveEmits = (menu: any): object => {
    let itemEmits = {} as object;
    switch (menu.key) {
      case 'emoji':
        itemEmits = {
          ['select-emoji']: (emoji: string) => onSelectEmoji(emoji),
        };
    }
    return itemEmits;
  };

  const resolveMenuAction = (key: string) => {
    switch (key) {
      case 'template':
        return (isSelectTemplateModalOpen.value = true);
      case 'personalization':
        return isSegmentSelected();
      case 'coupon':
        return vm?.$emit('on-select-coupon');
      case 'emoji':
        return vm?.$emit('select-emoji');
      case 'link':
        return vm?.$emit('on-select-link');
    }
  };

  const onCloseTemplateModal = () => {
    isSelectTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
  };

  const onClickPreviewTemplate = (template: Communication.Template.Template) => {
    selectedTemplate.value = template;
    isPreviewTemplateModalOpen.value = true;
  };

  const onClosePreviewTemplateModal = () => {
    isPreviewTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
  };

  const onSelectTemplate = (template: Communication.Template.Template) => {
    vm?.$emit('use-message-template', template);
    isSelectTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
  };

  const onUseMessageTemplate = () => {
    vm?.$emit('use-message-template', selectedTemplate.value);
    isPreviewTemplateModalOpen.value = false;
    isSelectTemplateModalOpen.value = false;
    selectedTemplate.value = null!;
  };

  function cursorHandle(e: any) {
    if (e.keyCode) {
      setSelection();
      isEditorFocus.value = true;
    }
  }

  function setSelection() {
    selectionEditor.value = saveSelection();
    vm?.$emit('get-cursor-position', selectionEditor.value);
  }

  function saveSelection() {
    selectionEditor.value = window.getSelection();
    return [selectionEditor.value.anchorNode, selectionEditor.value.anchorOffset, selectionEditor.value.focusNode, selectionEditor.value.focusOffset];
  }

  function isSegmentSelected() {
    if (props.isSegmentSelected) vm?.$emit('on-select-personalize');
    else vm?.$emit('on-select-personalize-no-segment');
  }

  function onEditorFocusIn() {
    if (!props.readOnly) {
      isEditorFocus.value = true;
    }
  }

  function onEditorFocusOut() {
    setTimeout(() => {
      isEditorFocus.value = false;
    }, 200);
  }

  onMounted(() => {
    setContent();
    setSelection();
  });

  watch(
    () => props.modelValue,
    () => {
      const selection = window.getSelection();
      const range = document.createRange();
      if (props.modelValue !== editor.value.innerHTML) {
        editor.value.innerHTML = props.modelValue || '';
        removeStyle();
        selection?.removeAllRanges();
        range.selectNodeContents(editor.value);
        range.collapse(false);
        selection?.addRange(range);
        editor.value.focus();
        if (isInnerHtmlOnlyContainLineBreak(editor.value.innerHTML)) {
          editor.value.innerHTML = '';
        }
      }
      if (props.provider) {
        if (props.provider == 'ARC') {
          checkInputARCSms(editor.value.innerHTML);
        } else if (props.provider == 'ThaiBulk') {
          checkInput1MobySMS(editor.value.innerHTML);
        }
      } else {
        checkInputARCSms(editor.value.innerHTML);
      }
    },
  );

  watch(
    () => props.provider,
    () => {
      if (isSetContent.value) {
        if (props.provider) {
          if (props.provider == 'ARC') {
            checkInputARCSms(editor.value.innerHTML);
          } else if (props.provider == 'ThaiBulk') {
            checkInput1MobySMS(editor.value.innerHTML);
          }
        } else {
          checkInputARCSms(editor.value.innerHTML);
        }
      }
    },
    { deep: true, immediate: true },
  );

  watch(
    () => props.readOnly,
    () => {
      if (props.readOnly) {
        isEditorFocus.value = false;
      }
    },
  );

  return {
    editor,
    editorConverted,
    menuHeaderList,
    selectedTemplate,
    isPreviewTemplateModalOpen,
    isSelectTemplateModalOpen,
    isEditorFocus,
    resolveEmojiPicker,
    resolveEmits,
    onCheckMaxChars,
    updateValue,
    onClickPreviewTemplate,
    onClosePreviewTemplateModal,
    onSelectTemplate,
    onCloseTemplateModal,
    onUseMessageTemplate,
    resolveMenuAction,
    setSelection,
    cursorHandle,
    onEditorFocusIn,
    onEditorFocusOut,
  };
}
