import { markRaw, onBeforeMount, ref, Ref, reactive, watch, getCurrentInstance, computed } from 'vue';
import router from '@/router';
import templateModel from '@/models/communication/template';
import apiService from '@/services/api';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import { MESSAGE_TYPE } from '@/views/modules/communication/pages/line-oa-campaign/constants/messageType';
import { CAMPAIGN_CHENNEL } from '@/constants/modules/commu/campaign-chennel';
import { TemplateCategoryEnum } from '@/constants/modules/commu/template-category.enum';
import { TemplateMainTypeEnum } from '@/constants/modules/commu/template-mainType.enum';

import All from '@/assets/icons/modules/communication/template-tab/template-category/All.vue';
import Popular from '@/assets/icons/modules/communication/template-tab/template-category/Popular.vue';
import Promotions from '@/assets/icons/modules/communication/template-tab/template-category/Promotions.vue';
import Seasonal from '@/assets/icons/modules/communication/template-tab/template-category/Seasonal.vue';
import Awareness from '@/assets/icons/modules/communication/template-tab/template-category/Awareness.vue';
import WinBack from '@/assets/icons/modules/communication/template-tab/template-category/WinBack.vue';
import Engagement from '@/assets/icons/modules/communication/template-tab/template-category/Engagement.vue';
import Notification from '@/assets/icons/modules/communication/template-tab/template-category/Notification.vue';
import Other from '@/assets/icons/modules/communication/template-tab/template-category/Other.vue';
import UseIcon from '@assets/icons/modules/communication/template-tab/Use.vue';
import PreviewIcon from '@assets/icons/modules/communication/template-tab/Preview.vue';
import EditIcon from '@assets/icons/modules/communication/template-tab/Edit.vue';
import DuplicateIcon from '@assets/icons/modules/communication/template-tab/Duplicate.vue';
import DeleteIcon from '@assets/icons/modules/communication/template-tab/Delete.vue';
import AllMessageTypeIcon from '@assets/icons/modules/communication/line-oa/AllMessageTypeIcon.vue';
import MessageIcon from '@assets/icons/modules/communication/line-oa/MessageIcon.vue';
import RichMessageIcon from '@assets/icons/modules/communication/line-oa/RichMessageIcon.vue';
import RichVideoIcon from '@assets/icons/modules/communication/line-oa/RichVideoIcon.vue';
import CardMessageIcon from '@assets/icons/modules/communication/line-oa/CardMessageIcon.vue';
import MixMessageIcon from '@assets/icons/modules/communication/line-oa/MixMessageIcon.vue';

// permission
import ability from '@/libs/acl/ability';

interface Props {
  mode: number;
  channelInfo: Communication.Channel.Component.AllChannelTab | null;
}

interface MessageType {
  key: string;
  label: string;
  icon: object;
  value: number;
  disable: boolean;
}

interface MyTemplateAction {
  key: string;
  label: string;
  icon: object;
  tooltip: string;
  tooltipBackgroundColor: string;
  tooltipColor: string;
}

export default function useTemplateTab(props: Props) {
  const vm = getCurrentInstance()?.proxy;

  const { fetchTemplateCardModel, fetchTemplateListModel, duplicateTemplateModel, deleteTemplateModel, getCategoryListModel } = templateModel();
  const { openDefaultErrorModal, openWarningModal, openSuccessModal } = useValidationModal();

  const templateCategoryList: Ref<Communication.TemplateCard.Response.Category[]> = ref([]);
  const templateList: Ref<Communication.Template.Template[]> = ref([]);
  const subTypeList: Ref<number[]> = ref([]);
  const isChangedListStyle: Ref<boolean> = ref(false);
  const keyChange: Ref<number> = ref(0);
  const allCategoryList: Ref<Communication.TemplateCard.Response.CategoryList[]> = ref([]);
  const toggleSearch: Ref<boolean> = ref(false);

  const previewModal: { isOpen: boolean; template: Communication.Template.Template } = reactive({
    isOpen: false,
    template: null!,
  });

  const templateFilterForm: Record<string, any> = reactive({
    mainType: TemplateMainTypeEnum.STANDARD,
    subType: null,
    category: null,
    search: '',
    order: [],
  });

  const isLoading = reactive({ templateCardList: false, templateList: false });

  const templateFilterList: Ref<Record<string, any>> = ref({
    mainType: [
      {
        label: 'Standard',
        value: TemplateMainTypeEnum.STANDARD,
      },
      {
        label: 'My Template',
        value: TemplateMainTypeEnum.MY_TEMPLATE,
      },
    ],
    category: [
      {
        label: 'All',
        icon: markRaw(All),
        value: TemplateCategoryEnum.ALL,
      },
      {
        label: 'Popular',
        icon: markRaw(Popular),
        value: TemplateCategoryEnum.POPULAR,
      },
      {
        label: 'Promotions',
        icon: markRaw(Promotions),
        value: TemplateCategoryEnum.PROMOTIONS,
      },
      {
        label: 'Seasonal',
        icon: markRaw(Seasonal),
        value: TemplateCategoryEnum.SEASONAL,
      },
      {
        label: 'Awareness',
        icon: markRaw(Awareness),
        value: TemplateCategoryEnum.AWARENESS,
      },
      {
        label: 'Win-Back',
        icon: markRaw(WinBack),
        value: TemplateCategoryEnum.WIN_BACK,
      },
      {
        label: 'Engagement',
        icon: markRaw(Engagement),
        value: TemplateCategoryEnum.ENGAGEMENT,
      },
      {
        label: 'Notification',
        icon: markRaw(Notification),
        value: TemplateCategoryEnum.NOTIFICATION,
      },
      {
        label: 'Other',
        icon: markRaw(Other),
        value: TemplateCategoryEnum.OTHER,
      },
      {
        label: 'ข่าวสาร',
        icon: markRaw(Awareness),
        value: TemplateCategoryEnum.NEWS,
      },
      {
        label: 'เทศกาล',
        icon: markRaw(Seasonal),
        value: TemplateCategoryEnum.FESTIVAL,
      },
      {
        label: 'การขายและโปรโมชั่น',
        icon: markRaw(Promotions),
        value: TemplateCategoryEnum.SALES_AND_PROMOTION,
      },
      {
        label: 'อื่นๆ',
        icon: markRaw(Other),
        value: TemplateCategoryEnum.OTHER_2,
      },
    ],
  });

  const standardActionList: Ref<Record<string, any>[]> = ref([]);

  const myTemplateActionList: Ref<MyTemplateAction[]> = ref([]);

  const messageTypeList: Ref<MessageType[]> = ref([
    {
      key: 'all',
      label: 'All',
      icon: markRaw(AllMessageTypeIcon),
      value: MESSAGE_TYPE.ALL,
      disable: false,
    },
    {
      key: 'message',
      label: 'Message',
      icon: markRaw(MessageIcon),
      value: MESSAGE_TYPE.MESSAGE,
      disable: false,
    },
    {
      key: 'rich-message',
      label: 'Rich Message',
      icon: markRaw(RichMessageIcon),
      value: MESSAGE_TYPE.RICH_MESSAGE,
      disable: false,
    },
    {
      key: 'card-message',
      label: 'Card Message',
      icon: markRaw(CardMessageIcon),
      value: MESSAGE_TYPE.CARD_MESSAGE,
      disable: false,
    },
    {
      key: 'rich-video',
      label: 'Rich Video Message',
      icon: markRaw(RichVideoIcon),
      value: MESSAGE_TYPE.VIDEO,
      disable: true,
    },
    {
      key: 'mix',
      label: 'Multi message',
      icon: markRaw(MixMessageIcon),
      value: MESSAGE_TYPE.MIX,
      disable: false,
    },
  ]);

  const pagination: BaseTable.Pagination = reactive({
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
  });

  const isListEmpty = computed(() => {
    if (templateCategoryList.value && templateCategoryList.value.length > 0) {
      const notEmptyCategory = templateCategoryList.value.find((category) => category?.template_list?.length);

      return !!!notEmptyCategory;
    }

    return true;
  });

  const field: BaseTable.TableColumn[] = [
    { key: 'name', label: 'Template Name', sortable: true },
    { key: 'category_name', label: 'Category', sortable: true },
    { key: 'updated_dt', label: 'Last Update', sortable: true },
    { key: 'updated_by', label: 'Update by', sortable: true },
    { key: 'action', label: '' },
  ];

  let searchTimeout: ReturnType<typeof setTimeout> = null!;

  const fetchTemplateCardList = () => {
    getCategoryList();
    isLoading.templateCardList = true;
    fetchTemplateCardModel.payload.Channel = props.channelInfo?.value as Communication.Campaign.ChannelType;
    fetchTemplateCardModel.payload.Status = 1;
    fetchTemplateCardModel.payload.Type = templateFilterForm.mainType ?? null;
    fetchTemplateCardModel.payload.List_SubType = subTypeList.value ?? [];
    fetchTemplateCardModel.payload.CategoryId = templateFilterForm.category?.category_id || null;
    fetchTemplateCardModel.payload.Query = templateFilterForm.search.trim();
    fetchTemplateCardModel.payload.Limit = 999;

    apiService
      .apiRequest(fetchTemplateCardModel)
      .then((response) => {
        const categoryList: Communication.TemplateCard.Response.Category[] = response.data;
        if (!templateCategoryList.value.length || !templateFilterForm.category?.category_id) {
          if (categoryList) {
            const allCategory: Communication.TemplateCard.Response.Category = {
              category_icon_name: null,
              category_icon_url: null,
              category_id: 0,
              category_name: 'All',
              template_list: [],
            };

            if (!templateFilterForm.category) templateFilterForm.category = allCategory;

            return (templateCategoryList.value = [allCategory, ...categoryList]);
          } else {
            const allCategory: Communication.TemplateCard.Response.Category = {
              category_icon_name: null,
              category_icon_url: null,
              category_id: 0,
              category_name: 'All',
              template_list: [],
            };
            const categoryListDropDown: Communication.TemplateCard.Response.Category[] = [];
            allCategoryList.value.forEach((cat) => {
              const category: Communication.TemplateCard.Response.Category = {
                category_icon_name: cat.iconName,
                category_icon_url: cat.iconUrl,
                category_id: cat.categoryId,
                category_name: cat.name,
                template_list: [],
              };
              categoryListDropDown.push(category);
            });

            if (!templateFilterForm.category) templateFilterForm.category = allCategory;

            return (templateCategoryList.value = [allCategory, ...categoryListDropDown]);
          }
        }

        const searchedCategory = templateCategoryList.value.find((category) => category.category_id == templateFilterForm.category.category_id);

        if (searchedCategory) return (searchedCategory.template_list = categoryList && categoryList.length ? categoryList[0].template_list : []);
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => {
        isLoading.templateCardList = false;
      });
  };

  const fetchTemplateList = () => {
    isLoading.templateList = true;
    fetchTemplateListModel.payload.Channel = props.channelInfo?.value as Communication.Campaign.ChannelType;
    fetchTemplateListModel.payload.Status = 1;
    fetchTemplateListModel.payload.Type = templateFilterForm.mainType ?? null;
    fetchTemplateListModel.payload.List_SubType = subTypeList.value ?? [];
    fetchTemplateListModel.payload.CategoryId = templateFilterForm.category?.category_id || null;
    fetchTemplateListModel.payload.Query = templateFilterForm.search.trim();
    fetchTemplateListModel.payload.Ordering = templateFilterForm.order;
    fetchTemplateListModel.payload.Limit = pagination.perPage;
    fetchTemplateListModel.payload.Page = pagination.currentPage;

    apiService
      .apiRequest(fetchTemplateListModel)
      .then((response) => {
        const responseData: Communication.Template.Response.TemplateList = response.data;
        pagination.totalRows = responseData.filtered_records;
        templateList.value = responseData.records;
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally(() => (isLoading.templateList = false));
  };

  const deleteTemplate = (template: Communication.Template.Template) => {
    deleteTemplateModel.payload.TemplateId = template.template_id;

    apiService
      .apiRequest(deleteTemplateModel)
      .then(() => openSuccessModal('ดำเนินการสำเร็จ', '', '', undefined, isChangedListStyle.value ? fetchTemplateList : fetchTemplateCardList))
      .catch((err) => openDefaultErrorModal(err));
  };

  function duplicateTemplate(template: Communication.Template.Template) {
    duplicateTemplateModel.payload.TemplateId = template.template_id;

    apiService
      .apiRequest(duplicateTemplateModel)
      .then(() => openSuccessModal('Duplicate สำเร็จ', '', '', undefined, isChangedListStyle.value ? fetchTemplateList : fetchTemplateCardList))
      .catch((err) => openDefaultErrorModal(err));
  }

  function getCategoryList() {
    allCategoryList.value = [];
    apiService
      .apiRequest(getCategoryListModel)
      .then((response) => {
        const data = response.data;
        data.forEach((data: any) => {
          const category = {
            categoryId: data.categoryid,
            createdBy: data.created_by,
            createdByReq: data.created_by_req,
            createdDt: data.created_dt,
            iconName: data.icon_name,
            iconUrl: data.icon_url,
            isDeleted: data.is_deleted,
            name: data.name,
            seq: data.seq,
            updatedBy: data.updated_by,
            updatedByReq: data.updated_by_req,
            updatedDt: data.updated_dt,
          };
          allCategoryList.value.push(category);
        });
      })
      .catch((err) => openDefaultErrorModal(err))
      .finally();
  }

  const onResolveAction = (action: Record<string, any>, template: Communication.Template.Template) => {
    switch (action.key) {
      case 'use':
        return vm?.$emit('select-template', template);
      case 'preview':
        return onOpenPreviewModal(template);
      case 'edit':
        if (template.channel == CAMPAIGN_CHENNEL.LINE_OA) {
          return router.push({
            path: `/communication/${String(router.currentRoute.value.query.channel)}/template/info/${template.template_id}`,
            query: { messageType: template.subtype },
          });
        } else {
          return router.push({
            path: `/communication/${String(router.currentRoute.value.query.channel)}/template/info/${template.template_id}`,
          });
        }
      case 'duplicate':
        return openWarningModal('Duplicate Template', `ต้องการ Duplicate Template ${template.name} หรือไม่`, () => duplicateTemplate(template));
      case 'delete':
        return openWarningModal('ลบ Template', `ต้องการลบ Template ${template.name} หรือไม่`, () => deleteTemplate(template));
    }
  };

  const resolveIcon = (category: number | null) => {
    switch (category) {
      case TemplateCategoryEnum.ALL:
        return markRaw(All);
      case TemplateCategoryEnum.POPULAR:
        return markRaw(Popular);
      case TemplateCategoryEnum.PROMOTIONS:
        return markRaw(Promotions);
      case TemplateCategoryEnum.SEASONAL:
        return markRaw(Seasonal);
      case TemplateCategoryEnum.AWARENESS:
        return markRaw(Awareness);
      case TemplateCategoryEnum.WIN_BACK:
        return markRaw(WinBack);
      case TemplateCategoryEnum.ENGAGEMENT:
        return markRaw(Engagement);
      case TemplateCategoryEnum.NOTIFICATION:
        return markRaw(Notification);
      case TemplateCategoryEnum.OTHER:
        return markRaw(Other);
      case TemplateCategoryEnum.NEWS:
        return markRaw(Awareness);
      case TemplateCategoryEnum.FESTIVAL:
        return markRaw(Seasonal);
      case TemplateCategoryEnum.SALES_AND_PROMOTION:
        return markRaw(Promotions);
      case TemplateCategoryEnum.OTHER_2:
        return markRaw(Other);
      default:
        return null;
    }
  };

  const onChangeSearch = () => {
    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      if (isChangedListStyle.value) {
        return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
      }

      return fetchTemplateCardList();
    }, 500);
  };
  const onClickSearchToggle = (value: any) => {
    toggleSearch.value = value;
  };

  const onOpenPreviewModal = (template: Communication.Template.Template) => {
    previewModal.isOpen = true;
    previewModal.template = template;
  };

  const onClosePreviewModal = () => {
    previewModal.isOpen = false;
    previewModal.template = null!;
  };

  const onClickViewAll = (category: number) => {
    const queryObj = {
      channel: props.channelInfo?.value,
      category: category,
      mode: props.mode,
      type: templateFilterForm.mainType,
    };
    if (props.channelInfo?.value == CAMPAIGN_CHENNEL.LINE_OA) {
      Object.assign(queryObj, { messageType: templateFilterForm.subType });
    }
    router.push({
      path: '/communication/template/list',
      query: queryObj,
    });
  };

  const onUseTemplate = (template: Communication.Template.Template) => {
    onClosePreviewModal();
    return vm?.$emit('select-template', template);
  };

  const onChangeListStyle = () => {
    pagination.currentPage = 1;
    isChangedListStyle.value = !isChangedListStyle.value;
    templateFilterForm.order = [];

    if (isChangedListStyle.value) {
      return fetchTemplateList();
    }

    return fetchTemplateCardList();
  };

  function onFilterMessageType(messageType: number) {
    templateCategoryList.value = [];
    templateFilterForm.search = '';
    templateFilterForm.order = [];
    templateFilterForm.subType = messageType;
    subTypeList.value = [messageType];

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    if (messageType === MESSAGE_TYPE.ALL) {
      subTypeList.value = [MESSAGE_TYPE.MIX, MESSAGE_TYPE.CARD_MESSAGE, MESSAGE_TYPE.MESSAGE, MESSAGE_TYPE.RICH_MESSAGE, MESSAGE_TYPE.VIDEO];
    }

    return fetchTemplateCardList();
  }

  const onSelectCategory = (selectedCategory: Communication.TemplateCard.Response.Category) => {
    templateCategoryList.value = [];
    templateFilterForm.search = '';
    templateFilterForm.order = [];
    templateFilterForm.category = selectedCategory;

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onSelectFilter = (typeId: number) => {
    templateCategoryList.value = [];
    templateFilterForm.mainType = typeId;

    if (isChangedListStyle.value) {
      keyChange.value++;
      return pagination.currentPage == 1 ? fetchTemplateList() : (pagination.currentPage = 1);
    }

    return fetchTemplateCardList();
  };

  const onSort = (sortedColumn: { key: string; direction: string }) => {
    if (sortedColumn.direction) {
      const sortModel = [
        {
          Seq: 1,
          Key: sortedColumn.key,
          Direction: sortedColumn.direction.toLocaleLowerCase(),
        },
      ];

      templateFilterForm.order = sortModel as Communication.Template.Request.Ordering[];
    } else {
      templateFilterForm.order = [];
    }

    if (pagination.currentPage == 1) return fetchTemplateList();

    return (pagination.currentPage = 1);
  };

  function checkPermissionCommu() {
    if (ability.can('portal-cdp', 'cdp-portal-commu-createtemplate')) {
      standardActionList.value.push({
        key: 'use',
        label: 'Use',
        icon: markRaw(UseIcon),
        tooltip: 'Use',
        tooltipBackgroundColor: '#BBDBFF',
        tooltipColor: '#007FFF',
      });
      myTemplateActionList.value.push({
        key: 'use',
        label: 'Use',
        icon: markRaw(UseIcon),
        tooltip: 'Use',
        tooltipBackgroundColor: '#BBDBFF',
        tooltipColor: '#007FFF',
      });
    }

    standardActionList.value.push({
      key: 'preview',
      label: 'Preview',
      icon: markRaw(PreviewIcon),
      tooltip: 'Preview',
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    });
    myTemplateActionList.value.push({
      key: 'preview',
      label: 'Preview',
      icon: markRaw(PreviewIcon),
      tooltip: 'Preview',
      tooltipBackgroundColor: '#BBDBFF',
      tooltipColor: '#007FFF',
    });

    if (ability.can('portal-cdp', 'cdp-portal-commu-edittemplate')) {
      myTemplateActionList.value.push({
        key: 'edit',
        label: 'Edit Template',
        icon: markRaw(EditIcon),
        tooltip: 'Edit Template',
        tooltipBackgroundColor: '#BBDBFF',
        tooltipColor: '#007FFF',
      });
    }
    if (ability.can('portal-cdp', 'cdp-portal-commu-copytemplate')) {
      myTemplateActionList.value.push({
        key: 'duplicate',
        label: 'Duplicate',
        icon: markRaw(DuplicateIcon),
        tooltip: 'Duplicate',
        tooltipBackgroundColor: '#BBDBFF',
        tooltipColor: '#007FFF',
      });
    }
    if (ability.can('portal-cdp', 'cdp-portal-commu-deletetemplate')) {
      myTemplateActionList.value.push({
        key: 'delete',
        label: 'Delete',
        icon: markRaw(DeleteIcon),
        tooltip: 'Delete',
        tooltipBackgroundColor: '#BBDBFF',
        tooltipColor: '#007FFF',
      });
    }
  }

  onBeforeMount(() => {
    // check permission
    checkPermissionCommu();
    const query = router.currentRoute.value.query;

    if (query.type) {
      templateFilterForm.mainType = Number(query.type) || 1;
    }

    if (props.channelInfo?.value == CAMPAIGN_CHENNEL.LINE_OA) {
      templateFilterForm.subType = MESSAGE_TYPE.ALL;
      subTypeList.value = [MESSAGE_TYPE.MIX, MESSAGE_TYPE.CARD_MESSAGE, MESSAGE_TYPE.MESSAGE, MESSAGE_TYPE.RICH_MESSAGE, MESSAGE_TYPE.VIDEO];
    }

    fetchTemplateCardList();
  });

  watch(
    () => pagination.currentPage,
    () => {
      if (isChangedListStyle.value) {
        fetchTemplateList();
      }
    },
  );

  return {
    isLoading,
    templateCategoryList,
    previewModal,
    field,
    pagination,
    templateFilterForm,
    templateFilterList,
    standardActionList,
    myTemplateActionList,
    isChangedListStyle,
    templateList,
    isListEmpty,
    keyChange,
    messageTypeList,
    onFilterMessageType,
    onResolveAction,
    onClosePreviewModal,
    resolveIcon,
    onChangeSearch,
    onClickViewAll,
    onUseTemplate,
    onChangeListStyle,
    onSelectCategory,
    onSelectFilter,
    onSort,
    onClickSearchToggle,
    toggleSearch,
  };
}
