import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import { canNavigate } from '@/libs/acl/routeProtection';
import authService from '@/services/authentication';

import Preview from '@/views/modules/preview/pages/Preview.vue';
import Landing from '@/views/modules/landing/Landing.vue';
import authenicationRoute from './routes/authentication';
import segmentRoutes from './routes/segment';
import mkaRoutes from './routes/mka';
import dataConnectRoutes from './routes/data-connect';
import communicationRoutes from './routes/communication';
import analyticsRoutes from './routes/analytics';
import reportRoutes from './routes/report';
import settingRoutes from './routes/setting';
import customerInsightRoutes from './routes/customer-insight';
import permissionRoutes from './routes/permission';
import MyAccountRoutes from './routes/My-Account';
import MainNotificationCreditRoutes from './routes/notification-credit';

// fall back page
import NotFoundPage from '@/views/modules/unauthorized/404NotFound.vue';

// custom brands
import bangchakAnalyticsRoute from '@/router/routes/custom-brands/bangchak/bangchak';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: 'analytics-dashboard' },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: { name: 'analytics-dashboard' },
  },
  {
    path: '/landing',
    name: 'landing',
    component: Landing,
  },
  {
    path: '/not-found',
    name: 'not-found-page',
    component: NotFoundPage,
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/preview/:component',
    name: 'preview-component',
    component: Preview,
  },
  ...authenicationRoute,
  ...dataConnectRoutes,
  ...mkaRoutes,
  ...segmentRoutes,
  ...analyticsRoutes,
  ...communicationRoutes,
  ...reportRoutes,
  ...settingRoutes,
  ...permissionRoutes,
  ...customerInsightRoutes,
  // custom brands
  ...bangchakAnalyticsRoute,
  ...customerInsightRoutes,
  ...MyAccountRoutes,
  ...MainNotificationCreditRoutes,

  // markdow preview
  {
    path: '/docs-markdown',
    name: 'docs-markdown',
    component: () => import('@/views/modules/docs-markdown/DocsMarkdown.vue'),
  },
  {
    path: '/docs-markdown-direct',
    name: 'docs-markdown-direct',
    component: () => import('@/views/modules/docs-markdown/test.md'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const baseUrl: string = process.env.BASE_URL || '';
const brand = authService.getLocalBrandData() ? JSON.parse(authService.getLocalBrandData()!)[0] : null;
router.beforeEach((to, _, next) => {
  const isUserLoggedIn = authService.isUserLoggedIn();
  const currentRoute = window.location;

  // bypass skip check login if path = verify
  if (to.name == 'verify-account') {
    return next();
  }

  if (!to.query.brandRef && (sessionStorage.getItem('brandSessionData') || brand)) {
    return next({ path: to.fullPath, query: { ...to.query, brandRef: sessionStorage.getItem('brandSessionData') || brand.brandRef } });
  }

  if (!canNavigate(to)) {
    if (!isUserLoggedIn && !to.meta.redirectIfLoggedIn) return next({ name: 'auth-login' });
  }

  if (to.name == 'auth-login' && isUserLoggedIn) {
    return next({ name: 'analytics-dashboard' }); // if logged in redirect to dashboard
  }

  if (to.name == 'landing' && currentRoute.pathname !== '/landing') {
    const redirect = currentRoute.pathname.replaceAll(baseUrl, '/') + currentRoute.search;
    return next(redirect);
  }

  return next();
});

export default router;
