import { ref, Ref, reactive, onBeforeMount, watch, getCurrentInstance, nextTick, shallowRef } from 'vue';
import useValidationModal from '@/views/components/modal/hooks/useValidationModal';

import segmentModel from '@models/segment/segment';
import apiService from '@/services/api';
import router from '@/router';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';
import { is } from '@vee-validate/rules';

interface Props {
  isOpen: boolean;
  dataSource: Segment.Datasource;
  columns: DynamicTable.Column[];
  DataTypeOperationList?: DynamicTable.DataTypeOperationList[];
  isLoading?: boolean;
  isEdit?: boolean;
  isTemplate?: boolean;
  segment?: Segment.Segment;
}
interface FilterBySegmentCard {
  indexFilter: number;
  ruleFilter: { ruleName: string; ruleExclude: boolean; selectView: number; selectSegment: number };
}

export default function useAdvanceSegmentBuildLogic(props: Props, reset: Ref<boolean>) {
  const vm = getCurrentInstance()?.proxy;
  const { t } = useI18n();
  const { openDefaultErrorModal } = useValidationModal();

  const { applyWithConditionModel } = segmentModel();

  const conditionAdvance: CreateSegmentModal.ApplyWithCondition = reactive({
    ruleSetList: [],
    groupByList: [],
  });
  const isSubmitAdvance: Ref<boolean> = ref(false);
  const filterAdvanceSegmentList: Ref<CreateSegmentModal.FilterBySegment[]> = ref([]);
  const columnAdvanceList: Ref<CreateSegmentModal.RuleColumn[]> = ref([]);
  const aggregateColumnAdvanceList: Ref<CreateSegmentModal.RuleColumn[]> = ref([]);
  const groupbyColumnAdvanceList: Ref<CreateSegmentModal.RuleColumn[]> = ref([]);
  const tableId: Ref<number> = ref(0);
  const key: Ref<number> = ref(0);
  const useGroupByAdvance: Ref<boolean> = ref(false);

  const timezone = DateTime.fromISO((localStorage.getItem('userInfo') as any)?.timezone || new Date().toISOString()).zoneName || 'UTC+7';
  const today = DateTime.fromISO(new Date().toISOString(), { zone: timezone });

  const ruleSetOperatorAdvance: Ref<{
    label: string;
    value: number;
  }> = ref(null!);

  const ruleOperatorAdvance: Ref<{
    label: string;
    value: number;
  }> = ref(null!);

  const ruleAggregateOperatorAdvance: Ref<{
    label: string;
    value: number;
  }> = ref(null!);

  const logicAreaAdvance: Ref<HTMLElement> = ref(null!);
  const logicAggAreaAdvance: Ref<HTMLElement> = ref(null!);
  const setScrollContent: Ref<any> = ref(null!);
  const paramurl: Ref<string> = ref(String(router.currentRoute.value.query.segmentId));

  const setOperatorAdvanceList = [
    { label: t('segment.components.create_segment_modal.operator.and'), value: 1 },
    { label: t('segment.components.create_segment_modal.operator.or'), value: 2 },
  ];
  const setOperatorRuleAdvanceList = [
    { label: t('segment.components.create_segment_modal.operator.and'), value: 1 },
    { label: t('segment.components.create_segment_modal.operator.or'), value: 2 },
  ];
  const miniModalAdvance: Ref<boolean> = ref(false);
  const modalTypeAdvance: Ref<number> = ref(null!);
  const modalRuleSetNameAdvance: Ref<string> = ref(null!);
  const modalRuleSetIndexAdvance: Ref<number> = ref(null!);

  const indexRuleSetAction: Ref<number> = ref(null!);

  //====================================== Advance Value =====================================================//
  // const key: Ref<number> = ref(1);
  const logicText: Ref<string> = ref('');
  const listGroup: Ref<CreateSegmentModal.GroupBucket[]> = ref([]);
  const prepareGroup: Ref<CreateSegmentModal.GroupBucket[]> = ref([]);
  const preparelistGroup: Ref<CreateSegmentModal.GroupBucket[]> = ref([]);
  const prepareListMap: Ref<CreateSegmentModal.GroupBucket[]> = ref([]);
  const listText: Ref<Array<string>> = ref([]);
  const indexRoot: Ref<number> = ref(0);
  const findCountRoot: Ref<number> = ref(0);
  const ArrayLogicText: Ref<Array<string>> = ref([]);
  const ArraytOperatorIdContent: Ref<CreateSegmentModal.AdvanecOperator[]> = ref([]);
  const checkListText: Ref<string> = ref('');
  const resultLogicText: Ref<string> = ref('');
  const resultLogicTextTH: Ref<string> = ref('');
  const listIndexGroup: Ref<number[]> = ref([]);
  const hiddenGroup: Ref<boolean> = ref(false);

  const stringSetCaseI: Ref<string> = ref('(((12)3)4)5'); //( ( ( 1 and 2 ) and 3 ) and 4 ) and 5
  const stringSetCaseII: Ref<string> = ref('((1(23))4)5'); // ( ( 1 and ( 2 and 3 ) ) and 4 ) and 5
  const stringSetCaseIII: Ref<string> = ref('(1((23)4))5'); // ( 1 and ( ( 2 and 3 ) and 4 ) ) and 5
  const stringSetCaseIV: Ref<string> = ref('1(2((34)5))'); // 1 and ( 2 and ( ( 3 and 4 ) and 5 ) )
  const stringSetCaseV: Ref<string> = ref('(1(2(34)))5'); // ( 1 and ( 2 and ( 3 and 4 ) ) ) and 5

  const stringSetCaseVI: Ref<string> = ref('(((12)3)4)'); //( ( ( 1 and 2 ) and 3 ) and 4 )
  const stringSetCaseVII: Ref<string> = ref('((1(23))4)'); // ( ( 1 and ( 2 and 3 ) ) and 4 )
  const stringSetCaseVIII: Ref<string> = ref('(1((23)4))'); // ( 1 and ( ( 2 and 3 ) and 4 ) )
  const stringSetCaseIX: Ref<string> = ref('(1(2(34)))'); // ( 1 and ( 2 and ( 3 and 4 ) ) )

  const backwardIndex: Ref<number> = ref(0);

  const notiGroupRuleSet: Ref<boolean> = ref(false);
  const notiType: Ref<number> = ref(0);

  const setCaselogicI: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 1,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 1,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 2,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 2,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 3,
              type: 2,
              label: 'filter',
              ruleSetIndex: 3,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
          ],
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 3,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 3,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 4,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
      ],
    },
    {
      seq: 2,
      type: 3,
      label: 'operator',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: 4,
      logicRuleSetOperatorId: 1,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
    {
      seq: 3,
      type: 1,
      label: 'ruleSet',
      ruleSetIndex: 5,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
  ]);

  const setCaselogicII: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 1,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 1,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 2,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 2,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 2,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 1,
                  type: 2,
                  label: 'filter',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
          ],
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 3,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 4,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null,
        },
      ],
    },
    {
      seq: 2,
      type: 3,
      label: 'operator',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: 4,
      logicRuleSetOperatorId: 1,
      isCompleteBucket: true,
      isMapping: false,
      child: null,
    },
    {
      seq: 3,
      type: 1,
      label: 'ruleSet',
      ruleSetIndex: 5,
      OperatorOfRuleSetIndex: null,
      logicRuleSetOperatorId: null,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
  ]);

  const setCaselogicIII: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 1,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 1,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 3,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 2,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 2,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 3,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 3,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 4,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
          ],
        },
      ],
    },
    {
      seq: 2,
      type: 3,
      label: 'operator',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: 4,
      logicRuleSetOperatorId: 1,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
    {
      seq: 3,
      type: 1,
      label: 'ruleSet',
      ruleSetIndex: 5,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
  ]);

  const setCaselogicIV: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 1,
      label: 'ruleSet',
      ruleSetIndex: 1,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
    {
      seq: 2,
      type: 3,
      label: 'operator',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: 1,
      logicRuleSetOperatorId: 1,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 2,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 2,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 1,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 3,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 4,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 4,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 3,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 5,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
          ],
        },
      ],
    },
  ]);

  const setCaselogicV: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 1,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 1,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 3,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 2,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 2,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 3,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 4,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      seq: 2,
      type: 3,
      label: 'operator',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: 4,
      logicRuleSetOperatorId: 1,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
    {
      seq: 3,
      type: 1,
      label: 'ruleSet',
      ruleSetIndex: 5,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: null!,
    },
  ]);

  const setCaselogicVI: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 1,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 1,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 2,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 2,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 3,
              type: 2,
              label: 'filter',
              ruleSetIndex: 3,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
          ],
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 3,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 3,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 4,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
      ],
    },
  ]);

  const setCaselogicVII: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 1,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 1,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 2,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 2,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 2,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 1,
                  type: 2,
                  label: 'filter',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
          ],
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 3,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 4,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null,
        },
      ],
    },
  ]);

  const setCaselogicVIII: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 1,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 1,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 3,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 1,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 2,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 2,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 3,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 3,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 4,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
          ],
        },
      ],
    },
  ]);

  const setCaselogicIX: Ref<CreateSegmentModal.GroupBucket[]> = ref([
    {
      seq: 1,
      type: 4,
      label: 'bucket',
      ruleSetIndex: null!,
      OperatorOfRuleSetIndex: null!,
      logicRuleSetOperatorId: null!,
      isCompleteBucket: true,
      isMapping: false,
      child: [
        {
          seq: 1,
          type: 1,
          label: 'ruleSet',
          ruleSetIndex: 1,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 2,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: 1,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: true,
          child: null!,
        },
        {
          seq: 3,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: true,
          child: [
            {
              seq: 1,
              type: 1,
              label: 'ruleSet',
              ruleSetIndex: 2,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 2,
              type: 3,
              label: 'operator',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: 2,
              logicRuleSetOperatorId: 1,
              isCompleteBucket: true,
              isMapping: true,
              child: null!,
            },
            {
              seq: 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: true,
              isMapping: true,
              child: [
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 3,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 2,
                  type: 3,
                  label: 'operator',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: 3,
                  logicRuleSetOperatorId: 1,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
                {
                  seq: 3,
                  type: 1,
                  label: 'ruleSet',
                  ruleSetIndex: 4,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: true,
                  child: null!,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  const isRuleSet = (seq: number) => {
    for (let i = 0; i < conditionAdvance.ruleSetList.length; i++) {
      if (i == seq - 1) {
        if (!conditionAdvance.ruleSetList[i].useFilter) {
          return true;
        }
        return false;
      }
    }
  };

  const initModel = () => {
    isSubmitAdvance.value = true;
    listText.value = logicText.value.split(' ');
    checkListText.value = '';
    let keyIndex = 0;
    for (let i = 0; i < listText.value.length; i++) {
      if (listText.value[i].toLowerCase() == 'and' || listText.value[i].toLowerCase() == 'or') {
        keyIndex++;
        const object: CreateSegmentModal.AdvanecOperator = {
          ruleSetIndex: keyIndex,
          logicRuleSetOperatorId: listText.value[i].toLowerCase() == 'and' ? 1 : 2,
          status: false,
        };
        ArraytOperatorIdContent.value.push(object);
      } else {
        checkListText.value += listText.value[i];
      }
    }
    if (listText.value.length == 15) {
      if (checkListText.value == stringSetCaseI.value) {
        // listGroup.value = setCaselogicI.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 1,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 1,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 2,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 2,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 3,
                    type: 2,
                    label: 'filter',
                    ruleSetIndex: 3,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                ],
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 3,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 3,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 4,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
            ],
          },
          {
            seq: 2,
            type: 3,
            label: 'operator',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: 4,
            logicRuleSetOperatorId: 1,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
          {
            seq: 3,
            type: 1,
            label: 'ruleSet',
            ruleSetIndex: 5,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseII.value) {
        //listGroup.value = setCaselogicII.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 1,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 1,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 2,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 2,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 2,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 1,
                        type: 2,
                        label: 'filter',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                ],
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 3,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 4,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null,
              },
            ],
          },
          {
            seq: 2,
            type: 3,
            label: 'operator',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: 4,
            logicRuleSetOperatorId: 1,
            isCompleteBucket: true,
            isMapping: false,
            child: null,
          },
          {
            seq: 3,
            type: 1,
            label: 'ruleSet',
            ruleSetIndex: 5,
            OperatorOfRuleSetIndex: null,
            logicRuleSetOperatorId: null,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseIII.value) {
        //listGroup.value = setCaselogicIII.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 1,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 1,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 3,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 2,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 2,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 3,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 3,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 4,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                ],
              },
            ],
          },
          {
            seq: 2,
            type: 3,
            label: 'operator',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: 4,
            logicRuleSetOperatorId: 1,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
          {
            seq: 3,
            type: 1,
            label: 'ruleSet',
            ruleSetIndex: 5,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseIV.value) {
        //listGroup.value = setCaselogicIV.value;
        listGroup.value = [
          {
            seq: 1,
            type: 1,
            label: 'ruleSet',
            ruleSetIndex: 1,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
          {
            seq: 2,
            type: 3,
            label: 'operator',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: 1,
            logicRuleSetOperatorId: 1,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 2,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 2,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 1,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 3,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 4,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 4,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 3,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 5,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                ],
              },
            ],
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseV.value) {
        //listGroup.value = setCaselogicV.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 1,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 1,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 3,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 2,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 2,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 3,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 4,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            seq: 2,
            type: 3,
            label: 'operator',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: 4,
            logicRuleSetOperatorId: 1,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
          {
            seq: 3,
            type: 1,
            label: 'ruleSet',
            ruleSetIndex: 5,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: null!,
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else {
        initRuleSetModel();
        genLogicString(listGroup.value);
        genLogicRuleSet();
      }
    } else if (listText.value.length == 13) {
      if (checkListText.value == stringSetCaseVI.value) {
        // listGroup.value = setCaselogicVI.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 1,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 1,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 2,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 2,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 3,
                    type: 2,
                    label: 'filter',
                    ruleSetIndex: 3,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                ],
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 3,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 3,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 4,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
            ],
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseVII.value) {
        // listGroup.value = setCaselogicVII.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 1,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 1,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 2,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 2,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 2,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 1,
                        type: 2,
                        label: 'filter',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                ],
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 3,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 4,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null,
              },
            ],
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseVIII.value) {
        // listGroup.value = setCaselogicVIII.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 1,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 1,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 3,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 1,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 2,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 2,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 3,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 3,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 4,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                ],
              },
            ],
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else if (checkListText.value == stringSetCaseIX.value) {
        //listGroup.value = setCaselogicIX.value;
        listGroup.value = [
          {
            seq: 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [
              {
                seq: 1,
                type: 1,
                label: 'ruleSet',
                ruleSetIndex: 1,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 2,
                type: 3,
                label: 'operator',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: 1,
                logicRuleSetOperatorId: 1,
                isCompleteBucket: true,
                isMapping: true,
                child: null!,
              },
              {
                seq: 3,
                type: 4,
                label: 'bucket',
                ruleSetIndex: null!,
                OperatorOfRuleSetIndex: null!,
                logicRuleSetOperatorId: null!,
                isCompleteBucket: true,
                isMapping: true,
                child: [
                  {
                    seq: 1,
                    type: 1,
                    label: 'ruleSet',
                    ruleSetIndex: 2,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 2,
                    type: 3,
                    label: 'operator',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: 2,
                    logicRuleSetOperatorId: 1,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: null!,
                  },
                  {
                    seq: 1,
                    type: 4,
                    label: 'bucket',
                    ruleSetIndex: null!,
                    OperatorOfRuleSetIndex: null!,
                    logicRuleSetOperatorId: null!,
                    isCompleteBucket: true,
                    isMapping: true,
                    child: [
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 3,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 2,
                        type: 3,
                        label: 'operator',
                        ruleSetIndex: null!,
                        OperatorOfRuleSetIndex: 3,
                        logicRuleSetOperatorId: 1,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                      {
                        seq: 3,
                        type: 1,
                        label: 'ruleSet',
                        ruleSetIndex: 4,
                        OperatorOfRuleSetIndex: null!,
                        logicRuleSetOperatorId: null!,
                        isCompleteBucket: true,
                        isMapping: true,
                        child: null!,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ];
        updateOperatorIdFixModel(listGroup.value);
        genLogicString(listGroup.value);
        genLogicRuleSet();
      } else {
        initRuleSetModel();
        genLogicString(listGroup.value);
        genLogicRuleSet();
      }
    } else {
      initRuleSetModel();
      genLogicString(listGroup.value);
      genLogicRuleSet();
    }
    if (listGroup.value.length == 3 && conditionAdvance.ruleSetList.length == 5) {
      hiddenGroup.value = true;
    } else {
      hiddenGroup.value = false;
    }
    isSubmitAdvance.value = false;
  };

  const initRuleSetModel = () => {
    const tempArray = listText.value;
    const currentIndex: Ref<number> = ref(0);
    const haveSlot: Ref<boolean> = ref(false);
    const status: Ref<boolean> = ref(false);
    const stackBucket: Ref<Array<string>> = ref([]);
    const tempCountStack: Ref<number> = ref(0);
    for (let i = 0; i < listText.value.length; i++) {
      if (tempArray[i] == '(') {
        stackBucket.value.push('(');
        if (listGroup.value.length > 0) {
          if (prepareListMap.value.length > 0 && listGroup.value[currentIndex.value].isCompleteBucket == false) {
            const setListGroupSuccess = setGroupInRoot(
              listGroup.value[currentIndex.value],
              !(tempCountStack.value < stackBucket.value.length),
              currentIndex.value,
            );
            if (setListGroupSuccess == null) {
              prepareListMap.value.forEach((l) => {
                listGroup.value.push(l);
              });
            }
            prepareListMap.value = [];

            if (i + 1 == listText.value.length) {
              break;
            }
          } else if (prepareListMap.value.length > 0 && listGroup.value[currentIndex.value].isCompleteBucket == true) {
            prepareListMap.value.forEach((l) => {
              listGroup.value.push(l);
            });
            prepareListMap.value = [];
          }
        }

        if (listGroup.value.length == 0) {
          const groupBucketFunction: CreateSegmentModal.GroupBucket = {
            seq: listGroup.value.length + 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: false,
            isMapping: false,
            child: [],
          };

          listGroup.value.push(groupBucketFunction);
        } else {
          for (let i = 0; i < listGroup.value.length; i++) {
            if (listGroup.value[i].isCompleteBucket == false) {
              currentIndex.value = i;

              status.value = findRoot(listGroup.value[i]);
              if (status.value) {
                haveSlot.value = true;
                break;
              } else {
                break;
              }
            }
          }
          if (!haveSlot.value) {
            const groupBucketFunction: CreateSegmentModal.GroupBucket = {
              seq: listGroup.value.length + 1,
              type: 4,
              label: 'bucket',
              ruleSetIndex: null!,
              OperatorOfRuleSetIndex: null!,
              logicRuleSetOperatorId: null!,
              isCompleteBucket: false,
              isMapping: false,
              child: [],
            };
            listGroup.value.push(groupBucketFunction);
            currentIndex.value = listGroup.value.length - 1;
          }
          haveSlot.value = false;
        }

        tempCountStack.value = stackBucket.value.length;
      } else if (tempArray[i] != '(' && tempArray[i] != ')' && tempArray[i].toLowerCase() != 'and' && tempArray[i].toLowerCase() != 'or' && i == 0) {
        backwardIndex.value = parseInt(tempArray[i], 10);
        const temp: CreateSegmentModal.GroupBucket = {
          seq: prepareListMap.value.length + 1,
          type: isRuleSet(parseInt(tempArray[i], 10)) ? 1 : 2,
          label: isRuleSet(parseInt(tempArray[i], 10)) ? 'ruleSet' : 'filter',
          ruleSetIndex: parseInt(tempArray[i], 10),
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        listGroup.value.push(temp);
      } else if (
        tempArray[i] != '(' &&
        tempArray[i] != ')' &&
        tempArray[i].toLowerCase() != 'and' &&
        tempArray[i].toLowerCase() != 'or' &&
        i + 1 != listText.value.length
      ) {
        backwardIndex.value = parseInt(tempArray[i], 10);
        const temp: CreateSegmentModal.GroupBucket = {
          seq: prepareListMap.value.length + 1,
          type: isRuleSet(parseInt(tempArray[i], 10)) ? 1 : 2,
          label: isRuleSet(parseInt(tempArray[i], 10)) ? 'ruleSet' : 'filter',
          ruleSetIndex: parseInt(tempArray[i], 10),
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        prepareListMap.value.push(temp);
      } else if (
        tempArray[i] != '(' &&
        tempArray[i] != ')' &&
        tempArray[i].toLowerCase() != 'and' &&
        tempArray[i].toLowerCase() != 'or' &&
        i + 1 == listText.value.length
      ) {
        backwardIndex.value = parseInt(tempArray[i], 10);
        const temp: CreateSegmentModal.GroupBucket = {
          seq: prepareListMap.value.length + 1,
          type: isRuleSet(parseInt(tempArray[i], 10)) ? 1 : 2,
          label: isRuleSet(parseInt(tempArray[i], 10)) ? 'ruleSet' : 'filter',
          ruleSetIndex: parseInt(tempArray[i], 10),
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        prepareListMap.value.push(temp);
        prepareListMap.value.forEach((item) => {
          listGroup.value.push(item);
        });
        prepareListMap.value = [];
      } else if (tempArray[i] != '(' && tempArray[i] != ')' && (tempArray[i].toLowerCase() == 'and' || tempArray[i].toLowerCase() == 'or')) {
        const temp: CreateSegmentModal.GroupBucket = {
          seq: prepareListMap.value.length + 1,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: backwardIndex.value,
          logicRuleSetOperatorId: tempArray[i].toLowerCase() == 'and' ? 1 : 2,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        prepareListMap.value.push(temp);
      } else if (tempArray[i] == ')') {
        stackBucket.value.pop();
        setGroupInRoot(listGroup.value[currentIndex.value], tempCountStack.value > stackBucket.value.length, currentIndex.value);

        if (stackBucket.value.length == 0) {
          listGroup.value[currentIndex.value].isCompleteBucket = true;
        }
        prepareListMap.value = [];

        if (i + 1 == listText.value.length) {
          break;
        }
        tempCountStack.value = stackBucket.value.length;
      }
      currentIndex.value = listGroup.value.length - 1;
    }
  };

  const genLogicRuleSet = () => {
    resultLogicText.value = '';
    resultLogicTextTH.value = '';
    for (let i = 0; i < ArrayLogicText.value.length; i++) {
      if (i != ArrayLogicText.value.length - 1) {
        resultLogicText.value += ArrayLogicText.value[i] + ' ';
        resultLogicTextTH.value +=
          ArrayLogicText.value[i].toLowerCase() == 'and'
            ? 'และ' + ' '
            : ArrayLogicText.value[i].toLowerCase() == 'or'
            ? 'หรือ' + ' '
            : ArrayLogicText.value[i] + ' ';
      } else {
        resultLogicText.value += ArrayLogicText.value[i];
        resultLogicTextTH.value += ArrayLogicText.value[i];
      }
    }
  };
  const findRoot = (object: CreateSegmentModal.GroupBucket) => {
    if (object.child?.length == 0) {
      const groupBucketFunction: CreateSegmentModal.GroupBucket = {
        seq: 1,
        type: 4,
        label: 'bucket',
        ruleSetIndex: null!,
        OperatorOfRuleSetIndex: null!,
        logicRuleSetOperatorId: null!,
        isCompleteBucket: false,
        isMapping: true,
        child: [],
      };
      object.child.push(groupBucketFunction);
      return true;
    } else if (object.child == null) {
      return false;
    } else if (object.child.length != 0 && object.child != null) {
      const countNotCompleteBucket = object.child.filter((c) => c.isCompleteBucket == false);
      if (countNotCompleteBucket.length == 0) {
        const groupBucketFunction: CreateSegmentModal.GroupBucket = {
          seq: object.seq + 1,
          type: 4,
          label: 'bucket',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: false,
          isMapping: true,
          child: [],
        };
        object.child.push(groupBucketFunction);
        return true;
      } else {
        object.child.forEach((c) => {
          if (c.isCompleteBucket == false) {
            findRoot(c);
          }
        });
      }
    }
    return false;
  };

  const setGroupInRoot = (object: CreateSegmentModal.GroupBucket, stateComplete: boolean, currentIndex: number) => {
    if (object.child == null) {
      return null;
    } else if (object.child.length > 0) {
      let haveFalse = false;
      object.child.forEach((c, index) => {
        if (c.isCompleteBucket == false) {
          haveFalse = true;
          indexRoot.value++;
          findCountRoot.value = index;
          return setGroupInRoot(c, stateComplete, currentIndex);
        }
      });
      if (!haveFalse && prepareListMap.value.length > 0 && !stateComplete) {
        prepareListMap.value = prepareListMap.value.map((rule) => {
          return {
            seq: rule.seq,
            type: rule.type,
            label: rule.label,
            ruleSetIndex: rule.ruleSetIndex,
            OperatorOfRuleSetIndex: rule.OperatorOfRuleSetIndex,
            logicRuleSetOperatorId: rule.logicRuleSetOperatorId,
            isCompleteBucket: rule.isCompleteBucket,
            isMapping: true,
            child: rule.child,
          };
        });
        prepareListMap.value.forEach((RuleSet) => {
          object.child?.push(RuleSet);
        });
        return true;
      }
      if (!haveFalse && stateComplete) {
        prepareListMap.value = prepareListMap.value.map((rule) => {
          return {
            seq: rule.seq,
            type: rule.type,
            label: rule.label,
            ruleSetIndex: rule.ruleSetIndex,
            OperatorOfRuleSetIndex: rule.OperatorOfRuleSetIndex,
            logicRuleSetOperatorId: rule.logicRuleSetOperatorId,
            isCompleteBucket: rule.isCompleteBucket,
            isMapping: true,
            child: rule.child,
          };
        });
        prepareListMap.value.forEach((RuleSet) => {
          object.child?.push(RuleSet);
        });
        return true;
      }
    } else if (object.child.length == 0) {
      if (prepareListMap.value.length > 0) {
        prepareListMap.value = prepareListMap.value.map((rule) => {
          return {
            seq: rule.seq,
            type: rule.type,
            label: rule.label,
            ruleSetIndex: rule.ruleSetIndex,
            OperatorOfRuleSetIndex: rule.OperatorOfRuleSetIndex,
            logicRuleSetOperatorId: rule.logicRuleSetOperatorId,
            isCompleteBucket: rule.isCompleteBucket,
            isMapping: true,
            child: rule.child,
          };
        });
        prepareListMap.value.forEach((RuleSet) => {
          object.child?.push(RuleSet);
        });
      }
      object.isCompleteBucket = stateComplete;

      return true;
    } else if (object.isCompleteBucket == false && stateComplete == false) {
      prepareListMap.value.forEach((RuleSet) => {
        object.child?.push(RuleSet);
      });
      const groupBucketFunction: CreateSegmentModal.GroupBucket = {
        seq: object.child.length + 1,
        type: 4,
        label: 'bucket',
        ruleSetIndex: null!,
        OperatorOfRuleSetIndex: null!,
        logicRuleSetOperatorId: null!,
        isCompleteBucket: false,
        isMapping: true,
        child: [],
      };
      object.child.push(groupBucketFunction);
      return false;
    }
    return null;
  };

  const genLogicString = (listGroup: CreateSegmentModal.GroupBucket[]) => {
    ArrayLogicText.value = [];
    for (let i = 0; i < listGroup.length; i++) {
      if (listGroup[i].type == 4) {
        ArrayLogicText.value.push('(');

        const status = getChild(listGroup[i].child);

        if (status) {
          ArrayLogicText.value.push(')');
        } else {
          break;
        }
      } else {
        if (listGroup[i].type == 3) {
          const OperatorOfindex = listGroup[i].OperatorOfRuleSetIndex;
          if (OperatorOfindex != null) {
            const nameOperator = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId == 1 ? 'And' : 'Or';
            ArrayLogicText.value.push(nameOperator);
          }
        } else {
          ArrayLogicText.value.push(`${listGroup[i].ruleSetIndex}`);
        }
      }
    }
  };

  const getChild = (objectChild: CreateSegmentModal.GroupBucket[] | null) => {
    if (objectChild?.length != 0 && objectChild?.length != null) {
      for (let i = 0; i < objectChild.length; i++) {
        if (objectChild[i].type == 4) {
          ArrayLogicText.value.push('(');

          const childLoop1 = objectChild[i]?.child?.length || 0;

          const objectChildLoop1 = objectChild[i]?.child || null;

          if (objectChildLoop1 != null) {
            for (let j = 0; j < childLoop1; j++) {
              if (objectChildLoop1[j].type == 4) {
                ArrayLogicText.value.push('(');
                const childLoop2 = objectChildLoop1[j]?.child?.length || 0;

                const objectChildLoop2 = objectChildLoop1[j]?.child || null;

                if (objectChildLoop2 != null) {
                  for (let k = 0; k < childLoop2; k++) {
                    if (objectChildLoop2[k].type == 4) {
                      ArrayLogicText.value.push('(');
                    } else {
                      if (objectChildLoop2[k].type == 3) {
                        const OperatorOfindex = objectChildLoop2[k].OperatorOfRuleSetIndex;
                        if (OperatorOfindex != null) {
                          const nameOperator = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId == 1 ? 'And' : 'Or';
                          ArrayLogicText.value.push(nameOperator);
                        }
                      } else {
                        ArrayLogicText.value.push(`${objectChildLoop2[k].ruleSetIndex}`);
                      }
                    }
                  }
                  ArrayLogicText.value.push(')');
                }
              } else {
                if (objectChildLoop1[j].type == 3) {
                  const OperatorOfindex = objectChildLoop1[j].OperatorOfRuleSetIndex;
                  if (OperatorOfindex != null) {
                    const nameOperator = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId == 1 ? 'And' : 'Or';
                    ArrayLogicText.value.push(nameOperator);
                  }
                } else {
                  ArrayLogicText.value.push(`${objectChildLoop1[j].ruleSetIndex}`);
                }
              }
            }
            ArrayLogicText.value.push(')');
          }
        } else if (objectChild[i].type != 4) {
          if (objectChild[i].type == 3) {
            const OperatorOfindex = objectChild[i].OperatorOfRuleSetIndex;
            if (OperatorOfindex != null) {
              const nameOperator = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId == 1 ? 'And' : 'Or';
              ArrayLogicText.value.push(nameOperator);
            }
          } else {
            ArrayLogicText.value.push(`${objectChild[i].ruleSetIndex}`);
          }
        }
      }

      return true;
    } else {
      return false;
    }
  };

  const updateOperatorIdFixModel = (listGroup: CreateSegmentModal.GroupBucket[]) => {
    for (let i = 0; i < listGroup.length; i++) {
      if (listGroup[i].type == 4) {
        const status = updateChildOperatorIdFixModel(listGroup[i].child);
        if (status) {
        } else {
          break;
        }
      } else if (listGroup[i].type == 1 || listGroup[i].type == 2) {
        listGroup[i].type = isRuleSet(listGroup[i].ruleSetIndex) ? 1 : 2;
        listGroup[i].label = isRuleSet(listGroup[i].ruleSetIndex) ? 'ruleSet' : 'filter';
      } else if (listGroup[i].type == 3) {
        const OperatorOfindex = listGroup[i].OperatorOfRuleSetIndex;
        if (OperatorOfindex != null) {
          const OperatorId = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId;
          listGroup[i].logicRuleSetOperatorId = OperatorId == null ? null : OperatorId;
        }
      }
    }
  };

  const updateChildOperatorIdFixModel = (objectChild: CreateSegmentModal.GroupBucket[] | null) => {
    if (objectChild?.length != 0 && objectChild?.length != null) {
      for (let i = 0; i < objectChild.length; i++) {
        if (objectChild[i].type == 4) {
          const childLoop1 = objectChild[i]?.child?.length || 0;
          const objectChildLoop1 = objectChild[i]?.child || null;
          if (objectChildLoop1 != null) {
            for (let j = 0; j < childLoop1; j++) {
              if (objectChildLoop1[j].type == 4) {
                const childLoop2 = objectChildLoop1[j]?.child?.length || 0;
                const objectChildLoop2 = objectChildLoop1[j]?.child || null;
                if (objectChildLoop2 != null) {
                  for (let k = 0; k < childLoop2; k++) {
                    if (objectChildLoop2[k].type == 4) {
                    } else if (objectChildLoop2[k].type == 1 || objectChildLoop2[k].type == 2) {
                      objectChildLoop2[k].type = isRuleSet(objectChildLoop2[k].ruleSetIndex) ? 1 : 2;
                      objectChildLoop2[k].label = isRuleSet(objectChildLoop2[k].ruleSetIndex) ? 'ruleSet' : 'filter';
                    } else if (objectChildLoop2[k].type == 3) {
                      const OperatorOfindex = objectChildLoop2[k].OperatorOfRuleSetIndex;
                      if (OperatorOfindex != null) {
                        const OperatorId = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId;
                        objectChildLoop2[k].logicRuleSetOperatorId = OperatorId == null ? null : OperatorId;
                      }
                    }
                  }
                }
              } else if (objectChildLoop1[j].type == 1 || objectChildLoop1[j].type == 2) {
                objectChildLoop1[j].type = isRuleSet(objectChildLoop1[j].ruleSetIndex) ? 1 : 2;
                objectChildLoop1[j].label = isRuleSet(objectChildLoop1[j].ruleSetIndex) ? 'ruleSet' : 'filter';
              } else if (objectChildLoop1[j].type == 3) {
                const OperatorOfindex = objectChildLoop1[j].OperatorOfRuleSetIndex;
                if (OperatorOfindex != null) {
                  const OperatorId = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId;
                  objectChildLoop1[j].logicRuleSetOperatorId = OperatorId == null ? null : OperatorId;
                }
              }
            }
          }
        } else if (objectChild[i].type == 1 || objectChild[i].type == 2) {
          objectChild[i].type = isRuleSet(objectChild[i].ruleSetIndex) ? 1 : 2;
          objectChild[i].label = isRuleSet(objectChild[i].ruleSetIndex) ? 'ruleSet' : 'filter';
        } else if (objectChild[i].type == 3) {
          const OperatorOfindex = objectChild[i].OperatorOfRuleSetIndex;
          if (OperatorOfindex != null) {
            const OperatorId = ArraytOperatorIdContent.value[OperatorOfindex - 1]?.logicRuleSetOperatorId;
            objectChild[i].logicRuleSetOperatorId = OperatorId == null ? null : OperatorId;
          }
        }
      }
      return true;
    } else {
      return false;
    }
  };

  //use when click swicth operetor between rule set
  const setOperetorRuleSet = (Oparetor: CreateSegmentModal.AdvanecOperator) => {
    ArraytOperatorIdContent.value[Oparetor.ruleSetIndex - 1].logicRuleSetOperatorId = Oparetor.logicRuleSetOperatorId;
    ArraytOperatorIdContent.value.forEach((item) => {
      item.status = false;
    });
    return (
      updateSwicthOperatorId(Oparetor.ruleSetIndex, Oparetor.logicRuleSetOperatorId, listGroup.value),
      updateOperatorIdFixModel(listGroup.value),
      genLogicString(listGroup.value),
      genLogicRuleSet()
    );
  };

  // use when click add ruleSet
  const addRuleSetModel = (type: number) => {
    if (listGroup.value.length != 0) {
      const Index = listGroup.value.length - 1;
      if (listGroup.value[Index].type != 3) {
        const temp: CreateSegmentModal.GroupBucket = {
          seq: listGroup.value.length + 1,
          type: 3,
          label: 'operator',
          ruleSetIndex: null!,
          OperatorOfRuleSetIndex: conditionAdvance.ruleSetList.length - 1,
          logicRuleSetOperatorId: 1,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        listGroup.value.push(temp);
        const object: CreateSegmentModal.AdvanecOperator = {
          ruleSetIndex: conditionAdvance.ruleSetList.length - 1,
          logicRuleSetOperatorId: 1,
          status: false,
        };
        ArraytOperatorIdContent.value.push(object);
      }
    }
    switch (type) {
      case 1:
        const ruleSet: CreateSegmentModal.GroupBucket = {
          seq: listGroup.value.length + 1,
          type: type,
          label: 'ruleSet',
          ruleSetIndex: conditionAdvance.ruleSetList.length, //ruleSetList.length
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        listGroup.value.push(ruleSet);
        if (listGroup.value.length == 3 && conditionAdvance.ruleSetList.length == 5) {
          hiddenGroup.value = true;
        } else {
          hiddenGroup.value = false;
        }
        break;
      case 2:
        const filter: CreateSegmentModal.GroupBucket = {
          seq: listGroup.value.length + 1,
          type: type,
          label: 'filter',
          ruleSetIndex: conditionAdvance.ruleSetList.length, //ruleSetList.length
          OperatorOfRuleSetIndex: null!,
          logicRuleSetOperatorId: null!,
          isCompleteBucket: true,
          isMapping: false,
          child: null!,
        };
        listGroup.value.push(filter);
        if (listGroup.value.length == 3 && conditionAdvance.ruleSetList.length == 5) {
          hiddenGroup.value = true;
        } else {
          hiddenGroup.value = false;
        }
        break;
    }
  };

  // use when click remove ruleSet
  const removeRuleSetModel = (ruleSetIndex: number) => {
    listText.value = resultLogicText.value.split(' ');

    let operatorRuleSet = 0;
    let status = false;
    resultLogicText.value = '';
    for (let i = 0; i < listText.value.length; i++) {
      if (listText.value[i] == ruleSetIndex.toString()) {
        //find Index remove
        status = true;
        operatorRuleSet = i;
      } else if (!status) {
        if (i != listText.value.length - 1) {
          resultLogicText.value += listText.value[i] + ' ';
        } else {
          resultLogicText.value += listText.value[i];
        }
      } else {
        if (i != listText.value.length - 1 && i != operatorRuleSet) {
          if (
            listText.value[i] != '(' &&
            listText.value[i] != ')' &&
            listText.value[i].toLowerCase() != 'and' &&
            listText.value[i].toLowerCase() != 'or' &&
            i != operatorRuleSet + 1
          ) {
            const setIndex = parseInt(listText.value[i]);
            resultLogicText.value += (setIndex - 1).toString() + ' ';
          } else if (i != operatorRuleSet + 1) {
            resultLogicText.value += listText.value[i] + ' ';
          }
        } else if (
          i == listText.value.length - 1 &&
          i != operatorRuleSet &&
          (listText.value[i].toLowerCase() != 'and' || listText.value[i].toLowerCase() != 'or')
        ) {
          if (listText.value[i] != ')') {
            const setIndex = parseInt(listText.value[i]);
            resultLogicText.value += (setIndex - 1).toString() + ' ';
          } else {
            resultLogicText.value += listText.value[i];
          }
        }
      }
    }
    listText.value = resultLogicText.value.split(' ');

    //if find '' in list
    const hold = listText.value;
    if (hold[hold.length - 1].length == 0) {
      listText.value.splice(hold.length - 1, 1);
    }

    //if find and/or on last index of list
    resultLogicText.value = '';
    logicText.value = '';

    if (listText.value[listText.value.length - 1].toLowerCase() == 'and' || listText.value[listText.value.length - 1].toLowerCase() == 'or') {
      listText.value.splice(listText.value.length - 1, 1);

      for (let i = 0; i < listText.value.length; i++) {
        if (i != listText.value.length - 1) {
          logicText.value += listText.value[i] + ' ';
        } else {
          logicText.value += listText.value[i];
        }
      }
    } else {
      for (let i = 0; i < listText.value.length; i++) {
        if (i != listText.value.length - 1) {
          logicText.value += listText.value[i] + ' ';
        } else {
          logicText.value += listText.value[i];
        }
      }
    }

    listText.value = [];
    listGroup.value = [];
    ArraytOperatorIdContent.value = [];

    prepareListMap.value = [];

    indexRoot.value = 0;
    findCountRoot.value = 0;
    ArrayLogicText.value = [];

    return initModel();
  };

  const updateSwicthOperatorId = (oparetorOfRuleSetIndex: number, oparetorId: number, listGroup: CreateSegmentModal.GroupBucket[]) => {
    for (let i = 0; i < listGroup.length; i++) {
      if (listGroup[i].type == 4) {
        const status = updateSwicthOperatorIdChild(oparetorOfRuleSetIndex, oparetorId, listGroup[i].child);
        if (status) {
        } else {
          break;
        }
      }
    }
  };
  const updateSwicthOperatorIdChild = (oparetorOfRuleSetIndex: number, oparetorId: number, objectChild: CreateSegmentModal.GroupBucket[] | null) => {
    if (objectChild?.length != 0 && objectChild?.length != null) {
      const findOperatorOfRuleSet = objectChild.find((a) => a.OperatorOfRuleSetIndex == oparetorOfRuleSetIndex);
      if (findOperatorOfRuleSet) {
        objectChild.forEach((item) => {
          if (item.type == 3 && item.logicRuleSetOperatorId != null) {
            const findOperatorOfRuleSet = ArraytOperatorIdContent.value.find((a) => a.ruleSetIndex == item.OperatorOfRuleSetIndex);
            if (findOperatorOfRuleSet) {
              ArraytOperatorIdContent.value[findOperatorOfRuleSet.ruleSetIndex - 1].logicRuleSetOperatorId = oparetorId;
            }
          }
        });
        return true;
      } else {
        for (let i = 0; i < objectChild.length; i++) {
          if (objectChild[i].type == 4) {
            const objectChildLoop1 = objectChild[i]?.child || null;
            if (objectChildLoop1 != null) {
              const findOperatorOfRuleSet = objectChildLoop1.find((a) => a.OperatorOfRuleSetIndex == oparetorOfRuleSetIndex);
              if (findOperatorOfRuleSet) {
                objectChildLoop1.forEach((item) => {
                  if (item.type == 3 && item.logicRuleSetOperatorId != null) {
                    const findOperatorOfRuleSet = ArraytOperatorIdContent.value.find((a) => a.ruleSetIndex == item.OperatorOfRuleSetIndex);
                    if (findOperatorOfRuleSet) {
                      ArraytOperatorIdContent.value[findOperatorOfRuleSet.ruleSetIndex - 1].logicRuleSetOperatorId = oparetorId;
                    }
                  }
                });
                return true;
              } else {
                for (let j = 0; j < objectChildLoop1.length; j++) {
                  if (objectChildLoop1[j].type == 4) {
                    const objectChildLoop2 = objectChildLoop1[i]?.child || null;
                    if (objectChildLoop2 != null) {
                      const findOperatorOfRuleSet = objectChildLoop2.find((a) => a.OperatorOfRuleSetIndex == oparetorOfRuleSetIndex);
                      if (findOperatorOfRuleSet) {
                        objectChildLoop2.forEach((item) => {
                          if (item.type == 3 && item.logicRuleSetOperatorId != null) {
                            const findOperatorOfRuleSet = ArraytOperatorIdContent.value.find((a) => a.ruleSetIndex == item.OperatorOfRuleSetIndex);
                            if (findOperatorOfRuleSet) {
                              ArraytOperatorIdContent.value[findOperatorOfRuleSet.ruleSetIndex - 1].logicRuleSetOperatorId = oparetorId;
                            }
                          }
                        });
                        return true;
                      } else {
                        for (let k = 0; k < objectChildLoop2.length; k++) {
                          if (objectChildLoop2[j].type == 4) {
                            const objectChildLoop3 = objectChildLoop2[k]?.child || null;
                            if (objectChildLoop3 != null) {
                              const findOperatorOfRuleSet = objectChildLoop3.find((a) => a.OperatorOfRuleSetIndex == oparetorOfRuleSetIndex);
                              if (findOperatorOfRuleSet) {
                                objectChildLoop3.forEach((item) => {
                                  if (item.type == 3 && item.logicRuleSetOperatorId != null) {
                                    const findOperatorOfRuleSet = ArraytOperatorIdContent.value.find(
                                      (a) => a.ruleSetIndex == item.OperatorOfRuleSetIndex,
                                    );
                                    if (findOperatorOfRuleSet) {
                                      ArraytOperatorIdContent.value[findOperatorOfRuleSet.ruleSetIndex - 1].logicRuleSetOperatorId = oparetorId;
                                    }
                                  }
                                });
                                return true;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const selectRuleSetGroup = (indexBox: number) => {
    if (listIndexGroup.value.length == 0) {
      listIndexGroup.value.push(indexBox);
    } else {
      let findIndex = false;
      let cutIndex = 0;
      for (let index = 0; index < listIndexGroup.value.length; index++) {
        if (listIndexGroup.value[index] == indexBox) {
          cutIndex = index;
          findIndex = true;
        }
      }
      if (!findIndex) {
        listIndexGroup.value.push(indexBox);
      } else {
        listIndexGroup.value.splice(cutIndex, 1);
      }
    }
  };
  const validateRuleSetGroup = () => {
    const sortedArray = listIndexGroup.value.sort();
    let group = true;
    sortedArray.forEach((item, index) => {
      if (index != sortedArray.length - 1) {
        const value = sortedArray[index] - sortedArray[index + 1];
        if (Math.abs(value) != 2) {
          notiGroupRuleSet.value = true;
          notiType.value = 2;
          group = false;
          return false;
        }
      }
    });
    if (group) {
      return true;
    } else {
      return false;
    }
  };
  const validateOperatorRuleSetGroup = () => {
    const sortedArray = listIndexGroup.value.sort();
    const start = sortedArray[0];
    const end = sortedArray[listIndexGroup.value.length - 1];
    const operatorId = listGroup.value[start + 1].logicRuleSetOperatorId;
    let group = true;
    listGroup.value.forEach((item, index) => {
      if (index >= start && index < end) {
        const isOperator = sortedArray.find((a) => a == index);

        if (isOperator == undefined) {
          if (listGroup.value[index].logicRuleSetOperatorId != operatorId) {
            notiGroupRuleSet.value = true;
            notiType.value = 1;
            group = false;
            return false;
          }
        }
      }
    });
    if (group) {
      return true;
    } else {
      return false;
    }
  };

  const manageRuleSetGroup = () => {
    if (validateRuleSetGroup()) {
      if (validateOperatorRuleSetGroup()) {
        const oldModel = listGroup.value;
        const sortedArray = listIndexGroup.value.sort();
        const start = sortedArray[0];
        const end = sortedArray[listIndexGroup.value.length - 1];
        const countEnd = Math.abs(end - start) + 1;
        let count = 0;
        preparelistGroup.value = [];
        prepareGroup.value = [];
        listIndexGroup.value = [];
        logicText.value = '';
        ArraytOperatorIdContent.value.forEach((element) => {
          element.status = false;
        });
        if (sortedArray.length == conditionAdvance.ruleSetList.length) {
          oldModel.forEach((element, index) => {
            element.isMapping = true;
            prepareGroup.value.push(element);
          });
          const groupBucketFunction: CreateSegmentModal.GroupBucket = {
            seq: prepareGroup.value.length + 1,
            type: 4,
            label: 'bucket',
            ruleSetIndex: null!,
            OperatorOfRuleSetIndex: null!,
            logicRuleSetOperatorId: null!,
            isCompleteBucket: true,
            isMapping: false,
            child: [],
          };
          preparelistGroup.value.push(groupBucketFunction);
          prepareGroup.value.forEach((element) => {
            preparelistGroup.value[preparelistGroup.value.length - 1].child?.push(element);
          });
          prepareGroup.value = [];
        } else {
          oldModel.forEach((element, index) => {
            if (index >= start && index <= end) {
              prepareGroup.value.push(element);
              count += 1;
              if (count == countEnd) {
                const groupBucketFunction: CreateSegmentModal.GroupBucket = {
                  seq: preparelistGroup.value.length + 1,
                  type: 4,
                  label: 'bucket',
                  ruleSetIndex: null!,
                  OperatorOfRuleSetIndex: null!,
                  logicRuleSetOperatorId: null!,
                  isCompleteBucket: true,
                  isMapping: false,
                  child: [],
                };
                preparelistGroup.value.push(groupBucketFunction);
                prepareGroup.value.forEach((element) => {
                  preparelistGroup.value[preparelistGroup.value.length - 1].child?.push(element);
                });
                prepareGroup.value = [];
              }
            } else {
              element.seq = preparelistGroup.value.length + 1;
              preparelistGroup.value.push(element);
            }
          });
        }
        genLogicString(preparelistGroup.value);
        genLogicRuleSet();
        ArraytOperatorIdContent.value = [];
        logicText.value = resultLogicText.value;
        listGroup.value = [];
        listIndexGroup.value = [];
        initModel();
      }
    }
  };

  const unGroupRuleSetAll = () => {
    closeNotiGroupRuleSet();
    ArrayLogicText.value = [];
    const array = resultLogicText.value.split(' ');
    array.forEach((element) => {
      if (element != '(' && element != ')') {
        ArrayLogicText.value.push(element);
      }
    });
    genLogicRuleSet();
    preparelistGroup.value = [];
    prepareGroup.value = [];
    listIndexGroup.value = [];
    listGroup.value = [];
    ArraytOperatorIdContent.value = [];
    logicText.value = resultLogicText.value;
    resultLogicText.value = '';
    initModel();
  };

  const closeNotiGroupRuleSet = () => {
    isSubmitAdvance.value = true;
    notiGroupRuleSet.value = false;
    notiType.value = 0;
    isSubmitAdvance.value = false;
  };
  const notiUngroup = () => {
    notiGroupRuleSet.value = true;
    notiType.value = 3;
  };
  //=======================================  logic RuleSet  ===========================================================//

  const addDays = (date: string, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const onHandleFilterBySegmentAdvance = (val: FilterBySegmentCard) => {
    vm?.$emit('handleFilterBySegmentAdvance', { val: val });
  };

  const handleFilterBySegmentAdvance = (val: FilterBySegmentCard) => {
    onFilterBySegmentAdvance(val);
  };

  const onFilterBySegmentAdvance = (val: FilterBySegmentCard) => {
    conditionAdvance.ruleSetList[val.indexFilter - 1].ruleName = val.ruleFilter.ruleName;
    conditionAdvance.ruleSetList[val.indexFilter - 1].rootView = val.ruleFilter.selectView;
    conditionAdvance.ruleSetList[val.indexFilter - 1].selectSegment = val.ruleFilter.selectSegment;
    conditionAdvance.ruleSetList[val.indexFilter - 1].flagExclude = val.ruleFilter.ruleExclude;
  };

  const initColumns = () => {
    const columns = JSON.parse(JSON.stringify(props.columns)) || [];

    let dataSource = null;
    try {
      dataSource = JSON.parse(JSON.stringify(props.dataSource));
      tableId.value = dataSource.tableid;
      columnAdvanceList.value = columns.filter((element: DynamicTable.Column) => element.is_filter == true);
      aggregateColumnAdvanceList.value = columns.filter((element: DynamicTable.Column) => element.is_filter == true);
      groupbyColumnAdvanceList.value = columns;
    } catch (e) {
      dataSource = null;
    }
  };

  const initRuleSet = () => {
    if (!props.segment) return;
    reset.value = false;
    const operator = setOperatorAdvanceList.find((operator) => operator.value == props.segment?.set_op);
    ruleSetOperatorAdvance.value = operator!;
    const ruleSetList: CreateSegmentModal.RuleSet[] = [];
    let prepareGroupBylist: CreateSegmentModal.GroupBy[] = [];
    if (props.segment?.set_op == 3) {
      props.segment.rulesets?.forEach((ruleset) => {
        const ruleListFiltered = ruleset.rule_predicates.filter((item) => item.ruletype == 1).sort((a, b) => a.predicate_seq - b.predicate_seq);
        const aggListFiltered = ruleset.rule_predicates.filter((item) => item.ruletype == 2).sort((a, b) => a.predicate_seq - b.predicate_seq);
        const logicOperatorAggregate = setOperatorAdvanceList.find((operator) => operator.value == ruleset?.agg_op);
        let TypeOperationList = null;
        ruleAggregateOperatorAdvance.value = logicOperatorAggregate!;

        const ruleList = ruleListFiltered.map((rule) => {
          key.value++;
          let selectedColumn = null;
          let IndexOperationList: number = null!;

          if (columnAdvanceList.value && columnAdvanceList.value.length) {
            selectedColumn = columnAdvanceList.value.find((column) => column.column_id == rule.l_columnid);
            if (selectedColumn == undefined) {
              selectedColumn = columnAdvanceList.value.find((column) => column.column_name == rule.l_columnname);
            }
            if (selectedColumn) selectedColumn.isSelected = false;
          }
          if (props.DataTypeOperationList) {
            props.DataTypeOperationList?.forEach((TypeOperation, index) => {
              TypeOperationList = TypeOperation.data_types.find((types) => types == rule.r_value_type);
              if (TypeOperationList) {
                IndexOperationList = index;
              }
            });
          }

          return {
            key: key.value,
            ruleName: rule.rulename,
            ruleType: rule.ruletype,
            column: selectedColumn,
            operatorId: rule.exp_comp_op_id,
            unitId: rule.exp_comp_label_unit_id,
            value1:
              rule?.r_value_type_desc == 'Date' &&
              rule.exp_comp_op_id != 51 &&
              rule.exp_comp_op_id != 52 &&
              rule.exp_comp_op_id != 53 &&
              rule.exp_comp_op_id != 54 &&
              rule.exp_comp_op_id != 55 &&
              rule.exp_comp_op_id != 56 &&
              rule.exp_comp_op_id != 57 &&
              rule.exp_comp_op_id != 151 &&
              rule.exp_comp_op_id != 152
                ? new Date(rule.r_value as string)
                : rule.exp_comp_op_id == 55 || rule.exp_comp_op_id == 56
                ? parseInt(rule.r_value as string)
                : rule.exp_comp_op_id == 11 || rule.exp_comp_op_id == 111
                ? setKeyTagStringToArray(rule.r_value as string)
                : rule.r_value,
            value2: rule?.r_value_type_desc == 'Date' ? new Date(rule.r2_value as string) : rule.r2_value,
            warningColumn: selectedColumn
              ? null
              : {
                  l_columnalias: rule.l_columnalias,
                  l_columnname: rule.l_columnname,
                  r_value_type: rule.r_value_type,
                  r_value_type_desc: rule.r_value_type_desc,
                  operation_list: props.DataTypeOperationList ? props.DataTypeOperationList[IndexOperationList].operation_list : [],
                },
          };
        });
        const aggList = aggListFiltered.map((rule) => {
          key.value++;
          let selectedColumn = null;
          let IndexOperationList: number = null!;

          if (aggregateColumnAdvanceList.value && aggregateColumnAdvanceList.value.length) {
            selectedColumn = aggregateColumnAdvanceList.value.find((column) => column.column_id == rule.l_columnid);
            if (selectedColumn == undefined) {
              selectedColumn = aggregateColumnAdvanceList.value.find((column) => column.column_name == rule.l_columnname);
            }
            if (selectedColumn) selectedColumn.isSelected = false;
          }
          if (props.DataTypeOperationList) {
            props.DataTypeOperationList?.forEach((TypeOperation, index) => {
              TypeOperationList = TypeOperation.data_types.find((types) => types == rule.r_value_type);
              if (TypeOperationList) {
                IndexOperationList = index;
              }
            });
          }
          return {
            key: key.value,
            ruleName: rule.rulename,
            ruleType: rule.ruletype,
            column: selectedColumn,
            operatorId: rule.exp_comp_op_id,
            unitId: rule.exp_comp_label_unit_id,
            aggregateFuncId: rule.l_aggregatefuncid,
            value1:
              selectedColumn?.type_desc == 'Date' &&
              rule.exp_comp_op_id != 51 &&
              rule.exp_comp_op_id != 52 &&
              rule.exp_comp_op_id != 53 &&
              rule.exp_comp_op_id != 54 &&
              rule.exp_comp_op_id != 55 &&
              rule.exp_comp_op_id != 56 &&
              rule.exp_comp_op_id != 57 &&
              rule.exp_comp_op_id != 151 &&
              rule.exp_comp_op_id != 152
                ? new Date(rule.r_value as string)
                : rule.exp_comp_op_id == 55 || rule.exp_comp_op_id == 56
                ? parseInt(rule.r_value as string)
                : rule.exp_comp_op_id == 11 || rule.exp_comp_op_id == 111
                ? setKeyTagStringToArray(rule.r_value as string)
                : rule.r_value,
            value2: rule?.r_value_type_desc == 'Date' ? new Date(rule.r2_value as string) : rule.r2_value,
            warningColumn: selectedColumn
              ? null
              : {
                  l_columnalias: rule.l_columnalias,
                  l_columnname: rule.l_columnname,
                  r_value_type: rule.r_value_type,
                  r_value_type_desc: rule.r_value_type_desc,
                  operation_list: props.DataTypeOperationList ? props.DataTypeOperationList[IndexOperationList].operation_list : [],
                },
          };
        });
        const ruleEquationTH =
          ruleset.rule_op == 1
            ? ruleset.rule_logical_equation.replaceAll('AND', 'และ')
            : ruleset.rule_op == 2
            ? ruleset.rule_logical_equation.replaceAll('OR', 'หรือ')
            : '';
        const aggregateEquationTH =
          ruleset.agg_op != null && ruleset.agg_op == 1 && ruleset.agg_logical_equation != null
            ? ruleset.agg_logical_equation.replaceAll('AND', 'และ')
            : ruleset.agg_op != null && ruleset.agg_op == 2 && ruleset.agg_logical_equation != null
            ? ruleset.agg_logical_equation.replaceAll('OR', 'หรือ')
            : '';
        ruleSetList.push({
          // ruleName: ruleset.name,
          ruleName: '',
          logic: ruleset.rule_logical_equation,
          logicTH: ruleEquationTH,
          aggLogicEquation: ruleset.agg_logical_equation == null ? '' : ruleset.agg_logical_equation,
          aggLogicEquationTH: aggregateEquationTH,
          logicAggregateOperatorId: ruleset.agg_op,
          logicRuleOperatorId: ruleset.rule_op,
          useAggregate: aggListFiltered.length > 0,
          rules: ruleList as CreateSegmentModal.Rule[],
          aggregates: aggList as CreateSegmentModal.Rule[],
          useFilter: ruleset.filter_segmentid != null ? true : false,
          flagExclude: ruleset.filter_segmentid != null ? ruleset.filter_flag_exclude : false,
          selectSegment: ruleset.filter_segmentid != null ? ruleset.filter_segmentid : null!,
          rootView: ruleset.filter_segmentid != null ? ruleset.filter_root_tableid : null!,
        });
        prepareGroupBylist = ruleset.listgroupby.map((gb) => {
          return { column_id: gb.columnid, table_id: gb.tableid };
        });
      });
      conditionAdvance.ruleSetList = ruleSetList;
      conditionAdvance.groupByList = prepareGroupBylist;
      useGroupByAdvance.value = conditionAdvance.groupByList.length > 0;

      // for Advance
      logicText.value = props.segment?.set_logical_equation == null ? '' : props.segment?.set_logical_equation;
      initModel();
    }
  };

  const addRuleSetAdvance = () => {
    conditionAdvance.ruleSetList.push({
      ruleName: '',
      logic: '',
      logicTH: '',
      aggLogicEquation: '',
      aggLogicEquationTH: '',
      rules: [],
      aggregates: [],
      useAggregate: false,
      logicAggregateOperatorId: null!,
      logicRuleOperatorId: null!,
      flagExclude: null!,
      selectSegment: null!,
      useFilter: false,
      rootView: null!,
    });

    ArraytOperatorIdContent.value.forEach((item) => {
      item.status = false;
    });
    return (
      addRuleSetModel(1),
      genLogicString(listGroup.value),
      genLogicRuleSet(),
      addRuleAdvance(conditionAdvance.ruleSetList.length - 1),
      scrollIntoView()
    );
  };

  const addFilterSegmentAdvance = () => {
    conditionAdvance.ruleSetList.push({
      ruleName: '',
      logic: '',
      logicTH: '',
      aggLogicEquation: '',
      aggLogicEquationTH: '',
      rules: [],
      aggregates: [],
      useAggregate: false,
      logicAggregateOperatorId: null!,
      logicRuleOperatorId: null!,
      flagExclude: null!,
      selectSegment: null!,
      useFilter: true,
      rootView: null!,
    });
    ArraytOperatorIdContent.value.forEach((item) => {
      item.status = false;
    });
    return addRuleSetModel(2), genLogicString(listGroup.value), genLogicRuleSet(), scrollIntoView();
  };

  const scrollIntoView = () => {
    setScrollContent.value = document.getElementById('footer-content');
    setTimeout(() => {
      if (setScrollContent.value) {
        return setScrollContent.value.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const updateLogicRule = (ruleSetindex: number) => {
    conditionAdvance.ruleSetList[ruleSetindex].rules.forEach((rule, index) => {
      const name = generateRuleName('upper', index);
      rule.key = index + 1;
      rule.ruleName = name;
    });
    conditionAdvance.ruleSetList[ruleSetindex].logic = '';
    conditionAdvance.ruleSetList[ruleSetindex].logicTH = '';
    const operatorName =
      conditionAdvance.ruleSetList[ruleSetindex].logicRuleOperatorId == null
        ? null
        : conditionAdvance.ruleSetList[ruleSetindex].logicRuleOperatorId == 1
        ? 'AND'
        : 'OR';
    const operatorNameTH =
      conditionAdvance.ruleSetList[ruleSetindex].logicRuleOperatorId == null
        ? null
        : conditionAdvance.ruleSetList[ruleSetindex].logicRuleOperatorId == 1
        ? 'และ'
        : 'หรือ';

    conditionAdvance.ruleSetList[ruleSetindex].rules.forEach((rule, index) => {
      if (index != conditionAdvance.ruleSetList[ruleSetindex].rules.length - 1 && operatorName != null && operatorNameTH != null) {
        conditionAdvance.ruleSetList[ruleSetindex].logic += rule.ruleName + ' ' + operatorName + ' ';
        conditionAdvance.ruleSetList[ruleSetindex].logicTH += rule.ruleName + ' ' + operatorNameTH + ' ';
      } else if (index != conditionAdvance.ruleSetList[ruleSetindex].rules.length - 1 && operatorName == null) {
        conditionAdvance.ruleSetList[ruleSetindex].logic += rule.ruleName + ' ';
        conditionAdvance.ruleSetList[ruleSetindex].logicTH += rule.ruleName + ' ';
      } else {
        conditionAdvance.ruleSetList[ruleSetindex].logic += rule.ruleName;
        conditionAdvance.ruleSetList[ruleSetindex].logicTH += rule.ruleName;
      }
    });
  };

  const updateLogicAggregate = (ruleSetindex: number) => {
    conditionAdvance.ruleSetList[ruleSetindex].aggregates.forEach((rule, index) => {
      const name = generateRuleName('lower', index);
      rule.key = index + 1;
      rule.ruleName = name;
    });
    conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquation = '';
    conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquationTH = '';
    const operatorName =
      conditionAdvance.ruleSetList[ruleSetindex].logicAggregateOperatorId == null
        ? null
        : conditionAdvance.ruleSetList[ruleSetindex].logicAggregateOperatorId == 1
        ? 'AND'
        : 'OR';

    const operatorNameTH =
      conditionAdvance.ruleSetList[ruleSetindex].logicAggregateOperatorId == null
        ? null
        : conditionAdvance.ruleSetList[ruleSetindex].logicAggregateOperatorId == 1
        ? 'และ'
        : 'หรือ';

    conditionAdvance.ruleSetList[ruleSetindex].aggregates.forEach((rule, index) => {
      if (index != conditionAdvance.ruleSetList[ruleSetindex].aggregates.length - 1 && operatorName != null && operatorNameTH != null) {
        conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquation += rule.ruleName + ' ' + operatorName + ' ';
        conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquationTH += rule.ruleName + ' ' + operatorNameTH + ' ';
      } else if (index != conditionAdvance.ruleSetList[ruleSetindex].aggregates.length - 1 && operatorName == null && operatorNameTH == null) {
        conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquation += rule.ruleName + ' ';
        conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquationTH += rule.ruleName + ' ';
      } else {
        conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquation += rule.ruleName;
        conditionAdvance.ruleSetList[ruleSetindex].aggLogicEquationTH += rule.ruleName;
      }
    });
  };

  const addGroupByAdvance = () => {
    const groupByFunction: CreateSegmentModal.GroupBy = {
      column_id: null!,
      table_id: tableId.value,
    };

    return conditionAdvance.groupByList.push(groupByFunction);
  };

  const deleteGroupByAdvance = (groupbyIndex: number) => {
    conditionAdvance.groupByList.splice(groupbyIndex, 1);

    return conditionAdvance.groupByList;
  };

  const generateRuleName = (mode: 'upper' | 'lower', index: number): string => {
    if (mode == 'upper') {
      const charCode = 65 + index;

      return charCode > 90 ? '' : String.fromCharCode(charCode);
    }
    if (mode == 'lower') {
      const charCode = 97 + index;

      return charCode > 122 ? '' : String.fromCharCode(charCode);
    }

    return '';
  };

  //================================================================================//
  const handleSetAddRuleAdvance = (ruleSetindex: number) => {
    vm?.$emit('handleSetAddRuleAdvance', { ruleSetindex: ruleSetindex });
  };

  const onSetAddRuleAdvance = (ruleSetindex: number) => {
    addRuleAdvance(ruleSetindex);
  };

  const addRuleAdvance = (ruleSetindex: number) => {
    const ruleName = generateRuleName('upper', conditionAdvance.ruleSetList[ruleSetindex].rules.length);
    if (ruleName) {
      const rule: CreateSegmentModal.Rule = {
        key: key.value,
        ruleName: ruleName,
        ruleType: 1,
        column: null!,
        operatorId: null!,
        unitId: null!,
        value1: null,
      };
      key.value++;
      conditionAdvance.ruleSetList[ruleSetindex].rules.push(rule);
      updateLogicRule(ruleSetindex);
    }
  };

  //================================================================================//

  const handleSetAddAggregateAdvance = (ruleSetindex: number) => {
    vm?.$emit('handleSetAddAggregateAdvance', { ruleSetindex: ruleSetindex });
  };

  const onSetAddAggregateAdvance = (ruleSetindex: number) => {
    addAggregateAdvance(ruleSetindex);
  };

  const addAggregateAdvance = (ruleSetindex: number) => {
    const ruleName = generateRuleName('lower', conditionAdvance.ruleSetList[ruleSetindex].aggregates.length);

    if (ruleName) {
      const aggregateFunction: CreateSegmentModal.Rule = {
        key: key.value,
        ruleName: ruleName,
        ruleType: 2,
        column: null!,
        operatorId: null!,
        unitId: null!,
        value1: null,
        aggregateFuncId: null!,
      };
      key.value++;
      conditionAdvance.ruleSetList[ruleSetindex].aggregates.push(aggregateFunction);
      updateLogicAggregate(ruleSetindex);
    }
  };

  //================================================================================//
  const handleSetDeleteRuleAdvance = (ruleSetIndex: number, ruleIndex: number) => {
    vm?.$emit('handleSetDeleteRuleAdvance', { ruleSetIndex: ruleSetIndex, ruleIndex: ruleIndex });
  };

  const onSetDeleteRuleAdvance = (ruleSetIndex: number, ruleIndex: number) => {
    deleteRuleAdvance(ruleSetIndex, ruleIndex);
  };

  const deleteRuleAdvance = (ruleSetIndex: number, ruleIndex: number) => {
    const deletedRule = conditionAdvance.ruleSetList[ruleSetIndex].rules[ruleIndex];
    if (deletedRule.column) {
      deletedRule.column.isSelected = false;
    }
    conditionAdvance.ruleSetList[ruleSetIndex].rules.splice(ruleIndex, 1);
    conditionAdvance.ruleSetList[ruleSetIndex].rules.forEach((rule, index) => {
      rule.ruleName = generateRuleName('upper', index);
    });
    return updateLogicRule(ruleSetIndex);
  };
  //================================================================================//
  const handleSetDeleteAggregateAdvance = (ruleSetIndex: number, ruleIndex: number) => {
    vm?.$emit('handleSetDeleteAggregateAdvance', { ruleSetIndex: ruleSetIndex, ruleIndex: ruleIndex });
  };

  const onSetDeleteAggregateAdvance = (ruleSetIndex: number, ruleIndex: number) => {
    deleteAggregateAdvance(ruleSetIndex, ruleIndex);
  };

  const deleteAggregateAdvance = (ruleSetIndex: number, aggregateFunctionIndex: number) => {
    const deletedRule = conditionAdvance.ruleSetList[ruleSetIndex].aggregates[aggregateFunctionIndex];

    if (deletedRule.column) {
      deletedRule.column.isSelected = false;
    }

    conditionAdvance.ruleSetList[ruleSetIndex].aggregates.splice(aggregateFunctionIndex, 1);

    conditionAdvance.ruleSetList[ruleSetIndex].aggregates.forEach((aggregateFunction, index) => {
      aggregateFunction.ruleName = generateRuleName('lower', index);
    });
    updateLogicAggregate(ruleSetIndex);
  };

  //================================================================================//
  const handleToggleAggregateAdvance = (event: Event, ruleSetIndex: number) => {
    vm?.$emit('handleSetToggleAggregateAdvance', { event: event, ruleSetIndex: ruleSetIndex });
  };

  const onSetToggleAggregateAdvance = (event: Event, ruleSetIndex: number) => {
    onToggleAggregateAdvance(event, ruleSetIndex);
  };

  const onToggleAggregateAdvance = (event: Event, ruleSetIndex: number) => {
    const status = (<HTMLInputElement>event.target).checked;
    conditionAdvance.ruleSetList[ruleSetIndex].useAggregate = (<HTMLInputElement>event.target).checked;
    if (!status) {
      if (conditionAdvance.ruleSetList[ruleSetIndex].rules.length == 0) {
        return setModalTypeToggleAggregate(ruleSetIndex), addRuleAdvance(ruleSetIndex);
      }
      return setModalTypeToggleAggregate(ruleSetIndex);
    }
    useGroupByAdvance.value = conditionAdvance.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    return onOpenToggleAggregate(event, ruleSetIndex);
  };

  const onOpenToggleAggregate = (event: Event, ruleSetIndex: number) => {
    conditionAdvance.ruleSetList[ruleSetIndex].useAggregate = (<HTMLInputElement>event.target).checked;
    useGroupByAdvance.value = conditionAdvance.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    if (conditionAdvance.ruleSetList[ruleSetIndex].useAggregate && useGroupByAdvance.value && conditionAdvance.groupByList.length > 0) {
      return addAggregateAdvance(ruleSetIndex);
    }
    return addAggregateAdvance(ruleSetIndex), addGroupByAdvance();
  };

  const onCloseToggleAggregate = (ruleSetIndex: number) => {
    conditionAdvance.ruleSetList[ruleSetIndex].useAggregate = false;
    useGroupByAdvance.value = conditionAdvance.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    if (!conditionAdvance.ruleSetList[ruleSetIndex].useAggregate) {
      conditionAdvance.ruleSetList[ruleSetIndex].aggregates = [];
      if (!useGroupByAdvance.value) {
        conditionAdvance.groupByList = [];
      }
      return conditionAdvance.ruleSetList[ruleSetIndex].aggregates;
    }
  };

  const setModalTypeToggleAggregate = (ruleSetIndex: number) => {
    const last = conditionAdvance.ruleSetList.filter((item) => item.useAggregate == true).length;

    modalRuleSetNameAdvance.value =
      conditionAdvance.ruleSetList[ruleSetIndex].ruleName.length == 0
        ? '" ' + String(ruleSetIndex + 1) + ' "'
        : '"' + conditionAdvance.ruleSetList[ruleSetIndex].ruleName + '"';
    if (last >= 1) {
      //เหลือ aggregate มากกว่า 1 rule set
      modalRuleSetIndexAdvance.value = ruleSetIndex;

      modalTypeAdvance.value = 2;
      miniModalAdvance.value = true;
    } else {
      modalRuleSetIndexAdvance.value = ruleSetIndex;
      modalTypeAdvance.value = 1;
      miniModalAdvance.value = true;
    }
  };

  //================================================================================//
  const onSetDeleteRuleSetModalAdvance = (ruleSetIndex: number) => {
    modalRuleSetIndexAdvance.value = ruleSetIndex;
    modalRuleSetNameAdvance.value =
      conditionAdvance.ruleSetList[ruleSetIndex].ruleName.length == 0
        ? '" ' + String(ruleSetIndex + 1) + ' "'
        : '"' + conditionAdvance.ruleSetList[ruleSetIndex].ruleName + '"';

    const countAggregate = conditionAdvance.ruleSetList.filter((item, index) => item.useAggregate == true && index != ruleSetIndex).length;
    modalTypeAdvance.value =
      countAggregate >= 1 && useGroupByAdvance.value == true ? 3 : countAggregate == 0 && useGroupByAdvance.value == false ? 3 : 4;
    miniModalAdvance.value = true;
  };

  const onSetCancelMainModalAdvance = () => {
    if (conditionAdvance.ruleSetList.length != 0) {
      modalTypeAdvance.value = 5;
      miniModalAdvance.value = true;
    } else {
      onCloseModalAdvance();
    }
  };
  //================================================================================//
  const handleCloseMiniModalAdvance = (ruleSetIndex: number, modalsType: number) => {
    vm?.$emit('handleCloseMiniModalAdvance', { ruleSetIndex: ruleSetIndex, modalsType: modalsType });
  };

  const onSetCloseMiniModalAdvance = (ruleSetIndex: number, modalsType: number) => {
    onCloseMiniModalAdvance(ruleSetIndex, modalsType);
  };

  const onCloseMiniModalAdvance = (ruleSetIndex: number, modalsType: number) => {
    miniModalAdvance.value = false;

    if (modalsType == 1 || modalsType == 2) {
      conditionAdvance.ruleSetList[ruleSetIndex].useAggregate = true;
      useGroupByAdvance.value = conditionAdvance.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    }
    if (modalsType == 3 || modalsType == 4) {
      useGroupByAdvance.value = conditionAdvance.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
      return miniModalAdvance;
    }
    return miniModalAdvance;
  };
  //================================================================================//
  const handleConfirmMiniModalAdvance = (ruleSetIndex: number, modalsType: number) => {
    vm?.$emit('handleConfirmMiniModalAdvance', { ruleSetIndex: ruleSetIndex, modalsType: modalsType });
  };

  const onSetConfirmMiniModalAdvance = (ruleSetIndex: number, modalsType: number) => {
    onConfirmMiniModalAdvance(ruleSetIndex, modalsType);
  };

  const onConfirmMiniModalAdvance = (ruleSetIndex: number, modalTypeAdvance: number) => {
    if (modalTypeAdvance == 1 || modalTypeAdvance == 2) {
      miniModalAdvance.value = false;
      return onCloseToggleAggregate(ruleSetIndex);
    }
    if (modalTypeAdvance == 3 || modalTypeAdvance == 4) {
      miniModalAdvance.value = false;
      return deleteRuleSetAdvance(ruleSetIndex);
    }
  };
  //================================================================================//
  const setFormatDate = (value: Date, column: CreateSegmentModal.RuleColumn) => {
    const event: Ref<string> = ref('');
    const result: Ref<string> = ref('');
    switch (column.column_property.date_edittype) {
      case 1:
        if (column.column_property.covert_utc_flag) {
          const Ms = value.toISOString().split('.')[0] + '.000Z'; //format : ISO
          return Ms;
        }
        event.value = value.toISOString(); //format : ISO
        result.value = DateTime.fromISO(event.value).setLocale('th').toISO().split('.')[0] + '.000Z'; //todo: set zonename on setLocale
        return result.value;
      case 2:
        if (column.column_property.covert_utc_flag) {
          const Ms = value.toISOString().split('.')[0] + '.000Z'; //format : ISO
          return Ms;
        }
        event.value = value.toISOString(); //format : ISO
        result.value = DateTime.fromISO(event.value).setLocale('th').toISO().split('.')[0] + '.000Z'; //todo: set zonename on setLocale
        return result.value;

      case 3:
        if (column.column_property.covert_utc_flag) {
          const Ms = value.toISOString().split('.')[0] + '.000Z'; //format : ISO
          return Ms;
        }
        event.value = value.toISOString(); //format : ISO
        result.value = DateTime.fromISO(event.value).setLocale('th').toISO().split('.')[0] + '.000Z'; //todo: set zonename on setLocale
        return result.value;
    }
  };
  //================================================================================//
  const handleSetAggregateOperatorAdvance = (operator: number, ruleSetSeq: number) => {
    vm?.$emit('handleSetAggregateOperatorAdvance', { operator: operator, ruleSetSeq: ruleSetSeq });
  };

  const onSetAggregateOperatorAdvance = (operator: number, ruleSetSeq: number) => {
    setAggregateOperatorAdvance(operator, ruleSetSeq);
  };

  const setAggregateOperatorAdvance = (operator: number, ruleSetSeq: number) => {
    const obj = setOperatorAdvanceList.find((item) => item.value == operator);
    conditionAdvance.ruleSetList[ruleSetSeq].logicAggregateOperatorId = operator;
    ruleAggregateOperatorAdvance.value = obj!;
    updateLogicAggregate(ruleSetSeq);
  };

  //================================================================================//
  const handleSetRuleOperatorAdvance = (operator: number, ruleSetSeq: number) => {
    vm?.$emit('handleSetRuleOperatorAdvance', { operator: operator, ruleSetSeq: ruleSetSeq });
  };

  const onSetRuleOperatorAdvance = (operator: number, ruleSetSeq: number) => {
    setRuleOperatorAdvance(operator, ruleSetSeq);
  };
  const setRuleOperatorAdvance = (operator: number, ruleSetSeq: number) => {
    const obj = setOperatorAdvanceList.find((item) => item.value == operator);
    conditionAdvance.ruleSetList[ruleSetSeq].logicRuleOperatorId = operator;
    ruleOperatorAdvance.value = obj!;
    updateLogicRule(ruleSetSeq);
  };
  //==================================================================================//

  const deleteRuleSetAdvance = (index: number) => {
    listIndexGroup.value = [];
    conditionAdvance.ruleSetList.splice(index, 1);
    useGroupByAdvance.value = conditionAdvance.ruleSetList.find((item) => item.useAggregate == true)?.useAggregate ? true : false;
    if (!useGroupByAdvance.value) {
      conditionAdvance.groupByList = [];
    }
    if (conditionAdvance.ruleSetList && conditionAdvance.ruleSetList.length) {
      return removeRuleSetModel(index + 1);
    } else {
      conditionAdvance.ruleSetList = [];
      conditionAdvance.groupByList = [];
      filterAdvanceSegmentList.value = [];

      useGroupByAdvance.value = false;
      key.value = 0;
      miniModalAdvance.value = false;
      modalTypeAdvance.value = null!;
      modalRuleSetIndexAdvance.value = null!;
      modalRuleSetNameAdvance.value = null!;

      //advance
      logicText.value = null!;
      listText.value = [];
      listGroup.value = [];
      resultLogicText.value = null!;
      backwardIndex.value = null!;
      indexRoot.value = null!;
      findCountRoot.value = null!;
      ArrayLogicText.value = [];
      ArraytOperatorIdContent.value = [];
      checkListText.value = null!;
      resultLogicTextTH.value = '';
      preparelistGroup.value = [];
      prepareGroup.value = [];
      listIndexGroup.value = [];
      notiGroupRuleSet.value = false;
      notiType.value = 0;
      hiddenGroup.value = false;
    }
  };

  const deleteFilterSegmentAdvance = (index: number) => {
    listIndexGroup.value = [];
    filterAdvanceSegmentList.value.splice(index, 1);
    removeRuleSetModel(index);
    //advance
    logicText.value = '';
    listText.value = [];
    listGroup.value = [];
    resultLogicText.value = '';
    backwardIndex.value = 0;
    indexRoot.value = 0;
    findCountRoot.value = 0;
    ArrayLogicText.value = [];
    ArraytOperatorIdContent.value = [];
    checkListText.value = '';
  };
  //==================================================================================//
  const handleSelectRuleColumn = (event: CreateSegmentModal.RuleColumn, index: number, ruleSetSeq: number) => {
    vm?.$emit('handleSelectRuleColumn', { event: event, index: index, ruleSetSeq: ruleSetSeq });
  };

  const onSelectRuleSetColumn = (event: CreateSegmentModal.RuleColumn, index: number, ruleSetSeq: number) => {
    onSelectRuleColumnAdvance(event, index, ruleSetSeq);
  };

  const onSelectRuleColumnAdvance = (column: CreateSegmentModal.RuleColumn, ruleIndex: number, ruleSetSeq: number) => {
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = null!;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = null!;
    if (column) {
      column.isSelected = false;

      if (conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column) {
        conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column.isSelected = false;
      }
      return (conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column = column);
    }

    const previousSelectedColumn = conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column;

    previousSelectedColumn.isSelected = false;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column = null!;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = null!;
  };
  //==================================================================================//

  const handleSelectRuleAggregateColumnAdvance = (event: CreateSegmentModal.RuleColumn, index: number, ruleSetSeq: number) => {
    vm?.$emit('handleSelectRuleAggregateColumnAdvance', { event: event, index: index, ruleSetSeq: ruleSetSeq });
  };

  const onHandleSelectRuleAggregateColumnAdvance = (event: CreateSegmentModal.RuleColumn, index: number, ruleSetSeq: number) => {
    onSelectRuleAggregateColumnAdvance(event, index, ruleSetSeq);
  };

  const onSelectRuleAggregateColumnAdvance = (column: CreateSegmentModal.RuleColumn, ruleIndex: number, ruleSetSeq: number) => {
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].aggregateFuncId = null!;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!;
    if (column) {
      column.isSelected = false;

      if (conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column) {
        conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column.isSelected = false;
      }
      return (conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column = column);
    }
    const previousSelectedColumn = conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column;

    previousSelectedColumn.isSelected = false;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column = null!;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!;
  };
  //==================================================================================//

  const onSelectRuleGroupByFunctionAdvance = (column: number, ruleIndex: number) => {
    if (column) {
      return (conditionAdvance.groupByList[ruleIndex].column_id = column);
    }
  };
  //==================================================================================//

  const handleSelectRuleColumnOperatorAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    vm?.$emit('handleSelectRuleColumnOperatorAdvance', { operator: operator, ruleIndex: ruleIndex, ruleSetSeq: ruleSetSeq });
  };

  const onHandleSelectRuleColumnOperatorAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    onSelectRuleColumnOperatorAdvance(operator, ruleIndex, ruleSetSeq);
  };

  const onSelectRuleColumnOperatorAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value1 = null;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value2 = null;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = null!;
    if (operator) {
      if (
        conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].column.type_desc == 'Date' &&
        operator != 51 &&
        operator != 52 &&
        operator != 53 &&
        operator != 54 &&
        operator != 55 &&
        operator != 56 &&
        operator != 57 &&
        operator != 151
      ) {
        conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value1 = new Date(today.toString());
        conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value2 = addDays(today.toString(), 1);
      }
      return (conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = operator);
    }
    return (conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].operatorId = null!);
  };
  //==================================================================================//
  const handleSelectRuleAggregateColumnOperatorAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    vm?.$emit('handleSelectRuleAggregateColumnOperatorAdvance', { operator: operator, ruleIndex: ruleIndex, ruleSetSeq: ruleSetSeq });
  };

  const onHandleSelectRuleAggregateColumnOperatorAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    onSelectRuleAggregateColumnOperatorAdvance(operator, ruleIndex, ruleSetSeq);
  };

  const onSelectRuleAggregateColumnOperatorAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value1 = null;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value2 = null;
    if (operator) {
      if (
        conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].column.type_desc == 'Date' &&
        operator != 51 &&
        operator != 52 &&
        operator != 53 &&
        operator != 151
      ) {
        conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value1 = new Date(today.toString());
        conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value2 = addDays(today.toString(), 1);
      }
      return (conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = operator);
    }
    return (conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!);
  };
  //==================================================================================//
  const handleSelectRuleAggregateColumnFunctionAdvance = (functions: number, ruleIndex: number, ruleSetSeq: number) => {
    vm?.$emit('handleSelectRuleAggregateColumnFunctionAdvance', { functions: functions, ruleIndex: ruleIndex, ruleSetSeq: ruleSetSeq });
  };

  const onHandleSelectRuleAggregateColumnFunctionAdvance = (functions: number, ruleIndex: number, ruleSetSeq: number) => {
    onSelectRuleAggregateColumnFunctionAdvance(functions, ruleIndex, ruleSetSeq);
  };

  const onSelectRuleAggregateColumnFunctionAdvance = (functions: number, ruleIndex: number, ruleSetSeq: number) => {
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].operatorId = null!;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value1 = null;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].value2 = null;
    if (functions) {
      return (conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].aggregateFuncId = functions);
    }
    return (conditionAdvance.ruleSetList[ruleSetSeq - 1].aggregates[ruleIndex].aggregateFuncId = null!);
  };
  //==================================================================================//
  const handleSelectRuleColumnUnitAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    vm?.$emit('handleSelectRuleColumnUnitAdvance', { operator: operator, ruleIndex: ruleIndex, ruleSetSeq: ruleSetSeq });
  };

  const onHandleSelectRuleColumnUnitAdvance = (operator: number, ruleIndex: number, ruleSetSeq: number) => {
    onSelectRuleColumnUnitAdvance(operator, ruleIndex, ruleSetSeq);
  };

  const onSelectRuleColumnUnitAdvance = (unit: number, ruleIndex: number, ruleSetSeq: number) => {
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value1 = null;
    conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].value2 = null;

    if (unit) {
      return (conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = unit);
    }

    return (conditionAdvance.ruleSetList[ruleSetSeq - 1].rules[ruleIndex].unitId = null!);
  };
  //==================================================================================//
  const handleSetRuleNameAdvance = (value: string, seq: number) => {
    vm?.$emit('handleSetRuleNameAdvance', { value: value, seq: seq });
  };

  const onHandleSetRuleNameAdvance = (value: string, seq: number) => {
    onSetRuleNameAdvance(value, seq);
  };

  const onSetRuleNameAdvance = (value: string, seq: number) => {
    const ruleSetIndex = seq - 1;
    const rule = conditionAdvance.ruleSetList[ruleSetIndex];

    if (rule) rule.ruleName = value;
  };
  //==================================================================================//
  const handleSetInputColumnValueAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    vm?.$emit('handleSetInputColumnValueAdvance', { event: event, type: type, ruleIndex: ruleIndex, seq: seq });
  };

  const onHandleSetInputColumnValueAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    onInputColumnValueAdvance(event, type, ruleIndex, seq);
  };

  const onInputColumnValueAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const value = (<HTMLInputElement>event.target).value || '';
    const rule = conditionAdvance.ruleSetList[ruleSetIndex].rules[ruleIndex];

    if (type == 1) {
      return (rule.value1 = value);
    }
    if (type == 2) {
      return (rule.value2 = value);
    }
  };
  //==================================================================================//

  const handleSetInputColumnValueDataListAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    vm?.$emit('handleSetInputColumnValueDataListAdvance', { event: event, type: type, ruleIndex: ruleIndex, seq: seq });
  };

  const onHandleSetInputColumnValueDataListAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    onInputColumnValueDataListAdvance(event, type, ruleIndex, seq);
  };

  const onInputColumnValueDataListAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const rule = conditionAdvance.ruleSetList[ruleSetIndex].rules[ruleIndex];
    if (type == 1) {
      return (rule.value1 = event);
    }
    if (type == 2) {
      return (rule.value2 = event);
    }
  };
  //==================================================================================//
  const handleSetInputAggregateColumnValueAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    vm?.$emit('handleSetInputAggregateColumnValueAdvance ', { event: event, type: type, ruleIndex: ruleIndex, seq: seq });
  };

  const onHandleSetInputAggregateColumnValueAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    onInputAggregateColumnValueAdvance(event, type, ruleIndex, seq);
  };

  const onInputAggregateColumnValueAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const value = (<HTMLInputElement>event.target).value || '';
    const aggregate = conditionAdvance.ruleSetList[ruleSetIndex].aggregates[ruleIndex];

    if (type == 1) {
      return (aggregate.value1 = value);
    }
    if (type == 2) {
      return (aggregate.value2 = value);
    }
  };

  //==================================================================================//

  const handleInputHandleKeyRuleTagAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    vm?.$emit('handleInputHandleKeyRuleTagAdvance', { event: event, type: type, ruleIndex: ruleIndex, seq: seq });
  };

  const onHandleInputHandleKeyRuleTagAdvance = (event: Event, type: number, ruleIndex: number, seq: number) => {
    onInputHandleKeyRuleTagAdvance(event, type, ruleIndex, seq);
  };

  const onInputHandleKeyRuleTagAdvance = (event: any, type: number, ruleIndex: number, seq: number) => {
    const ruleSetIndex = seq - 1;
    const rule = conditionAdvance.ruleSetList[ruleSetIndex].rules[ruleIndex];
    if (type == 1) {
      return (rule.value1 = event);
    }
    if (type == 2) {
      return (rule.value2 = event);
    }
  };

  //==================================================================================//

  const setKeyTagStringToArray = (stringValue: string): string[] => {
    const result: Ref<Array<string>> = ref([]);
    result.value = stringValue.split(',');
    return result.value;
  };

  const setKeyTagArrayToString = (arrayValue: any[]): string => {
    let result = '';
    arrayValue.forEach((element, index) => {
      if (index == arrayValue.length - 1) {
        result += arrayValue[index];
      } else {
        result += arrayValue[index] + ',';
      }
    });
    return result;
  };

  const createPayload = () => {
    applyWithConditionModel.payload.root_TableId = props.dataSource.tableid;
    applyWithConditionModel.payload.name = '';
    applyWithConditionModel.payload.set_Op = 3; //Only Advance
    applyWithConditionModel.payload.set_Logical_Equation = resultLogicText.value;
    if (props.isEdit) {
      applyWithConditionModel.payload.Apply_SegmentId =
        Number(router.currentRoute.value.query.applySegmentId) || Number(router.currentRoute.value.query.segmentId);
    } else {
      applyWithConditionModel.payload.Apply_SegmentId = Number(router.currentRoute.value.query.applySegmentId) || null;
    }

    const gatherRuleAndAggregateList: any[] = [];
    conditionAdvance.ruleSetList.forEach((ruleSet, ruleSetIndex) => {
      const prepareRule: Segment.Request.Rule[] = [];
      const prepareAggregate: Segment.Request.Rule[] = [];
      ruleSet.rules.map((rule, index) => {
        const resolvedRule: Segment.Request.Rule = {
          seq: index + 1,
          ruleName: rule.ruleName,
          ruleType: rule.ruleType,
          agg_Column_Alias: null,
          Exp_Comp_Op_Id: rule.operatorId as Segment.EXPCompOpId,
          exp_Comp_Label: null,
          exp_Comp_Label_Unit_Id: rule.unitId,
          l_TableId: applyWithConditionModel.payload.root_TableId,
          l_ColumnId: rule.column?.column_id,
          l_AggregateFuncId: null,
          r_Value_Type: rule.column?.data_type,
          r_Value_Label: null,
          r_Value:
            rule.column?.type_desc == 'Date' &&
            rule.operatorId != 51 &&
            rule.operatorId != 52 &&
            rule.operatorId != 53 &&
            rule.operatorId != 54 &&
            rule.operatorId != 55 &&
            rule.operatorId != 56 &&
            rule.operatorId != 57 &&
            rule.operatorId != 58 &&
            rule.operatorId != 151
              ? setFormatDate(<Date>rule.value1, rule.column)
              : rule.operatorId == 11 || rule.operatorId == 111
              ? setKeyTagArrayToString(rule.value1 as any[])
              : rule.operatorId == 58
              ? ''
              : rule.value1 == null
              ? ''
              : rule.value1,
          r2_Value_Type: null,
          r2_Value_Label: null,
          r2_Value: null,
        };
        if (rule.operatorId == 9 || rule.operatorId == 109) {
          resolvedRule.r2_Value_Type = rule.column.data_type;
          resolvedRule.r2_Value =
            rule.column?.type_desc == 'Date' ? setFormatDate(<Date>rule.value2, rule.column) : rule.value2 == null ? '' : rule.value2;
        }
        prepareRule.push(resolvedRule);
      });
      ruleSet.aggregates.map((rule, index) => {
        const resolvedRule: Segment.Request.Rule = {
          seq: index + 1,
          ruleName: rule.ruleName,
          ruleType: rule.ruleType,
          agg_Column_Alias: null,
          Exp_Comp_Op_Id: rule.operatorId as Segment.EXPCompOpId,
          exp_Comp_Label: null,
          exp_Comp_Label_Unit_Id: rule.unitId,
          l_TableId: applyWithConditionModel.payload.root_TableId,
          l_ColumnId: rule.column?.column_id,
          l_AggregateFuncId: rule.aggregateFuncId ? rule.aggregateFuncId : null,
          r_Value_Type: rule.column?.data_type,
          r_Value_Label: null,
          r_Value:
            rule.column?.type_desc == 'Date' &&
            rule.operatorId != 51 &&
            rule.operatorId != 52 &&
            rule.operatorId != 53 &&
            rule.operatorId != 54 &&
            rule.operatorId != 55 &&
            rule.operatorId != 56 &&
            rule.operatorId != 57 &&
            rule.operatorId != 151
              ? setFormatDate(<Date>rule.value1, rule.column)
              : rule.value1 == null
              ? ''
              : rule.value1,
          r2_Value_Type: null,
          r2_Value_Label: null,
          r2_Value: null,
        };
        if (rule.operatorId == 9 || rule.operatorId == 109) {
          resolvedRule.r2_Value_Type = rule.column.data_type;
          resolvedRule.r2_Value =
            rule.column?.type_desc == 'Date' ? setFormatDate(<Date>rule.value2, rule.column) : rule.value2 == null ? '' : rule.value2;
        }
        prepareAggregate.push(resolvedRule);
      });
      gatherRuleAndAggregateList[ruleSetIndex] = prepareRule.concat(prepareAggregate);
    });

    applyWithConditionModel.payload.RuleSets = conditionAdvance.ruleSetList.map((ruleSet, index) => ({
      seq: index + 1,
      name: ruleSet.ruleName.isEmptyString() ? String(index + 1) : ruleSet.ruleName,
      rule_Op: ruleSet.selectSegment != null ? 0 : ruleSet.logicRuleOperatorId,
      Filter_SegmentId: ruleSet.selectSegment,
      Filter_Flag_Exclude: ruleSet.flagExclude,
      rule_Logical_Equation: ruleSet.logic,
      agg_Op: ruleSet.logicAggregateOperatorId as Segment.SegmentOperation,
      agg_Logical_Equation: ruleSet.aggLogicEquation,
      Rules: gatherRuleAndAggregateList[index],
    }));

    const prepareGroupBylist: Segment.GroupBy[] = conditionAdvance.groupByList.map((gb) => {
      return { columnid: gb.column_id, tableid: tableId.value };
    });
    applyWithConditionModel.payload.listGroupBy = prepareGroupBylist;
  };

  const onCloseModalAdvance = () => {
    conditionAdvance.ruleSetList = [];
    conditionAdvance.groupByList = [];
    filterAdvanceSegmentList.value = [];
    columnAdvanceList.value = [];
    aggregateColumnAdvanceList.value = [];
    ruleSetOperatorAdvance.value = null!;
    ruleAggregateOperatorAdvance.value = null!;
    useGroupByAdvance.value = false;
    key.value = 0;
    miniModalAdvance.value = false;
    modalTypeAdvance.value = null!;
    modalRuleSetIndexAdvance.value = null!;
    modalRuleSetNameAdvance.value = null!;

    listGroup.value = [];
    prepareListMap.value = [];
    listText.value = [];
    indexRoot.value = 0;
    findCountRoot.value = 0;
    ArrayLogicText.value = [];
    ArraytOperatorIdContent.value = [];
    checkListText.value = '';
    resultLogicText.value = '';
    resultLogicTextTH.value = '';
    preparelistGroup.value = [];
    prepareGroup.value = [];
    listIndexGroup.value = [];
    logicText.value = '';
    notiGroupRuleSet.value = false;
    notiType.value = 0;
    hiddenGroup.value = false;
    vm?.$emit('close-modal');
  };

  const onSubmitAdvance = () => {
    isSubmitAdvance.value = true;
    createPayload();
    apiService
      .apiRequest(applyWithConditionModel)
      .then((response) => {
        if (!props.isEdit) {
          router.push({
            path: '/segment/data-segment/preview',
            query: {
              ...router.currentRoute.value.query,
              segmentMode: 1,
              segmentId: response.data.segment_id,
              applySegmentId: response.data.apply_segment_id,
              applyDestSegmentId: null,
            },
          });
        } else {
          router.replace({
            path: '/segment/data-segment/preview',
            query: {
              ...router.currentRoute.value.query,
              segmentMode: 2,
              segmentOldId: paramurl.value,
              segmentId: response.data.segment_id,
              applySegmentId: response.data.apply_segment_id,
              applyDestSegmentId: response.data.apply_segment_id,
              countApply: (Math.random() + 1).toString(20).substring(5),
            },
          });
        }

        return onCloseModalAdvance();
      })
      .catch((err) => {
        openDefaultErrorModal(err);
      })
      .finally(() => (isSubmitAdvance.value = false));
  };

  onBeforeMount(() => {
    initColumns();
    if (props.isEdit) {
      return initRuleSet();
    }
  });

  watch(
    () => reset.value,
    () => {
      conditionAdvance.ruleSetList = [];
      conditionAdvance.groupByList = [];
      filterAdvanceSegmentList.value = [];
      ruleSetOperatorAdvance.value = null!;
      ruleAggregateOperatorAdvance.value = null!;
      useGroupByAdvance.value = false;
      key.value = 0;
      miniModalAdvance.value = false;
      modalTypeAdvance.value = null!;
      modalRuleSetIndexAdvance.value = null!;
      modalRuleSetNameAdvance.value = null!;

      listGroup.value = [];
      prepareListMap.value = [];
      listText.value = [];
      indexRoot.value = 0;
      findCountRoot.value = 0;
      ArrayLogicText.value = [];
      ArraytOperatorIdContent.value = [];
      checkListText.value = '';
      resultLogicText.value = '';
      resultLogicTextTH.value = '';
      preparelistGroup.value = [];
      prepareGroup.value = [];
      listIndexGroup.value = [];
      logicText.value = '';
      notiGroupRuleSet.value = false;
      notiType.value = 0;
      hiddenGroup.value = false;
      initColumns();
    },
  );

  watch([() => props.isOpen, () => props.isEdit, () => props.segment, () => props.columns], () => {
    initColumns();
    if (props.isOpen && !props.isEdit && props.columns && props.segment?.set_op == 3) {
      conditionAdvance.ruleSetList = [];
      conditionAdvance.groupByList = [];
      filterAdvanceSegmentList.value = [];
      ruleSetOperatorAdvance.value = null!;
      ruleAggregateOperatorAdvance.value = null!;

      key.value = 0;
      miniModalAdvance.value = false;
      modalTypeAdvance.value = null!;
      modalRuleSetIndexAdvance.value = null!;
      modalRuleSetNameAdvance.value = null!;

      listGroup.value = [];
      prepareListMap.value = [];
      listText.value = [];
      indexRoot.value = 0;
      findCountRoot.value = 0;
      ArrayLogicText.value = [];
      ArraytOperatorIdContent.value = [];
      checkListText.value = '';
      resultLogicText.value = '';
      resultLogicTextTH.value = '';
      useGroupByAdvance.value = false;
      preparelistGroup.value = [];
      prepareGroup.value = [];
      listIndexGroup.value = [];
      logicText.value = '';
      notiGroupRuleSet.value = false;
      notiType.value = 0;
      hiddenGroup.value = false;
    }

    if (props.isOpen && props.isEdit && props.segment && props.segment?.set_op == 3) {
      conditionAdvance.ruleSetList = [];
      conditionAdvance.groupByList = [];
      filterAdvanceSegmentList.value = [];
      ruleSetOperatorAdvance.value = null!;
      ruleAggregateOperatorAdvance.value = null!;

      key.value = 0;
      miniModalAdvance.value = false;
      modalTypeAdvance.value = null!;
      modalRuleSetIndexAdvance.value = null!;
      modalRuleSetNameAdvance.value = null!;

      listGroup.value = [];
      prepareListMap.value = [];
      listText.value = [];
      indexRoot.value = 0;
      findCountRoot.value = 0;
      ArrayLogicText.value = [];
      ArraytOperatorIdContent.value = [];
      checkListText.value = '';
      resultLogicText.value = '';
      resultLogicTextTH.value = '';
      useGroupByAdvance.value = false;
      preparelistGroup.value = [];
      prepareGroup.value = [];
      listIndexGroup.value = [];
      logicText.value = '';
      notiGroupRuleSet.value = false;
      notiType.value = 0;
      hiddenGroup.value = false;
      initRuleSet();
    }
  });

  return {
    filterAdvanceSegmentList,
    conditionAdvance,
    setOperatorAdvanceList,
    columnAdvanceList,
    aggregateColumnAdvanceList,
    groupbyColumnAdvanceList,
    ruleSetOperatorAdvance,
    ruleAggregateOperatorAdvance,
    logicAreaAdvance,
    logicAggAreaAdvance,
    isSubmitAdvance,
    useGroupByAdvance,
    miniModalAdvance,
    modalTypeAdvance,
    modalRuleSetIndexAdvance,
    modalRuleSetNameAdvance,

    setOperatorRuleAdvanceList,
    setRuleOperatorAdvance,

    addRuleSetAdvance,
    addFilterSegmentAdvance,
    deleteRuleSetAdvance,
    deleteFilterSegmentAdvance,
    deleteRuleAdvance,
    addRuleAdvance,
    addAggregateAdvance,
    addGroupByAdvance,
    deleteAggregateAdvance,
    deleteGroupByAdvance,
    onToggleAggregateAdvance,
    setAggregateOperatorAdvance,
    onSelectRuleColumnAdvance,
    onSelectRuleColumnOperatorAdvance,
    onSelectRuleColumnUnitAdvance,
    onSelectRuleAggregateColumnAdvance,
    onSelectRuleAggregateColumnOperatorAdvance,
    onSelectRuleAggregateColumnFunctionAdvance,
    onCloseModalAdvance,
    onSubmitAdvance,
    onInputColumnValueAdvance,
    onInputColumnValueDataListAdvance,
    onInputAggregateColumnValueAdvance,
    onSetRuleNameAdvance,
    onSelectRuleGroupByFunctionAdvance,
    onCloseMiniModalAdvance,
    onConfirmMiniModalAdvance,
    onSetDeleteRuleSetModalAdvance,
    onSetCancelMainModalAdvance,
    onFilterBySegmentAdvance,

    resultLogicText,
    resultLogicTextTH,
    listGroup,
    ArraytOperatorIdContent,
    indexRuleSetAction,
    listIndexGroup,
    notiGroupRuleSet,
    notiType,
    hiddenGroup,
    setOperetorRuleSet,
    selectRuleSetGroup,
    manageRuleSetGroup,
    handleSelectRuleColumn,
    onSelectRuleSetColumn,
    handleSelectRuleAggregateColumnAdvance,
    onHandleSelectRuleAggregateColumnAdvance,
    handleSetRuleOperatorAdvance,
    onSetRuleOperatorAdvance,
    handleToggleAggregateAdvance,
    onSetToggleAggregateAdvance,
    handleSetAggregateOperatorAdvance,
    onSetAggregateOperatorAdvance,
    handleSelectRuleColumnOperatorAdvance,
    onHandleSelectRuleColumnOperatorAdvance,
    handleSelectRuleColumnUnitAdvance,
    onHandleSelectRuleColumnUnitAdvance,
    handleSetRuleNameAdvance,
    onHandleSetRuleNameAdvance,
    handleSetInputColumnValueAdvance,
    onHandleSetInputColumnValueAdvance,
    handleSetInputAggregateColumnValueAdvance,
    onHandleSetInputAggregateColumnValueAdvance,
    handleSelectRuleAggregateColumnOperatorAdvance,
    onHandleSelectRuleAggregateColumnOperatorAdvance,
    handleSelectRuleAggregateColumnFunctionAdvance,
    onHandleSelectRuleAggregateColumnFunctionAdvance,
    handleSetAddRuleAdvance,
    onSetAddRuleAdvance,
    handleSetDeleteRuleAdvance,
    onSetDeleteRuleAdvance,
    handleSetAddAggregateAdvance,
    onSetAddAggregateAdvance,
    handleSetDeleteAggregateAdvance,
    onSetDeleteAggregateAdvance,
    handleCloseMiniModalAdvance,
    onSetCloseMiniModalAdvance,
    handleConfirmMiniModalAdvance,
    onSetConfirmMiniModalAdvance,
    unGroupRuleSetAll,
    closeNotiGroupRuleSet,
    notiUngroup,
    onHandleFilterBySegmentAdvance,
    handleFilterBySegmentAdvance,
    handleSetInputColumnValueDataListAdvance,
    onHandleSetInputColumnValueDataListAdvance,

    handleInputHandleKeyRuleTagAdvance,
    onHandleInputHandleKeyRuleTagAdvance,
    onInputHandleKeyRuleTagAdvance,
  };
}
